import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, SEARCH_URL, SUBCATEGORIES_URL } from "../../utils/constants";
import { buildUrl } from "../../utils/common";

const baseQuery = fetchBaseQuery({ baseUrl: BASE_URL });

export const apiSlice = createApi({
   reducerPath: "api",
   baseQuery,
   tagTypes: ["Product"],
   endpoints: (builder) => ({
      getBrands: builder.query({
         query: () => "/brands",
         providesTags: ["Brands"],
      }),
   }),
});

export const { useGetBrandsQuery, useGetProductsQuery } = apiSlice;
