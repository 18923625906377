// productSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constants";
import { getBrands } from '../../features/categories/brandsSlice'

export const getProductById = createAsyncThunk(
   'product/getProductById',
   async ({ categoryId, subcategoryId, productId }, thunkAPI) => {
      try {
         const res = await axios.get(`${BASE_URL}/${encodeURIComponent(categoryId)}/${encodeURIComponent(subcategoryId)}/products/${productId}`);
         return res.data;
      } catch (err) {
         console.error(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

export const updateProductRating = createAsyncThunk(
   'product/updateProductRating',
   async ({ categoryId, subcategoryId, productId, newRating }, thunkAPI) => {
      try {
         console.log('Оновлення товару. URL:', `${BASE_URL}/${encodeURIComponent(categoryId)}/${encodeURIComponent(subcategoryId)}/products/${productId}`);
         console.log('Новий рейтинг:', newRating);

         const res = await axios.patch(
            `${BASE_URL}/${encodeURIComponent(categoryId)}/${encodeURIComponent(subcategoryId)}/products/${encodeURIComponent(productId)}`,
            { newRating }
         );

         console.log('Update request successful. Response:', res.data);

         // Повертаємо дані з відповіді
         return res.data;
      } catch (err) {
         console.error('Update request failed. Error:', err);

         // Відхиляємо запит разом з помилкою
         return thunkAPI.rejectWithValue(err);
      }
   }
);
// Створення slice для управління станом товару
const productSlice = createSlice({
   name: 'product',
   initialState: {
      data: null,       // Дані одного товару
      brandProducts: [],
      isLoading: false, // Завантажується чи ні
      isError: false,   // Виникла помилка чи ні
      cart: [],

   },
   reducers: {
      addToCart: (state, action) => {
         const { productId, title, brands, price, quantity, imageUrl, article } = action.payload;

         const existingItem = state.cart.find((item) => item.productId === productId);

         if (existingItem) {
            existingItem.quantity += quantity;
         } else {
            state.cart.push({
               productId,
               title,
               brands,
               price,
               quantity,
               imageUrl,
               article
            });
         }
      },
      removeItemFromCart: (state, action) => {
         const productIdToRemove = action.payload.productId;
         // Оновіть стан, щоб видалити товар із кошика
         state.cart = state.cart.filter(item => item.productId !== productIdToRemove);
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getProductById.pending, (state) => {
         state.isLoading = true;
         state.isError = false;
      });
      builder.addCase(getProductById.fulfilled, (state, { payload }) => {
         state.data = payload;
         state.isLoading = false;
      });
      builder.addCase(getProductById.rejected, (state, action) => {
         console.error('Product rejected:', action.error);
         console.log('Request details:', action.meta.arg); // Деталі запиту
         state.isLoading = false;
         state.isError = true;
      });
      // Додайте обробники для нового asyncThunk оновлення рейтингу
      builder.addCase(updateProductRating.pending, (state) => {
         state.isLoading = true;
         state.isError = false;
      });
      builder.addCase(updateProductRating.fulfilled, (state, action) => {
         const { categoryId, subcategoryId, productId, newRating } = action.meta.arg;
         const existingProduct = state[categoryId]?.[subcategoryId]?.find((p) => p._id === productId);

         if (existingProduct) {
            existingProduct.rating = newRating;
         }
      });
      builder.addCase(updateProductRating.rejected, (state, action) => {
         console.error('Update product rating rejected:', action.error);
         console.log('Request details:', action.meta.arg); // Деталі запиту
         state.isLoading = false;
         state.isError = true;
      });
   },
});

// Експорт редуктора та селектора
export default productSlice.reducer;
export const { } = productSlice.actions;
export const selectProduct = (state) => state.product;
export const { addToCart, removeItemFromCart } = productSlice.actions;

