// subcategoriesSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SUBCATEGORIES_URL, BASE_URL } from "../../utils/constants";

const yourToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTVjNjJkMDBkZmJjM2Y2ZDY1Mzc3YWEiLCJpYXQiOjE3MDA1NTM0MjQsImV4cCI6MTcwMzE0NTQyNH0.nm6SaW45iamizCz6Q6q52K45tho9jXEsAmpfmxGpqZo'; // Вставьте свой токен

export const getSubcategories = createAsyncThunk(
   'subcategories/getSubcategories',
   async (categoryId, thunkAPI) => {
      try {
         const res = await axios.get(`${SUBCATEGORIES_URL}/${encodeURIComponent(categoryId)}/subcategories`
            , {
               headers: {
                  Authorization: `Bearer ${yourToken}`,
               },
            });


         return res.data;
      } catch (err) {
         console.log(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

export const getSimilarProductsByTitle = createAsyncThunk(
   'subcategories/getSimilarProductsByTitle',
   async (title, thunkAPI) => {
      try {
         const res = await axios.get(`http://localhost:4444/655ddaafbd342d93bef0dd16/655f50f5817af6082789b044/products?title=${encodeURIComponent(title)}`);
         return res.data;
      } catch (err) {
         console.error(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);



const subcategoriesSlice = createSlice({
   name: 'subcategories',
   initialState: {
      list: [],
      isLoading: false,
      similarProducts: [],
      similarProductsLoading: false,
      similarProductsError: null,
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(getSubcategories.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getSubcategories.fulfilled, (state, { payload }) => {
         // Фильтруем новые подкатегории, исключая те, которые уже есть в текущем списке
         const newSubcategories = payload.filter(newSubcategory =>
            !state.list.some(existingSubcategory => existingSubcategory._id === newSubcategory._id)
         );

         // Добавляем новые подкатегории к текущему списку
         state.list = [...state.list, ...newSubcategories];
         state.isLoading = false;
      });
      builder.addCase(getSubcategories.rejected, (state, action) => {
         console.error('Subcategories rejected:', action.error);
         state.isLoading = false;
      });
      builder.addCase(getSimilarProductsByTitle.pending, (state) => {
         state.similarProductsLoading = true;
         state.similarProductsError = null;
      });
      builder.addCase(getSimilarProductsByTitle.fulfilled, (state, { payload }) => {
         state.similarProducts = payload;
         state.similarProductsLoading = false;
      });
      builder.addCase(getSimilarProductsByTitle.rejected, (state, action) => {
         console.error('Similar products rejected:', action.error);
         state.similarProductsLoading = false;
         state.similarProductsError = action.error;
      });
   },
});

// ... (ваш код)

export default subcategoriesSlice.reducer;
export const { } = subcategoriesSlice.actions;
export const selectSubcategories = (state) => state.subcategories.list;
export const selectSimilarProducts = (state) => state.subcategories.similarProducts;