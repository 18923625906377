import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constants";

const PAGE_SIZE = 12;

export const getProducts = createAsyncThunk(
   'products/getProducts',
   async ({ categoryId, subcategoryId }, thunkAPI) => {
      try {
         // Используем encodeURIComponent для категории и подкатегории
         const res = await axios.get(`${BASE_URL}/${encodeURIComponent(categoryId)}/${encodeURIComponent(subcategoryId)}/products`);
         return res.data;
      } catch (err) {
         console.log(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);
export const searchProducts = createAsyncThunk(
   'products/searchProducts',
   async (searchQuery, thunkAPI) => {
      try {
         const res = await axios.get(`${BASE_URL}/search/products`, {
            params: {
               title: searchQuery,
            },
         });
         return res.data;
      } catch (err) {
         console.log(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);
export const getAllProducts = createAsyncThunk(
   'products/getAllProducts',
   async ({ page = 1 }, thunkAPI) => {
      try {
         console.log('Calling getAllProducts');
         const res = await axios.get(`${BASE_URL}/products?page=${page}&pageSize=${PAGE_SIZE}`);
         console.log('Response from server:', res.data);
         return res.data;
      } catch (err) {
         console.log('Error in getAllProducts:', err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

const productsSlice = createSlice({
   name: 'products',
   initialState: {
      list: [],
      isLoading: false,
      isError: false,
      totalProducts: 0,
      filters: {},
      brandId: null,
      currentPage: 1, // Додано поле currentPage
      pageSize: PAGE_SIZE, // Додано поле pageSize

   },
   reducers: {
      setFilters: (state, action) => {
         state.filters = action.payload;
      },
      setBrandId: (state, action) => {
         state = { ...state, brandId: action.payload };
      },
      clearBrandId: (state) => {
         state.brandId = null;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getProducts.pending, (state) => {
         state.isLoading = true;
         state.isError = false;  // Сбрасываем ошибку при начале загрузки
      });
      builder.addCase(getProducts.fulfilled, (state, { payload }) => {
         console.log('Fulfilled with payload:', payload);

         if (Array.isArray(payload)) {
            state.list = payload;
            state.totalProducts = payload.length;
            state.isLoading = false;
         } else {
            console.error('Invalid payload structure. Unable to set totalProducts.');
            state.isLoading = false;
            state.isError = true;
         }

         // Фільтрація товарів залежно від значень фільтрів
         if (Object.keys(state.filters).length > 0) {
            state.list = state.list.filter((product) => {
               return Object.keys(state.filters).every((filterKey) => {
                  if (Array.isArray(state.filters[filterKey])) {
                     // Якщо значення фільтра - масив, товар повинен містити хоча б одне співпадіння
                     return state.filters[filterKey].some((filterValue) => product[filterKey] === filterValue);
                  } else {
                     // Якщо значення фільтра - просте значення, товар повинен точно співпадати
                     return product[filterKey] === state.filters[filterKey];
                  }
               });
            });
            state.totalProducts = state.list.length;
         }
      });
      builder.addCase(getProducts.rejected, (state, action) => {
         console.error('Products rejected:', action.error);
         state.isLoading = false;
         state.isError = true;
      });
      builder.addCase(searchProducts.pending, (state) => {
         state.isLoading = true;
         state.isError = false;
      });
      builder.addCase(searchProducts.fulfilled, (state, { payload }) => {
         console.log('Fulfilled with payload:', payload);

         if (Array.isArray(payload)) {
            // Додаємо результати пошуку до списку товарів
            state.list = [...state.list, ...payload];
            state.totalProducts = state.list.length;
            state.isLoading = false;
         } else {
            console.error('Invalid payload structure. Unable to set totalProducts.');
            state.isLoading = false;
            state.isError = true;
         }
      });

      builder.addCase(searchProducts.rejected, (state, action) => {
         console.error('Search rejected:', action.error);
         state.isLoading = false;
         state.isError = true;
      });
      builder.addCase(getAllProducts.fulfilled, (state, { payload }) => {
         console.log('Завершено з результатом:', payload);

         if (Array.isArray(payload)) {
            state.list = payload;
            state.totalProducts = payload.length;
            state.isLoading = false;
         } else {
            console.error('Неправильна структура результату. Неможливо встановити totalProducts.');
            state.isLoading = false;
            state.isError = true;
         }

         // Застосовуємо фільтри, якщо вони є
         if (Object.keys(state.filters).length > 0) {
            state.list = state.list.filter((product) => {
               return Object.keys(state.filters).every((filterKey) => {
                  if (Array.isArray(state.filters[filterKey])) {
                     return state.filters[filterKey].some((filterValue) => product[filterKey] === filterValue);
                  } else {
                     return product[filterKey] === state.filters[filterKey];
                  }
               });
            });
            state.totalProducts = state.list.length;
         }

         // Фільтрація за брендом
         if (state.brandId) {
            state.list = state.list.filter((product) => product.brands && product.brands.length > 0 && product.brands[0].id === state.brandId);
            state.totalProducts = state.list.length;
         }
         // Поновлення полів для пагінації
         state.currentPage = 1; // Скидаємо поточну сторінку до першої при завантаженні нових товарів
         state.pageSize = PAGE_SIZE;
      });

   },

});

// Экспорт действий и редуктора
export default productsSlice.reducer;
export const { setFilters, setBrandId, clearBrandId } = productsSlice.actions;

export const selectProducts = (state) => state.products;
