//export const BASE_URL = 'https://api.escuelajs.co/api/v1'


export const BASE_URL = 'https://server.agrosnabmarket.com.ua'
export const CATEGORIES_URL = 'https://server.agrosnabmarket.com.ua'
export const SUBCATEGORIES_URL = 'https://server.agrosnabmarket.com.ua';
export const PRODUCTS_PATH = "products";
export const PRODUCTS_URL = `${BASE_URL}/${PRODUCTS_PATH}`;
export const REGISTER_URL = 'https://server.agrosnabmarket.com.ua/auth';
export const COMMENTS_URL = 'https://server.agrosnabmarket.com.ua/comments/all';
export const ALLPRODUCTS_URL = 'https://server.agrosnabmarket.com.ua/products'

export const SEARCH_URL = (categoryId, subcategoryId) => {
   return `${BASE_URL}/${encodeURIComponent(categoryId)}/${encodeURIComponent(subcategoryId)}/products`;
};