// Comments.jsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getComments, addComment } from '../../../features/categories/CommentsSlice';
import { selectCurrentUser, selectUsers, getUsers } from '../../../features/user/UserSlice';
import { selectProducts, getAllProducts } from '../../../features/categories/productsSlice';
import Avatar from '../../../images/other/avatar.png';

const Comments = ({ productId }) => {
   const dispatch = useDispatch();
   const comments = useSelector((state) => state.comments.list);
   const isLoading = useSelector((state) => state.comments.isLoading);
   const currentUser = useSelector(selectCurrentUser);
   const products = useSelector(selectProducts);
   const users = useSelector(selectUsers);
   const [newComment, setNewComment] = useState('');

   useEffect(() => {
      dispatch(getAllProducts({ page: 1 }));
   }, [dispatch]);

   useEffect(() => {
      dispatch(getUsers());
      dispatch(getComments());
   }, [dispatch, products]);

   const handleAddComment = () => {
      if (newComment.trim() !== '') {
         dispatch(addComment({ text: newComment, userId: currentUser?._id?.toString(), productId }));
         setNewComment('');
      }
   };

   if (isLoading) {
      return <p>Loading...</p>;
   }

   // Фільтруємо коментарі за productId, якщо productId передано
   // В іншому випадку відображаємо всі коментарі
   const filteredComments = productId ? comments.filter(comment => comment.productId === productId) : comments;
   console.log('Users:', users);
   return (
      <div className='comments'>
         <div className="comments-container container">
            <div className="comments-textarea">
               <textarea
                  rows="4"
                  cols="50"
                  placeholder="Ваш коментар..."
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
               />
               <button onClick={handleAddComment} className='btn-comments'>Додати коментар</button>
            </div>
            <div className="comments-content">
               {filteredComments.length === 0 ? (
                  <p>Коментарів ще не має</p>
               ) : (
                  <ul>
                     {filteredComments.map((comment) => {
                        const product = products.list && products.list.find((p) => p._id === comment.productId);
                        console.log('Product inside map:', product);

                        const user = comment.userId ? (users && users.find((u) => u._id === comment.userId)) : null;
                        console.log('User inside map:', user);


                        return (
                           <li key={comment._id}>
                              <p className='comments-block'>
                                 {user && (
                                    <div className='comments-body'>
                                       <img src={user.avatarUrl} alt="Avatar" className='comments-body__image' /> <br />
                                       <div className="comments-body__user">
                                          <strong className='comments-body__fullname'>{user.fullName}</strong>  <br />
                                          {product && (
                                             <>
                                                <strong>Товар:</strong> {product.title} <br />
                                             </>
                                          )}
                                          <strong className='comments-body__text'>{comment.text}</strong>
                                          <br />
                                          <strong className='comments-body__data'>Дата:  {new Date(comment.createdAt).toLocaleString()}</strong>
                                          <br />
                                       </div>
                                    </div>
                                 )}
                                 {!user && (
                                    <div className="comments-body">
                                       <img src={Avatar} alt="Avatar" className='comments-body__image' /> <br />
                                       <div className="comments-body__uknown">
                                          {product && (
                                             <>
                                                <strong>Товар:</strong> {product.title} <br />
                                             </>
                                          )}
                                          <strong></strong> Невідомий користувач <br />

                                          <strong className='comments-body__text'>{comment.text}</strong>
                                          <br />
                                          <strong className='comments-body__data'>Дата:  {new Date(comment.createdAt).toLocaleString()}</strong>
                                          <br />
                                       </div>
                                    </div>
                                 )}
                              </p>
                           </li>
                        );
                     })}
                  </ul>
               )}
            </div>
         </div>
      </div>
   );
};

export default Comments;
