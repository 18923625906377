import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { selectSubcategories, getSubcategories } from '../../features/categories/subCategoriesSlice';

const CategoriesList = () => {
   const dispatch = useDispatch();
   const subcategories = useSelector(selectSubcategories);
   const { categoryId } = useParams(); // Отримання categoryId з URL

   useEffect(() => {
      console.log('Subcategories component mounted or selected subcategory changed.');
      console.log('Selected Category ID:', categoryId);

      // При монтуванні компонента або зміні вибраної категорії
      // Виконуємо запит підкатегорій для вибраної категорії
      dispatch(getSubcategories(categoryId));
   })

   return (
      <div>
         <h2>Виберіть підкатегорію</h2>
         <ul>
            {subcategories
               .filter(subcategory => subcategory.category === categoryId) // Фільтруємо підкатегорії за вибраною категорією
               .map((subcategory) => (
                  <Link to={`/${categoryId}/${subcategory._id}/products`} key={subcategory._id} className='subcategories-block'>
                     {subcategory.title}
                  </Link>
               ))}
         </ul>
      </div>
   );
};

export default CategoriesList;