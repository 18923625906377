import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { selectProduct, removeItemFromCart } from '../../features/categories/productSlice';
import { createOrder, selectOrderDetails, selectOrderLoading, selectOrderError, clearOrderDetails } from '../../features/api/orderSlice';
import { registerUser, loginUser, selectCurrentUser, selectError, setError } from '../../features/user/UserSlice';
import Wallet from '../../images/cart/wallet.svg'
import Visa from '../../images/cart/visa.svg'
import Mastercard from '../../images/cart/mastercard.svg'
import Bill from '../../images/cart/bill.svg'
import Basket from '../../images/cart/basket.svg'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Cart = () => {
   const { cart } = useSelector(selectProduct);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const orderDetails = useSelector(selectOrderDetails);
   const orderLoading = useSelector(selectOrderLoading);
   const orderError = useSelector(selectOrderError);
   const currentUser = useSelector(selectCurrentUser);


   useEffect(() => {
      if (orderDetails) {
         // Здійсніть необхідні дії, якщо замовлення успішно створено
         // Наприклад, очистіть кошик або перенаправте користувача на сторінку підтвердження
         dispatch(clearOrderDetails()); // Очистити деталі замовлення
         setTimeout(() => {
            // Перенаправити користувача на сторінку хом
            navigate('/');
         }, 5000); // Затримка в мілісекунд

         // Додайте інші дії відповідно до ваших потреб
      }
   }, [orderDetails, dispatch, navigate]);

   const handleConfirmOrder = () => {
      // Перевірка, чи в кошику є товари
      if (cart.length === 0) {
         toast.error('Додайте товари до кошика перед оформленням замовлення', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         });
         return;
      }

      // Перевірка, чи введено контактні дані
      if (!contactInfo || !contactInfo.name || !contactInfo.phone || !contactInfo.email) {
         toast.error('Натисніть "Підтвердити дані" перед оформленням замовлення', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         });
         return;
      }

      // Перевірка, чи вибрано спосіб доставки і оплати
      if (!selectedOption || !selectedPaymentOption) {
         toast.error('Виберіть спосіб доставки та оплати перед оформленням замовлення', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         });
         return;
      }
      // Успішне замовлення
      toast.success('Ваше замовлення успішно зафіксоване!', {
         position: 'top-right',
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
      });

      // Розрахунок загальної вартості товарів у кошику
      const totalPrice = cart.reduce((sum, item) => sum + item.price * quantities[item.productId], 0);
      // Розрахунок загальної вартості товарів зі знижкою


      console.log('currentUser:', currentUser);
      // Ваші дані замовлення
      const orderData = {
         userId: currentUser ? currentUser._id : null,
         products: cart.map(item => ({
            productId: item.productId,
            quantity: quantities[item.productId],
            title: item.title,
            article: item.article,
            imageUrl: item.imageUrl[0],
         })),
         contactInfo: contactInfo,
         delivery: { option: selectedOption },
         payment: { option: selectedPaymentOption },
         totalPrice: totalPrice,
      };

      console.log('userId:', orderData.userId); // Вивести userId в консоль
      console.log('orderData:', orderData);
      // Виклик функції для створення замовлення
      dispatch(createOrder(orderData));
   };

   const [quantities, setQuantities] = useState(
      cart.reduce((acc, item) => {
         acc[item.productId] = item.quantity;
         return acc;
      }, {})
   );

   const handleQuantityChange = (productId, newQuantity) => {
      if (newQuantity < 1) {
         return;
      }

      setQuantities((prevQuantities) => ({
         ...prevQuantities,
         [productId]: newQuantity,
      }));

      // Тут ви можете диспетчити дію для оновлення кількості товару в Redux state
      // Зверніть увагу, що це має бути додано до вашого редюсера
   };
   const [totalPrice, setTotalPrice] = useState(0);

   useEffect(() => {
      // При зміні кількості або додаванні нового товару перераховуємо загальну вартість
      const newTotalPrice = cart.reduce((acc, item) => {
         acc += item.price * quantities[item.productId];
         return acc;
      }, 0);

      setTotalPrice(newTotalPrice);
   }, [quantities, cart]);

   const [formData, setFormData] = useState({
      name: '',
      phone: '',
      email: '-',
   });

   const [contactInfo, setContactInfo] = useState(null);

   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
         ...prevData,
         [name]: value,
      }));
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      // Встановлюємо контактні дані після відправки форми
      setContactInfo({ ...formData });
   };
   const [selectedOption, setSelectedOption] = useState('');

   const handleOptionChange = (option) => {
      setSelectedOption(option);
   };
   const handleContactInfoChange = (fieldName, value) => {
      setContactInfo((prevInfo) => ({
         ...prevInfo,
         [fieldName]: value,
      }));
   };
   const [selectedPaymentOption, setSelectedPaymentOption] = useState('');

   const handlePaymentOptionChange = (option) => {
      setSelectedPaymentOption(option);
   };
   const handleRemoveFromCart = (productId) => {
      console.log('Removing product with ID:', productId);
      dispatch(removeItemFromCart({ productId }));
   };
   const calculatePrice = (quantity, itemPrice, discountThreshold) => {
      const discountedPrice = quantity >= discountThreshold ? itemPrice * 0.8 : itemPrice;
      const totalPriceWithDiscount = (quantity * discountedPrice).toFixed(0);
      const totalPriceWithoutDiscount = (quantity * itemPrice).toFixed(0);

      return {
         price: discountedPrice.toFixed(0),
         total: totalPriceWithDiscount,
         discounted: quantity >= discountThreshold,
         totalWithoutDiscount: totalPriceWithoutDiscount,
      };
   };
   const totalDiscountedPrice = cart.reduce((sum, item) => {
      const { totalWithoutDiscount, total, discounted } = calculatePrice(quantities[item.productId], item.price, 10);
      return sum + (discounted ? Number(total) : item.price * quantities[item.productId]);
   }, 0);
   return (
      <section className='cart'>
         <div className="cart-container container">
            <div className="cart-order">
               <div className="cart-order__box cart-box">
                  <h2 className='cart-box__title'>Ваше замовлення</h2>
                  {cart.length > 0 ? (
                     <div className="cart-box__products products-cart">
                        <ul>
                           {cart.map((item) => (
                              <li key={item.productId} className='products-cart__item'>
                                 <img src={item.imageUrl[0]} alt={item.title} className='products-cart__image' />

                                 <div className="products-cart__info">
                                    <p className="products-cart__title">{item.title}</p>
                                    <div className="products-cart__article">
                                       <h6>Артикул: {item.article}</h6></div>

                                 </div>
                                 <div className="products-cart__quantity quantity">
                                    <button type="submit"
                                       className="quantity__btn btn-minus"
                                       onClick={() => handleQuantityChange(item.productId, quantities[item.productId] - 1)}>-</button>
                                    <input
                                       type="number"
                                       className='quantity__input'
                                       value={quantities[item.productId]}
                                       onChange={(e) => handleQuantityChange(item.productId, parseInt(e.target.value, 10))}
                                    />
                                    <button type="submit"
                                       className="quantity__btn btn-plus"
                                       onClick={() => handleQuantityChange(item.productId, quantities[item.productId] + 1)}
                                    >+</button>
                                 </div>
                                 <h3 className='products-cart__price'>
                                    {calculatePrice(quantities[item.productId], item.price, 10).price}₴
                                 </h3>
                                 <h3 className='products-cart__prices'>
                                    {calculatePrice(quantities[item.productId], item.price, 10).discounted ? (
                                       <>
                                          <del>{(quantities[item.productId] * item.price).toFixed(0)}₴</del> {` ${calculatePrice(quantities[item.productId], item.price, 10).total}₴`}
                                       </>
                                    ) : (
                                       `${calculatePrice(quantities[item.productId], item.price, 10).total}₴`
                                    )}
                                 </h3>
                                 <button onClick={() => handleRemoveFromCart(item.productId)} className='btn-basket'>
                                    <img src={Basket} alt={Basket} />
                                 </button>
                              </li>

                           ))}
                        </ul>
                     </div>
                  ) : (
                     <p>Кошик порожній</p>
                  )}
               </div>
               <div className="cart-step">
                  <div className="cart-step__header header-step">
                     <div className="header-step__step">
                        <h3>Крок 1.</h3>
                     </div>
                     <div className="header-step__title">
                        <h3>Вкажіть данні отримувача</h3>
                     </div>

                  </div>
                  <div className="cart-step__content content-step">
                     <form onSubmit={handleSubmit}>
                        <div className="content-step__inputs">
                           <div className="content-step__input-group">
                              <label className='content-step__input input-name'>
                                 <p>Ім’я*</p>
                                 <input type="text"
                                    name="name"
                                    placeholder='Введіть ПІБ'
                                    value={formData.name}
                                    onChange={handleInputChange} required />
                              </label>
                              <label className='content-step__input input-phone'>
                                 <p>Телефон*</p>
                                 <input type="text"
                                    name="phone"
                                    placeholder='Введіть номер'
                                    value={formData.phone}
                                    onChange={handleInputChange} required />
                              </label>
                           </div>
                           <label className='content-step__input input-mail'>
                              <p>E-mail</p>
                              <input type="text"
                                 name="email"
                                 placeholder='Введіть E-mail'
                                 value={formData.email}
                                 onChange={handleInputChange} />
                           </label>
                        </div>
                        <button type="submit" className="bill-btn">Підтвердити дані</button>
                     </form>
                     <div className="content-step__warning">
                        <h6>* Після введення та перевірки даних натисніть "Підтвердити дані". Далі переходьте до вибору доставки.</h6>
                     </div>

                  </div>
               </div>
               <div className="cart-step">
                  <div className="cart-step__header header-step">
                     <div className="header-step__step">
                        <h3>Крок 2.</h3>
                     </div>
                     <div className="header-step__title">
                        <h3>Оберіть спосіб доставки</h3>
                     </div>
                  </div>
                  <div className="cart-step__content content-step">
                     <div className="delivery-options">
                        <div className="delivery-options__radiobox">
                           <label>
                              <input
                                 type="radio"
                                 name="deliveryOption"
                                 value="pickup"
                                 checked={selectedOption === 'pickup'}
                                 onChange={() => handleOptionChange('pickup')}
                                 className='delivery-radiobox'
                              />
                              Самовивіз
                           </label>

                           <label>
                              <input
                                 type="radio"
                                 name="deliveryOption"
                                 value="courier"
                                 checked={selectedOption === 'courier'}
                                 onChange={() => handleOptionChange('courier')}
                                 className='delivery-radiobox'
                              />
                              Кур'єром
                           </label>
                           {selectedOption === 'courier' && (
                              <div className='delivery-body'>
                                 <label>
                                    <p>Адреса доставки: </p>
                                    <input type="text"
                                       name="city"
                                    />

                                 </label>
                                 {/* Додайте інші інпути для кур'єра */}
                              </div>
                           )}
                           <label>
                              <input
                                 type="radio"
                                 name="deliveryOption"
                                 value="novaPoshta"
                                 checked={selectedOption === 'novaPoshta'}
                                 onChange={() => handleOptionChange('novaPoshta')}
                                 className='delivery-radiobox'
                              />
                              Нова Пошта
                           </label>
                           {selectedOption === 'novaPoshta' && (
                              <div className='delivery-body'>
                                 <label>
                                    <p>Місто:</p>
                                    <input
                                       type="text"
                                       name="city"
                                       value={contactInfo?.city || ''}
                                       onChange={(e) => handleContactInfoChange('city', e.target.value)}
                                    />
                                 </label>
                                 <label>
                                    <p>Область:</p>
                                    <input
                                       type="text"
                                       name="region"
                                       value={contactInfo?.region || ''}
                                       onChange={(e) => handleContactInfoChange('region', e.target.value)}
                                    />
                                 </label>
                                 <label>
                                    <p>Район:</p>
                                    <input
                                       type="text"
                                       name="district"
                                       value={contactInfo?.district || ''}
                                       onChange={(e) => handleContactInfoChange('district', e.target.value)}
                                    />
                                 </label>
                                 <label>
                                    <p>Відділення Нової Пошти:</p>
                                    <input
                                       type="text"
                                       name="novaPoshtaOffice"
                                       value={contactInfo?.novaPoshtaOffice || ''}
                                       onChange={(e) => handleContactInfoChange('novaPoshtaOffice', e.target.value)}
                                    />
                                 </label>
                              </div>

                           )}
                           <label>
                              <input
                                 type="radio"
                                 name="deliveryOption"
                                 value="novaPoshta"
                                 checked={selectedOption === 'ukrposhta'}
                                 onChange={() => handleOptionChange('ukrposhta')}
                                 className='delivery-radiobox'
                              />
                              УкрПошта
                           </label>
                           {selectedOption === 'ukrposhta' && (
                              <div className='delivery-body'>
                                 <label>
                                    <p>Місто:</p>
                                    <input
                                       type="text"
                                       name="ukrposhtaCity"
                                       value={contactInfo?.ukrposhtaCity || ''}
                                       onChange={(e) => handleContactInfoChange('ukrposhtaCity', e.target.value)}
                                    />
                                 </label>
                                 <label>
                                    <p>Індекс:</p>
                                    <input
                                       type="text"
                                       name="ukrposhtaIndex"
                                       value={contactInfo?.ukrposhtaIndex || ''}
                                       onChange={(e) => handleContactInfoChange('ukrposhtaIndex', e.target.value)}
                                    />
                                 </label>
                              </div>

                           )}
                           <label>
                              <input
                                 type="radio"
                                 name="deliveryOption"
                                 value="sat"
                                 checked={selectedOption === 'sat'}
                                 onChange={() => handleOptionChange('sat')}
                                 className='delivery-radiobox'
                              />
                              САТ
                           </label>
                           {selectedOption === 'sat' && (
                              <div className='delivery-body'>
                                 <label>
                                    <p>Місто:</p>
                                    <input
                                       type="text"
                                       name="satCity"
                                       value={contactInfo?.satCity || ''}
                                       onChange={(e) => handleContactInfoChange('satCity', e.target.value)}
                                    />
                                 </label>
                                 <label>
                                    <p>Відділення:</p>
                                    <input
                                       type="text"
                                       name="satOffice"
                                       value={contactInfo?.satOffice || ''}
                                       onChange={(e) => handleContactInfoChange('satOffice', e.target.value)}
                                    />
                                 </label>
                              </div>

                           )}
                        </div>
                        <div className="cart-step">
                           <div className="cart-step__header header-step">
                              <div className="header-step__step">
                                 <h3>Крок 3.</h3>
                              </div>
                              <div className="header-step__title">
                                 <h3>Оберіть спосіб оплати</h3>
                              </div>
                           </div>
                           <div className="cart-step__content content-step">
                              <div className="content-step__pays">
                                 {/* Інші варіанти оплати */}
                                 {selectedOption === 'pickup' && (
                                    <div className='content-step__pay'>
                                       <label>
                                          <input
                                             type="radio"
                                             name="paymentOption"
                                             value="cash"
                                             checked={selectedPaymentOption === 'cash'}
                                             onChange={() => handlePaymentOptionChange('cash')}
                                             className='delivery-radiobox'
                                          />
                                          Готівкою
                                       </label>
                                       <img src={Wallet} alt="Wallet" />
                                    </div>
                                 )}
                                 {selectedOption === 'novaPoshta' && (
                                    <>
                                       <div className="content-step__pay"><label>
                                          <input
                                             type="radio"
                                             name="paymentOption"
                                             value="cod"
                                             checked={selectedPaymentOption === 'cod'}
                                             onChange={() => handlePaymentOptionChange('cod')}
                                             className='delivery-radiobox'
                                          />
                                          Наложений платіж
                                       </label>
                                          <img src={Wallet} alt="Wallet" />
                                       </div>
                                       <div className="content-step__pay"><label>
                                          <input
                                             type="radio"
                                             name="paymentOption"
                                             value="online-cash"
                                             checked={selectedPaymentOption === 'online-cash'}
                                             onChange={() => handlePaymentOptionChange('online-cash')}
                                             className='delivery-radiobox'
                                          />
                                          Оплата на сайті
                                       </label>
                                          <div className="content-step__ico">
                                             <img src={Visa} alt="visa" />
                                             <img src={Mastercard} alt="Mastercard" />
                                          </div>
                                       </div>
                                       <div className="content-step__pay"><label>
                                          <input
                                             type="radio"
                                             name="paymentOption"
                                             value="bankTransfer"
                                             checked={selectedPaymentOption === 'bankTransfer'}
                                             onChange={() => handlePaymentOptionChange('bankTransfer')}
                                             className='delivery-radiobox'
                                          />
                                          Оплата по реквізитам
                                       </label>
                                          <img src={Bill} alt="Bill" /></div>

                                    </>
                                 )}
                                 {selectedOption === 'ukrposhta' && (
                                    <>

                                       <div className="content-step__pay"><label>
                                          <input
                                             type="radio"
                                             name="paymentOption"
                                             value="online-cash"
                                             checked={selectedPaymentOption === 'online-cash'}
                                             onChange={() => handlePaymentOptionChange('online-cash')}
                                             className='delivery-radiobox'
                                          />
                                          Оплата на сайті
                                       </label>
                                          <div className="content-step__ico">
                                             <img src={Visa} alt="visa" />
                                             <img src={Mastercard} alt="Mastercard" />
                                          </div>
                                       </div>
                                       <div className="content-step__pay"><label>
                                          <input
                                             type="radio"
                                             name="paymentOption"
                                             value="bankTransfer"
                                             checked={selectedPaymentOption === 'bankTransfer'}
                                             onChange={() => handlePaymentOptionChange('bankTransfer')}
                                             className='delivery-radiobox'
                                          />
                                          Оплата по реквізитам
                                       </label>
                                          <img src={Bill} alt="Bill" /></div>

                                    </>
                                 )}
                                 {selectedOption === 'courier' && (
                                    <>
                                       <div className="content-step__pay"><label>
                                          <input
                                             type="radio"
                                             name="paymentOption"
                                             value="online-cash"
                                             checked={selectedPaymentOption === 'online-cash'}
                                             onChange={() => handlePaymentOptionChange('online-cash')}
                                             className='delivery-radiobox'
                                          />
                                          Оплата на сайті
                                       </label>
                                          <div className="content-step__ico">
                                             <img src={Visa} alt="visa" />
                                             <img src={Mastercard} alt="Mastercard" />
                                          </div>
                                       </div>
                                       <div className="content-step__pay"><label>
                                          <input
                                             type="radio"
                                             name="paymentOption"
                                             value="bankTransfer"
                                             checked={selectedPaymentOption === 'bankTransfer'}
                                             onChange={() => handlePaymentOptionChange('bankTransfer')}
                                             className='delivery-radiobox'
                                          />
                                          Оплата по реквізитам
                                       </label>
                                          <img src={Bill} alt="Bill" />
                                       </div>
                                       <div className='content-step__pay'>
                                          <label>
                                             <input
                                                type="radio"
                                                name="paymentOption"
                                                value="cash"
                                                checked={selectedPaymentOption === 'cash'}
                                                onChange={() => handlePaymentOptionChange('cash')}
                                                className='delivery-radiobox'
                                             />
                                             Готівкою
                                          </label>
                                          <img src={Wallet} alt="Wallet" />
                                       </div>
                                    </>
                                 )}
                                 {selectedOption === 'sat' && (
                                    <>
                                       <div className="content-step__pay"><label>
                                          <input
                                             type="radio"
                                             name="paymentOption"
                                             value="cod"
                                             checked={selectedPaymentOption === 'cod'}
                                             onChange={() => handlePaymentOptionChange('cod')}
                                             className='delivery-radiobox'
                                          />
                                          Наложений платіж
                                       </label>
                                          <img src={Wallet} alt="Wallet" />
                                       </div>
                                       <div className="content-step__pay"><label>
                                          <input
                                             type="radio"
                                             name="paymentOption"
                                             value="online-cash"
                                             checked={selectedPaymentOption === 'online-cash'}
                                             onChange={() => handlePaymentOptionChange('online-cash')}
                                             className='delivery-radiobox'
                                          />
                                          Оплата на сайті
                                       </label>
                                          <div className="content-step__ico">
                                             <img src={Visa} alt="visa" />
                                             <img src={Mastercard} alt="Mastercard" />
                                          </div>
                                       </div>
                                       <div className="content-step__pay"><label>
                                          <input
                                             type="radio"
                                             name="paymentOption"
                                             value="bankTransfer"
                                             checked={selectedPaymentOption === 'bankTransfer'}
                                             onChange={() => handlePaymentOptionChange('bankTransfer')}
                                             className='delivery-radiobox'
                                          />
                                          Оплата по реквізитам
                                       </label>
                                          <img src={Bill} alt="Bill" /></div>

                                    </>
                                 )}
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="cart-bill bill">
               <div className="bill__content">
                  <div className="bill__content-container">
                     <div className="bill__content-all all-bill">
                        <div className="all-bill__text">Всього:</div>
                        <div className="all-bill__price">{totalDiscountedPrice}₴</div>
                     </div>
                     <div className="bill__content-body body-content">
                        <div className="body-content__bill bill-check">
                           <div className="bill-check__text">Вартість товарів зі знижкою:</div>
                           <div className="bill-check__price">
                              {totalDiscountedPrice}₴</div>
                        </div>
                        <div className="body-content__bill bill-check">
                           <div className="bill-check__text">Всього без урахування знижки:</div>
                           <div className="bill-check__price">{totalPrice}₴</div>
                        </div>
                        <div className="body-content__bill bill-products">
                           {/* Відображення обраних товарів і кількостей */}
                           <div className="bill-products__title">Ваші товари:</div>
                           <ul>
                              {cart.map((item) => (
                                 <li key={item.productId} className="bill-products__item">
                                    <img src={item.imageUrl[0]} alt={item.title} className='bill-products__image' />
                                    <span>{item.title}</span>
                                    <span>{item.article}</span>
                                    <span>Кількість: {quantities[item.productId]}</span>

                                 </li>
                              ))}
                           </ul>
                        </div>

                        <div className="body-content__bill bill-contacts">
                           <div className="bill-contacts__title">Ваші контактні дані</div>
                           <div className="bill-contacts__content">
                              {/* Відображення контактних даних */}
                              {contactInfo && (
                                 <div className="body-content__bill bill-contacts">
                                    <div>
                                       <p>Ім’я: {contactInfo.name}</p>
                                       <p>Телефон: {contactInfo.phone}</p>
                                       <p>Email: {contactInfo.email}</p>
                                    </div>
                                 </div>
                              )}
                           </div>
                        </div>
                        <div className="body-content__bill bill-delivery">
                           <div className="bill-contacts__title">Адреса доставки</div>
                           <div className="bill-contacts__content">
                              {/* Додайте інші поля в залежності від обраного способу доставки */}
                              {selectedOption === 'novaPoshta' && (
                                 <>
                                    <label>
                                       {contactInfo?.city || ''}
                                    </label>
                                    <label>
                                       {contactInfo?.region || ''}
                                    </label>
                                    <label>
                                       {contactInfo?.district || ''}
                                    </label>
                                    <label>
                                       {contactInfo?.novaPoshtaOffice || ''}
                                    </label>
                                 </>
                              )}
                              {selectedOption === 'ukrposhta' && (
                                 <>
                                    <label>
                                       {contactInfo?.ukrposhtaCity || ''}
                                    </label>
                                    <label>
                                       {contactInfo?.ukrposhtaIndex || ''}
                                    </label>

                                 </>
                              )}
                              {selectedOption === 'sat' && (
                                 <>
                                    <label>
                                       {contactInfo?.satCity || ''}
                                    </label>
                                    <label>
                                       {contactInfo?.satOffice || ''}
                                    </label>

                                 </>
                              )}
                              {selectedOption === 'courier' && (
                                 <>
                                    <label>
                                       {contactInfo?.city || ''}
                                    </label>

                                 </>
                              )}
                              {selectedOption === 'pickup' && (
                                 <>
                                    <div className="pickup">Самовивіз</div>

                                 </>
                              )}
                           </div>

                        </div>
                        <div className="body-content__bill bill-pay">
                           <div className="bill-contacts__title">Спосіб оплати:</div>
                           <div className="bill-contacts__content">
                              {selectedOption === 'pickup' && selectedPaymentOption === 'cash' && (
                                 <div>
                                    <p>Готівкою</p>
                                    <img src={Wallet} alt="Wallet" />
                                 </div>
                              )}
                              {selectedOption === 'novaPoshta' && (
                                 <>
                                    {selectedPaymentOption === 'cod' && (
                                       <div>
                                          <p>Наложений платіж</p>
                                          <img src={Wallet} alt="Wallet" />
                                       </div>
                                    )}
                                    {selectedPaymentOption === 'online-cash' && (
                                       <div>
                                          <p>Оплата на сайті</p>
                                          <div className="content-step__ico">
                                             <img src={Visa} alt="visa" />
                                             <img src={Mastercard} alt="Mastercard" />
                                          </div>
                                       </div>
                                    )}
                                    {selectedPaymentOption === 'bankTransfer' && (
                                       <div>
                                          <p>Оплата по реквізитам</p>
                                          <img src={Bill} alt="Bill" />
                                       </div>
                                    )}
                                 </>
                              )}
                              {selectedOption === 'ukrposhta' && (
                                 <>
                                    {selectedPaymentOption === 'online-cash' && (
                                       <div>
                                          <p>Оплата на сайті</p>
                                          <div className="content-step__ico">
                                             <img src={Visa} alt="visa" />
                                             <img src={Mastercard} alt="Mastercard" />
                                          </div>
                                       </div>
                                    )}
                                    {selectedPaymentOption === 'bankTransfer' && (
                                       <div>
                                          <p>Оплата по реквізитам</p>
                                          <img src={Bill} alt="Bill" />
                                       </div>
                                    )}
                                 </>
                              )}
                              {selectedOption === 'courier' && (
                                 <>
                                    {selectedOption === 'courier' && selectedPaymentOption === 'cash' && (
                                       <div>
                                          <p>Готівкою</p>
                                          <img src={Wallet} alt="Wallet" />
                                       </div>
                                    )}
                                    {selectedPaymentOption === 'online-cash' && (
                                       <div>
                                          <p>Оплата на сайті</p>
                                          <div className="content-step__ico">
                                             <img src={Visa} alt="visa" />
                                             <img src={Mastercard} alt="Mastercard" />
                                          </div>
                                       </div>
                                    )}
                                    {selectedPaymentOption === 'bankTransfer' && (
                                       <div>
                                          <p>Оплата по реквізитам</p>
                                          <img src={Bill} alt="Bill" />
                                       </div>
                                    )}
                                 </>
                              )}
                              {selectedOption === 'sat' && (
                                 <>
                                    {selectedPaymentOption === 'cod' && (
                                       <div>
                                          <p>Наложений платіж</p>
                                          <img src={Wallet} alt="Wallet" />
                                       </div>
                                    )}
                                    {selectedPaymentOption === 'online-cash' && (
                                       <div>
                                          <p>Оплата на сайті</p>
                                          <div className="content-step__ico">
                                             <img src={Visa} alt="visa" />
                                             <img src={Mastercard} alt="Mastercard" />
                                          </div>
                                       </div>
                                    )}
                                    {selectedPaymentOption === 'bankTransfer' && (
                                       <div>
                                          <p>Оплата по реквізитам</p>
                                          <img src={Bill} alt="Bill" />
                                       </div>
                                    )}
                                 </>
                              )}
                           </div>

                        </div>
                     </div>
                     <button type="submit" className="bill-btn" onClick={handleConfirmOrder} disabled={orderLoading}>
                        {orderLoading ? 'Очікування...' : 'Підтвердити'}
                     </button>
                     <ToastContainer />
                  </div>
               </div>
            </div>
         </div>
      </section>

   )
}

export default Cart
