import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { ROUTES } from "../../utils/routes.js"
import Mail from '../../images/headers/mail.svg'
import Account from '../../images/headers/account.svg'
import Logo from '../../images/headers/logo.svg'
import Time from '../../images/headers/time.svg'
import Phone from '../../images/headers/phone.svg'
import Burger from '../../images/headers/burger.svg'
import Search from '../../images/headers/search.svg'
import Compare from '../../images/headers/compare.svg'
import Cart from '../../images/headers/cart.svg'
import { useDispatch, useSelector } from 'react-redux';
import { searchProducts, getAllProducts } from '../../features/categories/productsSlice.js'
import Modal from '../module/moduleWindow.jsx'
import Login from '../User/Login.jsx'
import { logOut, selectCurrentUser } from '../../features/user/UserSlice';
import CallbackForm from '../module/callbackForm.jsx'
import Catalog from '../Pages/Other/Catalog.jsx'


const Header = () => {
   const dispatch = useDispatch();
   const [searchValue, setSearchValue] = useState('');
   const { isLoading, list: searchResults } = useSelector((state) => state.products);
   const { cart } = useSelector((state) => state.product);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const currentUser = useSelector(selectCurrentUser);
   const [isCallbackModalOpen, setIsCallbackModalOpen] = useState(false);
   const [isCatalogOpen, setIsCatalogOpen] = useState(false);




   const toggleCatalog = () => {
      setIsCatalogOpen(!isCatalogOpen);
   };


   const openCallbackModal = () => {
      setIsCallbackModalOpen(true);
   };

   // Додаємо функцію для закриття модалки "Зворотний виклик"
   const closeCallbackModal = () => {
      setIsCallbackModalOpen(false);
   };

   const openModal = () => {
      setIsModalOpen(true);

   };

   const closeModal = () => {
      setIsModalOpen(false);
   };

   const handleLogout = () => {
      // Диспетчеризуємо екшен logOut при натисканні кнопки "Вийти"
      dispatch(logOut());
   };

   useEffect(() => {
      if (searchValue.trim() !== '') {
         dispatch(searchProducts(searchValue));
      }
   }, [searchValue, dispatch]);

   const handleSearch = ({ target: { value } }) => {
      setSearchValue(value);

      // Отримуємо результати пошуку через getAllProducts
      dispatch(getAllProducts({ page: 1, search: value }));
   };

   const handleProductSelect = () => {
      // Скидаємо значення пошуку після вибору продукту
      setSearchValue('');
   };
   return (
      <header>
         <div className="header">
            <div className='header-up'>
               <div className='header-up__container container'>
                  <div className="header-up__mail mail-header">
                     <img src={Mail} alt="Mail" />
                     <div className="mail-header__text">agrosnabmarket@gmail.com</div>
                  </div>
                  <nav className="header-up__navigation navigation">
                     <ul className='navigation__block'>
                        <li className="navigation__list">
                           <Link to='/contacts' className="navigation__link">Контакти</Link>
                        </li>
                        <li className="navigation__list">
                           <Link to='/posts' className="navigation__link">Питання</Link>
                        </li>
                        <li className="navigation__list">
                           <Link to='/about' className="navigation__link">Про нас</Link>
                        </li>
                        <li className="navigation__list">
                           <Link to='/delivery' className="navigation__link">Доставка та оплата</Link>
                        </li>
                        <li className="navigation__list">
                           <Link to={ROUTES.COMMENTS} className="navigation__link">Відгуки</Link>
                        </li>
                     </ul>
                  </nav>
                  <button className="btn-login">
                     <img src={Account} alt="Account" />
                     {currentUser ? (
                        <div className='btn-login__container'>
                           <Link to={ROUTES.USER} className="btn-login__text">{currentUser.fullName}</Link>
                           <button type="submit" className="btn-login__logout" onClick={handleLogout}>
                              Вийти
                           </button>
                        </div>
                     ) : (
                        <div className="btn-login__text" onClick={openModal}>
                           Увійти
                        </div>
                     )}
                  </button>
                  <Modal active={isModalOpen} closeModal={closeModal} className="modal">
                     <Login onClose={() => closeModal()} />
                  </Modal>
               </div>
            </div>
         </div>
         <div className="header-body">
            <div className="header-body__container container">
               <Link to={ROUTES.HOME} className="header-body__logo logo">
                  <img src={Logo} alt="Logo" />
               </Link>
               <div className="header-body__time time">
                  <img src={Time} alt="Time" />
                  <div className="time__text">Працюємо щодня
                     з 08:00 до 20:00</div>
               </div>
               <div className="header-body__phone phone">
                  <img src={Phone} alt="Phone" />
                  <Link to='tel: +380506726443' className="phone__text">+38 (050) 672-64-43</Link>
               </div>
               <button type="submit" className="btn-callback" onClick={openCallbackModal}>
                  <h3>Зворотній виклик</h3>
               </button>
               <Modal active={isCallbackModalOpen} closeModal={closeCallbackModal} className="modal">
                  <CallbackForm onClose={closeCallbackModal} />
               </Modal>
            </div>
         </div>
         <div className="header-down">
            <div className="header-down__container container">
               <div className="header-down__category category-header" onClick={toggleCatalog}>
                  <img src={Burger} alt="Burger" />
                  <h3 className="category-header__text">Каталог продукції</h3>
                  {isCatalogOpen && <Catalog />}
               </div>
               <form class='form' >
                  <div class="form-input">
                     <input
                        type="text"
                        name="search"
                        placeholder="Введіть номер запчастини, назву або VIN"
                        className="form-input__search"
                        autoComplete="off"
                        onChange={handleSearch}
                        value={searchValue}
                     />
                     {searchValue && (
                        <div className="search-box">
                           {/* Відображення знайдених результатів */}
                           <ul>
                              {/* Відображення товарів зі співпадінням у заголовку */}
                              {searchResults
                                 .filter(result => result.title.toLowerCase().includes(searchValue.toLowerCase()))
                                 .map((result) => (
                                    <li key={result._id}>
                                       <Link to={`/products/${result._id}`} style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleProductSelect}>
                                          <img src={result.imageUrl[0]} alt={result.title} className="result-image" />
                                          <p>{result.article}</p>
                                          {result.title} - {result.price}₴
                                       </Link>
                                    </li>
                                 ))
                              }
                              {/* Відображення товарів зі співпадінням за тегами */}
                              {searchResults
                                 .filter(result =>
                                    result.tags.some(tag => tag.toLowerCase().includes(searchValue.toLowerCase()))
                                 )
                                 .map((result) => (
                                    <li key={result._id}>
                                       <Link to={`/products/${result._id}`} style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleProductSelect}>
                                          <img src={result.imageUrl[0]} alt={result.title} className="result-image" />
                                          <p>{result.article}</p>
                                          {result.title} - {result.price}₴
                                       </Link>
                                    </li>
                                 ))}
                           </ul>
                           {/* Повідомлення, якщо не знайдено жодного результату */}
                           {searchResults.length === 0 && (
                              <div>
                                 Цей товар ще не додано. Зателефонуйте нам і наші менеджери допоможуть Вам!{' '}
                                 <a className='notfound-phone' href="tel:+380506726443">+380506726443</a> або{' '}
                                 <a className='notfound-phone' href="tel:+380970699023">+380970699023</a>
                              </div>
                           )}
                        </div>
                     )}

                  </div>
                  <button type="button" class="btn-search" onClick={() => { window.location.href = '/search'; }}>
                     <img src={Search} alt="Search" />
                  </button>
               </form>

               <div className="btn-header">
                  <Link to={ROUTES.COMPARE}>
                     <button type="submit" className="btn-compare">
                        <img src={Compare} alt="Compare" />
                        <span className='btn-compare__count'>2</span>
                     </button>
                  </Link>
                  <Link to={ROUTES.CART}>
                     <button type="submit" className="btn-cart">
                        <img src={Cart} alt="Cart" />
                        <span className='btn-cart__count'>{cart.length}</span>
                     </button>
                  </Link>

               </div>
            </div>
         </div>
      </header>
   )
}

export default Header
