// SwiperComponent.jsx
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import '../../../node_modules/swiper/swiper-bundle.min.css';

const SwiperComponent = ({ items }) => {
   const [swiper, setSwiper] = useState(null);

   const handleSlideChange = (index) => {
      if (swiper) {
         swiper.slideTo(index);
      }
   };

   return (
      <div>
         <Swiper onSwiper={setSwiper} spaceBetween={10} slidesPerView={1}>
            {items.map((item, index) => (
               <SwiperSlide key={index}>
                  {/* Використайте параметр searchParam для побудови посилання */}
                  <Link to={{
                     pathname: '/655ddaafbd342d93bef0dd16/655f50f5817af6082789b044/products', // Замініть це на відповідний шлях
                     search: `?${item.searchParam}`, // Додайте параметри пошуку
                  }} className="swiper-link">
                     <div className={`main-swiper slide-${index + 1}`}>
                        <h2 className='swiper-title'>{item.title}</h2>
                        <p className='swiper-desc'>{item.description}</p>
                     </div>
                  </Link>
               </SwiperSlide>
            ))}
         </Swiper>
         <div className="radio-buttons">
            {items.map((_, index) => (
               <input
                  key={index}
                  type="radio"
                  name="swiper-radio"
                  onChange={() => handleSlideChange(index)}
               />
            ))}
         </div>
      </div>
   );
};

export default SwiperComponent;
