// OrderList.jsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrdersForUser, selectOrderLoading, selectOrderError, selectUserOrders } from '../../features/api/orderSlice';
import { selectCurrentUser } from '../../features/user/UserSlice';

const OrderList = () => {
   const dispatch = useDispatch();
   const orderLoading = useSelector(selectOrderLoading);
   const orderError = useSelector(selectOrderError);
   const currentUser = useSelector(selectCurrentUser);
   const userOrders = useSelector(selectUserOrders);

   const [currentPage, setCurrentPage] = useState(1);
   const [totalPages, setTotalPages] = useState(1);
   const pageSize = 6; // Розмір сторінки

   useEffect(() => {
      const fetchData = async () => {
         if (currentUser) {
            await dispatch(getAllOrdersForUser(currentUser.orders))
               .then(() => {
                  setTotalPages(Math.ceil(currentUser.orders.length / pageSize));
               })
               .catch((err) => {
                  console.error('Error loading user orders:', err);
               });
         }
      };

      fetchData();
   }, [dispatch, currentUser, pageSize]);

   useEffect(() => {
      console.log('All Orders:', userOrders);
   }, [userOrders]);

   useEffect(() => {
      console.log('currentPage:', currentPage);
      console.log('totalPages:', totalPages);
   }, [currentPage, totalPages]);

   if (!currentUser) {
      return (
         <div>
            <p>No orders yet. Please log in to view your orders.</p>
         </div>
      );
   }

   return (
      <div className='order-list'>
         <h2>Ваші замовлення:</h2>
         {userOrders && userOrders.length > 0 ? (
            <div>
               {userOrders
                  .filter(order => order.userId === currentUser._id)
                  .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                  .map((order, orderIndex) => (
                     <div key={order._id}>
                        {order.products && order.products.length > 0 && (
                           <div className='order-list__body body-order'>
                              <p>Замовлення №{(currentPage - 1) * pageSize + orderIndex + 1}</p>
                              <ul className='body-order__products'>
                                 {order.products.map((product, productIndex) => (
                                    <li key={productIndex}>
                                       <img src={product.imageUrl} alt={product.title} className='body-order__image' />
                                       <p>{product.title}</p>
                                       <p>Артикул: {product.article}</p>
                                       <p>Кількість: {product.quantity}</p>
                                    </li>
                                 ))}
                                 <div className="body-order__info">
                                    <p className='body-order__date'>Дата замовлення: {new Date(order.createdAt).toLocaleString()}</p>
                                    <p className='body-order__price'>Загальна сума: {order.totalPrice}₴</p>
                                 </div>
                              </ul>
                           </div>
                        )}
                     </div>
                  ))}
               {/* Пагінація */}
               {totalPages > 1 && (
                  <div className="pagination">
                     {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                        <button
                           key={page}
                           className={`button-page ${page === currentPage ? 'current-page' : ''}`}
                           onClick={() => setCurrentPage(page)}
                        >
                           {page}
                        </button>
                     ))}
                  </div>
               )}
            </div>
         ) : (
            <p>No orders yet.</p>
         )}
      </div>
   );
};

export default OrderList;
