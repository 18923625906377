import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Subcategories from './SubCategories';

const Categories = ({ isSidebar: isSidebarProp }) => {
   const { list: categoriesList } = useSelector(({ categories }) => categories);
   const [isSidebar, setIsSidebar] = useState(isSidebarProp);
   const [activeCategoryId, setActiveCategoryId] = useState(null);

   useEffect(() => {
      // Здесь можна виконати інші дії, пов'язані з отриманням категорій
   }, []);

   const handleCategoryMouseEnter = (categoryId) => {
      setActiveCategoryId(categoryId);
   };

   const handleCategoryMouseLeave = () => {
      setActiveCategoryId(null);
   };

   return (
      <div className={`categories ${isSidebar ? 'categories-sidebar' : 'categories-home'}`}>
         <div className={`categories-title ${isSidebar ? 'categories-title-sidebar' : 'categories-title-other'}`}>
            Категорії
         </div>
         <ul className={`categories-menu ${isSidebar ? 'categories-menu-sidebar' : 'categories-menu-home'}`}>
            {categoriesList.map(({ _id, title, imageUrl }) => (
               <li
                  key={_id}
                  onMouseEnter={() => handleCategoryMouseEnter(_id)}
                  onMouseLeave={handleCategoryMouseLeave}
               >
                  <NavLink
                     className={`categories-menu ${activeCategoryId === _id ? 'categories-menu:active' : ''}`}
                     to={`/categories/${_id}`} // Оновлено шлях без /categories
                  >
                     <div className="categories-item">
                        <img
                           className={`all-image ${isSidebar ? 'sidebar-image' : 'other-image'}`}
                           src={imageUrl}
                           alt={title}
                        />
                        <p className='category-title'>{title}</p>
                        {/* Вивід підкатегорій з використанням компонента Subcategories */}
                        {activeCategoryId === _id && <Subcategories categoryId={_id} />}
                     </div>
                  </NavLink>
               </li>
            ))}
         </ul>
      </div>
   );
};

export default Categories;
