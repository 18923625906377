import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, CATEGORIES_URL } from "../../utils/constants";

// Вставьте свой токен вместо 'ваш_токен'
const yourToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTVjNjJkMDBkZmJjM2Y2ZDY1Mzc3YWEiLCJpYXQiOjE3MDA1NTM0MjQsImV4cCI6MTcwMzE0NTQyNH0.nm6SaW45iamizCz6Q6q52K45tho9jXEsAmpfmxGpqZo';

export const getCategories = createAsyncThunk(
   'categories/getCategories',
   async (_, thunkAPI) => {
      try {
         const res = await axios.get(`${CATEGORIES_URL}/categories`, {
            headers: {
               Authorization: `Bearer ${yourToken}`,
            },
         });

         return res.data;
      } catch (err) {
         console.log(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

const categoriesSlice = createSlice({
   name: 'categories',
   initialState: {
      list: [],
      isLoading: false
   },
   extraReducers: (builder) => {
      builder.addCase(getCategories.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getCategories.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getCategories.rejected, (state) => {
         state.isLoading = false;
      });
   }
});

export default categoriesSlice.reducer;
