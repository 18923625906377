import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../components/Pages/Home/Home.jsx'
import ProductList from '../components/Products/ProductList.jsx'
import { ROUTES } from '../utils/routes.js'
import Brands from '../components/Brands/Brands.jsx'
import ProductDetails from '../components/Products/Product.jsx'
import Contacts from '../components/Pages/Other/Contacts.jsx'
import Delivery from '../components/Pages/Other/Delivery.jsx'
import Cart from '../components/Cart/Cart.jsx'
import Actions from '../components/Products/Actions.jsx'
import About from '../components/Pages/Other/About.jsx'
import Posts from '../components/Pages/Other/Posts.jsx'
import Comments from '../components/Pages/Other/Comments.jsx'
import BrandsPage from '../components/Brands/BrandsPage.jsx'
import UserPage from '../components/User/UserPage.jsx'
import Confidential from '../components/Pages/Publical/Confidentional.jsx'
import Exchange from '../components/Pages/Publical/Exchange.jsx'
import FullPost from '../components/Pages/Other/FullPost.jsx'
import OrderList from '../components/Cart/OrderList.jsx'
import CategoriesList from '../components/Categories/CategoriesList.jsx'
import NotFound from '../components/Pages/Other/NotFound.jsx'


const AppRoutes = () => {
   return (
      <Routes>
         <Route index element={<Home />} />
         <Route path={ROUTES.PRODUCTS} element={<ProductList />} />
         <Route path="/products/:productId" element={<ProductDetails />} />
         <Route path='/brands' element={<Brands showAll={true} />} />
         <Route path={`${ROUTES.PRODUCTS}/:productId`} element={<ProductDetails />} />
         <Route path={ROUTES.CONTACTS} element={<Contacts />} />
         <Route path={ROUTES.DELIVERY} element={<Delivery />} />
         <Route path={`${ROUTES.PRODUCTS}/pages/:page`} element={<ProductList />} />
         <Route path="/next-page" element={<ProductList />} />
         <Route path={ROUTES.CART} element={<Cart />} />
         <Route path={ROUTES.CART} element={<Cart />} />
         <Route path={ROUTES.ACTIONS} element={<Actions />} />
         <Route path={ROUTES.ABOUT} element={<About />} />
         <Route path={ROUTES.POSTS} element={<Posts />} />
         <Route path={ROUTES.COMMENTS} element={<Comments />} />
         <Route path='/brands/:brandId' element={<BrandsPage />} />
         <Route path="/brands/:brandId/*" element={<BrandsPage />} />
         <Route path="/brands/:brandId/next-page" element={<BrandsPage />} />
         <Route path="/auth/me" element={<UserPage />} />
         <Route path={ROUTES.CONFIDENTIONAL} element={<Confidential />} />
         <Route path={`${ROUTES.ACTIONS}/*`} element={<BrandsPage />} />
         <Route path={`${ROUTES.ACTIONS}/next-page`} element={<BrandsPage />} />
         <Route path={ROUTES.EXCHANGE} element={<Exchange />} />
         <Route path="/posts/:id" element={<FullPost />} />
         <Route path="/cart/order" element={<OrderList />} />
         <Route exact path="/categories/:categoryId" element={<CategoriesList />} />
         <Route path="*" element={<NotFound />} />

      </Routes>
   )
}

export default AppRoutes
