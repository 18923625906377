import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios.js";

import { BASE_URL, CATEGORIES_URL, REGISTER_URL } from "../../utils/constants";


// Перевірка токену в локальному сховищі
const storedToken = window.localStorage.getItem('token');
// Вставьте свой токен вместо 'ваш_токен'


export const getCategories = createAsyncThunk(
   'categories/getCategories',
   async (_, thunkAPI) => {
      try {
         const res = await axios.get(`${CATEGORIES_URL}/categories`, {

         });

         return res.data;
      } catch (err) {
         console.log(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);
// Додайте нову дію для реєстрації
export const registerUser = createAsyncThunk(
   'user/registerUser',
   async ({ email, fullName, password }, thunkAPI) => {
      try {
         const res = await axios.post(`${REGISTER_URL}/register`, {
            email,
            fullName,
            password,
         });

         return res.data;
      } catch (err) {
         console.log(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

export const loginUser = createAsyncThunk(
   'user/loginUser',
   async ({ email, password, _id }, thunkAPI) => {
      try {
         let res;

         // Опція входу за _id
         if (_id) {
            res = await axios.post(`${REGISTER_URL}/login`, { _id });
         } else {
            // Звичайний вхід за емейлом та паролем
            res = await axios.post(`${REGISTER_URL}/login`, { email, password });
         }

         const token = res.data.token;

         if (token) {
            window.localStorage.setItem('token', token);
         } else {
            alert('Не вдалося увійти. Токен не отримано від сервера.');
         }

         return res.data;

      } catch (err) {
         console.log(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);
// Додайте нову дію для отримання користувача
export const getUser = createAsyncThunk("user/getUser", async (_, thunkAPI) => {
   try {
      const res = await axios.get("/auth/me"); // Оновлено шлях

      return res.data;
   } catch (err) {
      console.log(err);
      return thunkAPI.rejectWithValue(err.response.data);
   }
});
export const getUsers = createAsyncThunk(
   'user/getUsers',
   async (_, thunkAPI) => {
      try {
         const response = await axios.get('/auth/users/all'); // Маршрут /all для отримання всіх користувачів
         return response.data;
      } catch (err) {
         console.error(err);
         return thunkAPI.rejectWithValue(err.response.data);
      }
   }
);
export const getUserByToken = createAsyncThunk(
   'user/getUserByToken',
   async (token, thunkAPI) => {
      try {
         const res = await axios.get('/auth/me', {
            headers: {
               Authorization: `Bearer ${token}`,
            },
         });

         return res.data;
      } catch (err) {
         console.log(err);
         return thunkAPI.rejectWithValue(err.response.data);
      }
   }
);

export const logOut = createAsyncThunk('user/logOut', async (_, thunkAPI) => {
   try {
      // Очищення токену з локального сховища
      window.localStorage.removeItem('token');
      return null; // Можете повернути будь-які дані після успішного виходу
   } catch (err) {
      console.log(err);
      return thunkAPI.rejectWithValue(err);
   }
});

// Якщо є токен, можна викликати сервер для отримання інформації про користувача
if (storedToken) {
   // Ось де ви можете викликати екшен або функцію для отримання інформації про користувача
   // Наприклад, ємітуючи екшен `getUser` або викликаючи функцію, яка викликає сервер для отримання даних про користувача
}


const userSlice = createSlice({
   name: 'user',
   initialState: {
      currentUser: null,
      cart: [],
      isLoading: false,
      error: null,
      users: [],
   },
   reducers: {
      setCurrentUser: (state, action) => {
         state.currentUser = action.payload;
      },
      clearCurrentUser: (state) => {
         state.currentUser = null;
      },
   },
   extraReducers: (builder) => {
      // Додаємо обробку для реєстрації
      builder.addCase(registerUser.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(registerUser.fulfilled, (state, action) => {
         state.isLoading = false;
         state.currentUser = action.payload;
      });
      builder.addCase(registerUser.rejected, (state, action) => {
         state.isLoading = false;
         state.error = action.payload;
      });
      builder.addCase(loginUser.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(loginUser.fulfilled, (state, action) => {
         state.isLoading = false;
         state.currentUser = action.payload;
         state.error = null; // Очистити будь-які попередні помилки входу
      });
      builder.addCase(loginUser.rejected, (state, action) => {
         state.isLoading = false;
         state.error = action.payload;
      });
      // Додаємо обробку для отримання користувача
      builder.addCase(getUser.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getUser.fulfilled, (state, action) => {
         state.isLoading = false;
         state.currentUser = action.payload;
      });
      builder.addCase(getUser.rejected, (state, action) => {
         state.isLoading = false;
         state.currentUser = null; // Очистити currentUser при закінченні токена
         state.error = action.payload;
      });
      // Додайте новий випадок для виходу в extraReducers
      builder.addCase(logOut.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(logOut.fulfilled, (state) => {
         state.isLoading = false;
         state.currentUser = null; // Очистити currentUser
      });
      builder.addCase(logOut.rejected, (state, action) => {
         state.isLoading = false;
         state.error = action.payload;
      });
      builder.addCase(getUsers.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getUsers.fulfilled, (state, action) => {
         state.isLoading = false;
         state.users = action.payload;
      });
      builder.addCase(getUsers.rejected, (state, action) => {
         state.isLoading = false;
         state.error = action.payload;
      });
      builder.addCase(getUserByToken.fulfilled, (state, action) => {
         state.isLoading = false;
         const { user, orders } = action.payload;

         if (user?._id && user?.fullName && user?.avatarUrl) {
            // Якщо ви отримали _id, fullName і avatarUrl, встановіть користувача в стані
            state.currentUser = { ...user, orders };
         }

         state.error = null;
      });
   }
});


export const { setUser, loginUserSuccess, loginUserFailure, setError } = userSlice.actions;
export const { setCurrentUser, clearCurrentUser } = userSlice.actions;
export const selectCurrentUser = (state) => state.user.currentUser;
export const selectError = (state) => state.user.error;
export const selectUsers = (state) => state.user.users;
export default userSlice.reducer;
