// commentsForProductSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constants";

export const getCommentsForProduct = createAsyncThunk(
   'commentsForProduct/getCommentsForProduct',
   async ({ categoryId, subcategoryId, productId }, thunkAPI) => {
      try {
         const res = await axios.get(`${BASE_URL}/${categoryId}/${subcategoryId}/products/${productId}/comments`);
         return res.data;
      } catch (err) {
         console.error(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

export const addCommentForProduct = createAsyncThunk(
   'commentsForProduct/addCommentForProduct',
   async ({ categoryId, subcategoryId, productId, comment }, thunkAPI) => {
      try {
         const res = await axios.post(
            `${BASE_URL}/${categoryId}/${subcategoryId}/products/${productId}/comments`,
            { text: comment }
         );
         return { productId, comment: res.data };
      } catch (err) {
         console.error(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

const commentsForProductSlice = createSlice({
   name: 'commentsForProduct',
   initialState: {
      list: [],
      isLoading: false,
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(getCommentsForProduct.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getCommentsForProduct.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getCommentsForProduct.rejected, (state) => {
         state.isLoading = false;
      });

      builder.addCase(addCommentForProduct.fulfilled, (state, { payload }) => {
         if (!state.list || !Array.isArray(state.list)) {
            state.list = [];
         }

         const updatedComment = { ...payload.comment, productId: payload.productId };

         state.list = [...state.list, updatedComment];
      });
   },
});

// Додавання селектора
export const selectCommentsForProduct = (state) => state.commentsForProduct.list;

export default commentsForProductSlice.reducer;
