import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../../features/categories/categoriesSlice';
import { loginUser, selectCurrentUser, setError, getUserByToken } from '../../features/user/UserSlice';
import AppRoutes from '../../routes/Routes';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Redirect from '../../routes/Redirect';


const App = () => {
   const dispatch = useDispatch();
   const currentUser = useSelector(selectCurrentUser);

   useEffect(() => {
      // Викликайте екшен для отримання категорій
      dispatch(getCategories());

      // Отримайте токен з локального сховища
      const storedToken = window.localStorage.getItem('token');

      // Перевірте токен та викличте функцію для отримання інформації про користувача
      const checkToken = async () => {
         try {
            if (storedToken) {
               const userData = await dispatch(getUserByToken(storedToken));

               console.log('User Data:', userData);

               if (userData.payload?.error) {
                  dispatch(setError(userData.payload.error));
               } else {
                  // Перевірте, чи відповідь має необхідні дані для входу
                  if (userData.payload?._id) {
                     // Викличте loginUser для входу, передавши _id
                     await dispatch(loginUser({ _id: userData.payload._id }));
                  }
               }

               return userData;
            } else {
               return null;
            }
         } catch (error) {
            console.error('Error checking token:', error);
            throw error;
         }
      };


      // Викликайте функцію для перевірки токену
      checkToken()
         .then((userData) => {
            // Обробка отриманих даних користувача, якщо потрібно
         })
         .catch((error) => {
            console.error('Error checking token:', error);
         });
   }, [dispatch]);

   return (
      <div className='app'>
         <Header />
         <div className="wrapper">
            <AppRoutes />
         </div>
         <Footer />
         <Redirect />
      </div>

   );
};

export default App;

