import React from 'react'
import { Link } from "react-router-dom"
import { ROUTES } from "../../utils/routes.js"
import Logo from '../../images/headers/logo.svg'
import Phone from '../../images/headers/phone.svg'
import Time from '../../images/footers/time.svg'
import Local from '../../images/footers/Location.svg'
import Mail from '../../images/footers/mail.svg'

const Footer = () => {
   return (
      <div>
         <div className="footer-up">
            <div className="footer-up__container container">
               <Link to={ROUTES.HOME} className=" logo">
                  <img src={Logo} alt="Logo" />
               </Link>
               <div className="phone">
                  <img src={Phone} alt="Phone" />
                  <Link to='tel: +380506726443' className="phone__text">+38 (050) 672-64-43</Link>
               </div>
               <button type="submit" className="btn-callback">
                  <h3>Зворотній виклик</h3>
               </button>
            </div>
         </div>
         <div className="footer-body">
            <div className="footer-body__container container">
               <div className='footer-body__info info-footer'>
                  <div className="info-footer__time time-footer">
                     <img src={Time} alt="Time" className="time-footer__image" />
                     <h5 className="time-footer__text">Щодня з 08:00 до 20:00</h5>
                  </div>
                  <div className="info-footer__location location-footer">
                     <img src={Local} alt="Local" className="location-footer__image" />
                     <h5 className="location-footer__text">Кіровоградська обл, м.Олександрія,
                        вул. Козацький шлях 89</h5>
                  </div>
                  <div className="info-footer__mail mail-footer">
                     <img src={Mail} alt="Mail" className="mail-footer__image" />
                     <h5 className="mail-footer__text">agrosnabmarket@gmail.com</h5>
                  </div>
               </div>
               <nav className="footer-navigation">
                  <ul className="footer-navigation__title title-footer">
                     <h4> Головне меню</h4>
                     <li className="footer-navigation__list">
                        <Link to={ROUTES.HOME} className="footer-navigation__link">Головна</Link>
                        <Link to='/contacts' className="footer-navigation__link">Контакти</Link>
                        <Link to={ROUTES.POSTS} className="footer-navigation__link">Питання</Link>
                        <Link to='/about' className="footer-navigation__link">Про нас</Link>
                        <Link to={ROUTES.DELIVERY} className="footer-navigation__link">Доставка та оплата</Link>
                     </li>
                  </ul>
               </nav>
               <nav className="footer-navigation">
                  <ul>
                     <h4 className="footer-navigation__title title-footer"> Профіль</h4>
                     <li className="footer-navigation__list">
                        <Link to={ROUTES.USER} className="footer-navigation__link">Увійти</Link>
                        <Link to='/contacts' className="footer-navigation__link">Налаштування</Link>
                        <Link to={ROUTES.EXCHANGE} className="footer-navigation__link">Обмін/Повернення</Link>
                     </li>
                  </ul>
               </nav>
               <div className="footer-body__map map">
                  <h4 className="map-title title-footer"> ми на карті</h4>
                  <iframe title='map' className='map-footer' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d854.3353151328367!2d33.083359533045545!3d48.66474991213199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d09d3a8d417e0f%3A0xd6a5d55a3490acf7!2z0JDQs9GA0L7RgdC90LDQsQ!5e0!3m2!1sru!2sua!4v1699333950711!5m2!1sru!2sua" width="187" height="125" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
               </div>
            </div>
         </div>
         <div className="footer-down">
            <div className="footer-down__container container">
               <div className="footer-down__privat privat">
                  <Link to={ROUTES.CONFIDENTIONAL} className='privat__political'>Політика конфіденційності</Link>
                  <h6 className='privat__copyright'>© ФОП Агроснаб, 2007 - 2024</h6>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Footer
