import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, getAllProducts } from '../../features/categories/productsSlice';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import Cart from '../../images/headers/cart.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToCart } from '../../features/categories/productSlice';
import { Helmet } from 'react-helmet';
const PAGE_SIZE = 12;

const ProductList = () => {
   const { categoryId, subcategoryId, page = '1' } = useParams();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [currentPage, setCurrentPage] = useState(parseInt(page, 10));
   const [minPrice, setMinPrice] = useState(0);
   const [showAutoProducts, setShowAutoProducts] = useState(false);
   const [showIndustrialProducts, setShowIndustrialProducts] = useState(false); // Додайте новий стан для промисловості
   const [showTechProducts, setShowTechProducts] = useState(false); // Додайте новий стан для техніки
   const [showCombineProducts, setShowCombineProducts] = useState(false)
   const [showMotoTractorProducts, setShowMotoTractorProducts] = useState(false)
   const [showBalersProducts, setShowBalersProducts] = useState(false)
   const [showTractorProducts, setShowTractorProducts] = useState(false)
   const [showReapersProducts, setShowReapersProducts] = useState(false)
   const [showBikeProducts, setShowBikeProducts] = useState(false)
   const [showOtherTechnicProducts, setShowOtherTechnicProducts] = useState(false)
   const [internalSizeFilter, setInternalSizeFilter] = useState({ min: 0, max: Infinity });
   const [externalSizeFilter, setExternalSizeFilter] = useState({ min: 0, max: Infinity }); // Додав новий стан для externalSize
   const [widthFilter, setWidthFilter] = useState({ min: 0, max: Infinity }); // Додав новий стан для width
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const [isSidebarEnabled, setIsSidebarEnabled] = useState(false);
   const [sortOrder, setSortOrder] = useState(null);
   const [updatedProducts, setUpdatedProducts] = useState([]);
   const [isEmptyFilterResult, setIsEmptyFilterResult] = useState(false);

   useEffect(() => {
      if (
         showAutoProducts ||
         showIndustrialProducts ||
         showTechProducts ||
         showCombineProducts ||
         showMotoTractorProducts ||
         showBalersProducts ||
         showTractorProducts ||
         showReapersProducts ||
         showBikeProducts ||
         showOtherTechnicProducts
      ) {
         setIsSidebarEnabled(true);
      } else {
         setIsSidebarEnabled(false);
      }
   }, [
      showAutoProducts,
      showIndustrialProducts,
      showTechProducts,
      showCombineProducts,
      showMotoTractorProducts,
      showBalersProducts,
      showTractorProducts,
      showReapersProducts,
      showBikeProducts,
      showOtherTechnicProducts
   ]);

   // Використовуйте інше ім'я змінної, щоб уникнути конфлікту імен
   const autoProductsParam = searchParams.get('showAutoProducts') === 'true';
   const industrialProductsParam = searchParams.get('showIndustrialProducts') === 'true';
   const fermerProductsParam = searchParams.get('showTechProducts') === 'true';
   // Додайте інші параметри за потреби

   // Встановіть значення станів
   useEffect(() => {
      setShowAutoProducts(autoProductsParam);
      setShowIndustrialProducts(industrialProductsParam);
      setShowTechProducts(fermerProductsParam)
      // Встановіть інші параметри за потреби
   }, [autoProductsParam, industrialProductsParam, fermerProductsParam]);



   const handleAddToCart = (product) => {
      dispatch(addToCart({
         productId: product._id,
         title: product.title,
         brands: product.brands,
         price: product.price,
         quantity: 1,
         imageUrl: product.imageUrl,
         article: product.article,
      }));

      toast.success(`Товар "${product.title}" додано до кошика!`);
   };
   useEffect(() => {
      if (categoryId) {
         if (subcategoryId) {
            // Завантаження всіх товарів
            dispatch(getAllProducts({ page: currentPage, categoryId }))

         }
      }
   }, [dispatch, categoryId, subcategoryId, currentPage]);



   const { list: products, isLoading, isError, totalProducts } = useSelector((state) => state.products);
   const filteredProducts = products.filter(product => {
      const isInSizeRange =
         !internalSizeFilter ||
         (product.internalSize >= internalSizeFilter.min &&
            product.internalSize <= internalSizeFilter.max);
      const isInExternalSizeRange =
         !externalSizeFilter ||
         (product.externalSize >= externalSizeFilter.min &&
            product.externalSize <= externalSizeFilter.max);
      const isInWidthRange =
         !widthFilter ||
         (product.width >= widthFilter.min &&
            product.width <= widthFilter.max);

      return (
         product.price > minPrice &&
         (!showAutoProducts || product.placeOfUse.includes("авто")) &&
         (!showIndustrialProducts || product.placeOfUse.includes("промисловість")) &&
         (!showTechProducts || product.placeOfUse.includes("техніка")) &&
         (!showCombineProducts || product.manufacturer.includes("Комбайни")) &&
         (!showMotoTractorProducts || product.manufacturer.includes("Мотоблоки")) &&
         (!showTractorProducts || product.manufacturer.includes("Трактори")) &&
         (!showBalersProducts || product.manufacturer.includes("Прес-підбирачі")) &&
         (!showReapersProducts || product.manufacturer.includes("Жатки")) &&
         (!showBikeProducts || product.manufacturer.includes("Мотоцикли")) &&
         (!showOtherTechnicProducts || product.manufacturer.includes("Інша техніка")) &&
         isInSizeRange &&
         isInExternalSizeRange &&
         isInWidthRange
      );
   });
   const subcategoryProducts = sortOrder ?
      updatedProducts.filter(product => product.subcategoryId === subcategoryId)
      :
      products.filter(product => product.subcategoryId === subcategoryId);
   const isFiltered =
      internalSizeFilter.min !== 0 ||
      internalSizeFilter.max !== Infinity ||
      externalSizeFilter.min !== 0 ||
      externalSizeFilter.max !== Infinity ||
      widthFilter.min !== 0 ||
      widthFilter.max !== Infinity;
   console.log(isFiltered)
   const displayedSubcategoryProducts =
      isFiltered ?
         filteredProducts.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
         :
         subcategoryProducts.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

   const displayedProducts = filteredProducts.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

   const handleBack = () => {
      if (currentPage > 1) {
         const nextPage = currentPage - 1;
         navigate(`/${categoryId}/${subcategoryId}/products/pages/${nextPage}`);
         setCurrentPage(nextPage);
      }
   };

   const handleForward = () => {
      const totalPages = Math.ceil(filteredProducts.length / PAGE_SIZE);
      if (currentPage < totalPages) {
         const nextPage = currentPage + 1;
         navigate(`/${categoryId}/${subcategoryId}/products/pages/${nextPage}`);
         setCurrentPage(nextPage);
      }
   };
   const handleSortChange = (event) => {
      const newSortOrder = event.target.value;
      setSortOrder(newSortOrder);

      // Оновлюємо список продуктів при зміні порядку сортування
      updateFilteredProducts(newSortOrder);
   };

   const sortProducts = (products, order) => {
      if (order === 'asc_price') {
         return [...products].sort((a, b) => a.price - b.price);
      } else if (order === 'desc_price') {
         return [...products].sort((a, b) => b.price - a.price);
      } else if (order === 'asc_internal') {
         return [...products].sort((a, b) => a.internalSize - b.internalSize);
      } else if (order === 'desc_internal') {
         return [...products].sort((a, b) => b.internalSize - a.internalSize);
      } else if (order === 'asc_external') {
         return [...products].sort((a, b) => a.externalSize - b.externalSize);
      } else if (order === 'desc_external') {
         return [...products].sort((a, b) => b.externalSize - a.externalSize);
      }
      return products;
   };

   const updateFilteredProducts = (order) => {
      const sortedProducts = sortProducts(subcategoryProducts, order);
      // Оновлюємо список продуктів, який буде відображений у залежності від порядку сортування
      setUpdatedProducts(sortedProducts);
   };

   useEffect(() => {
      setIsEmptyFilterResult(displayedProducts.length === 0);
   }, [displayedProducts]);

   const totalPages =
      !isFiltered ?
         Math.ceil((isFiltered ? filteredProducts.length : subcategoryProducts.length) / PAGE_SIZE)
         :
         Math.ceil(filteredProducts.length / PAGE_SIZE);

   const pagesToShow = [];

   if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
         pagesToShow.push(i);
      }
   } else {
      const startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(totalPages, startPage + 2);

      if (startPage > 1) {
         pagesToShow.push(1, '...');
      }

      for (let i = startPage; i <= endPage; i++) {
         pagesToShow.push(i);
      }

      if (endPage < totalPages) {
         pagesToShow.push('...', totalPages);
      }
   }


   if (isLoading) {
      return <div>Завантаження...</div>;
   }
   if (!products || !products.length) {
      return <div>Товарів не знайдено</div>;
   }

   return (
      <div className='catalog'>
         <select value={sortOrder || ''} onChange={handleSortChange} className='catalog-sort'>
            <option value="" disabled hidden>Виберіть порядок сортування</option>
            <option value="asc_price">Ціна: за зростанням</option>
            <option value="desc_price">Ціна: за спаданням</option>
            <option value="asc_internal">Внутрішній розмір: за зростанням</option>
            <option value="desc_internal">Внутрішній розмір: за спаданням</option>
            <option value="asc_external">Зовнішній розмір: за зростанням</option>
            <option value="desc_external">Зовнішній розмір: за спаданням</option>
         </select>
         <div className='products-container container'>
            <Sidebar setMinPrice={setMinPrice}
               showAutoProducts={showAutoProducts}
               setShowAutoProducts={setShowAutoProducts}
               showIndustrialProducts={showIndustrialProducts} // Передайте стан для промисловості
               setShowIndustrialProducts={setShowIndustrialProducts} // Передайте функцію для промисловості
               showTechProducts={showTechProducts} // Передайте стан для техніки
               setShowTechProducts={setShowTechProducts} // Передайте функцію для техніки
               showCombineProducts={showCombineProducts} // Передайте стан для комбайнів
               setShowCombineProducts={setShowCombineProducts}
               showMotoTractorProducts={showMotoTractorProducts}
               setShowMotoTractorProducts={setShowMotoTractorProducts}
               setInternalSizeFilter={setInternalSizeFilter}  // Передайте функцію для фільтрації за розміром
               setExternalSizeFilter={setExternalSizeFilter} // Додав externalSize до Sidebar
               setWidthFilter={setWidthFilter} // Додав width до Sidebar
               showBalersProducts={showBalersProducts}
               setShowBalersProducts={setShowBalersProducts}
               showTractorProducts={showTractorProducts}
               setShowTractorProducts={setShowTractorProducts}
               showReapersProducts={showReapersProducts}
               setShowReapersProducts={setShowReapersProducts}
               showBikeProducts={showBikeProducts}
               setShowBikeProducts={setShowBikeProducts}
               showOtherTechnicProducts={showOtherTechnicProducts}
               setShowOtherTechnicProducts={setShowOtherTechnicProducts}
            />
            {isEmptyFilterResult && <div>Цей товар ще не додано. Зателефонуйте нам і наші менеджери допоможуть Вам!
               {' '}
               <a className='notfound-phone ' href="tel:+380506726443">+380506726443</a>
               {' '}
               або
               {' '}
               <a className='notfound-phone' href="tel:+380970699023">+380970699023</a></div>}
            <div className='products-content'>
               {/* Використовуємо displayedSubcategoryProducts за замовчуванням, якщо фільтри не активовані */}
               {!isSidebarEnabled && (
                  <>
                     {/* Очищення показу підкатегорій */}
                     {displayedSubcategoryProducts.map((product) => (
                        <Link
                           to={`/products/${product._id}`}
                           key={product._id}
                           style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                           <div className='product-card'>
                              <img src={product.imageUrl[0]} alt={product.title} className='product-card__image' />
                              <p className='product-card__title'>{product.title}</p>
                              <div className="product-card__available">
                                 <div className={`product-card__avail ${product.isAvailable ? 'available' : 'unavailable'}`}></div>
                                 <div className='product-card__text'>{product.isAvailable ? 'в наявності' : 'відсутній'}</div>
                              </div>
                              <div className="product-card__buy">
                                 <div className="product-card__price">{product.price}₴</div>
                                 <button
                                    type="button"
                                    className="btn-productcart"
                                    onClick={(e) => {
                                       e.preventDefault();
                                       handleAddToCart(product);
                                    }}
                                 >
                                    <img src={Cart} alt="Cart" />
                                 </button>
                              </div>
                           </div>
                        </Link>
                     ))}
                  </>
               )}
               {/* Перемикаємо на displayedProducts, якщо Sidebar ввімкнено */}
               {isSidebarEnabled && displayedProducts.map((product) => (
                  <Link
                     to={`/products/${product._id}`}
                     key={product._id}
                     style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                     <div className='product-card'>
                        <img src={product.imageUrl[0]} alt={product.title} className='product-card__image' />
                        <p className='product-card__title'>{product.title}</p>
                        <div className="product-card__available">
                           <div className={`product-card__avail ${product.isAvailable ? 'available' : 'unavailable'}`}></div>
                           <div className='product-card__text'>{product.isAvailable ? 'в наявності' : 'відсутній'}</div>
                        </div>
                        <div className="product-card__buy">
                           <div className="product-card__price">{product.price}₴</div>
                           <button
                              type="button"
                              className="btn-productcart"
                              onClick={(e) => {
                                 e.preventDefault();
                                 handleAddToCart(product);
                              }}
                           >
                              <img src={Cart} alt="Cart" />
                           </button>
                        </div>
                     </div>
                  </Link>
               ))}
            </div>
         </div>
         <ToastContainer />
         <div className="pagination container">
            <button type="button" className="pagination__btn" onClick={handleBack} disabled={currentPage === 1}>
               Назад
            </button>
            {pagesToShow.map((pageNumber, index) => (
               <button
                  key={index}
                  className={`button-page ${pageNumber === currentPage ? 'current-page' : ''}`}
                  onClick={() => {
                     if (typeof pageNumber === 'number') {
                        navigate(`/${categoryId}/${subcategoryId}/products/pages/${pageNumber}`);
                        setCurrentPage(pageNumber);
                     }
                  }}
               >
                  {pageNumber}
               </button>
            ))}
            <button type="button" className="pagination__btn" onClick={handleForward} disabled={currentPage * PAGE_SIZE >= totalProducts}>
               Вперед
            </button>
         </div>

      </div>
   );
};

export default ProductList;
