import React, { Component } from 'react';



class Modal extends Component {
   render() {
      const { active, closeModal, children } = this.props;
      return (
         <div className={active ? "modal active" : "modal"} onClick={closeModal}>
            <div className='modal__content' onClick={e => e.stopPropagation()}>
               {children}
               <button onClick={closeModal} className='close'>Close Modal</button>
            </div>
         </div>
      );
   }
}

export default Modal;