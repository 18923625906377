import { configureStore } from "@reduxjs/toolkit";
import categoriesSlice from "./categories/categoriesSlice";
import subcategoriesSlice from "./categories/subCategoriesSlice";
import productsSlice from "../features/categories/productsSlice"; // Добавлено
import productSlice from "../features/categories/productSlice";
import userSlice from "../features/user/UserSlice";

import { apiSlice } from "./api/ApiSlice";
import postsSlice from "./categories/postsSlice";
import CommentsSlice from "./categories/CommentsSlice";
import commentsForProductSlice from "./categories/commentsForProductSlice";
import orderSlice from "./api/orderSlice";

export const store = configureStore({
   reducer: {
      categories: categoriesSlice,
      subcategories: subcategoriesSlice,
      products: productsSlice, // Добавлено
      product: productSlice,
      user: userSlice,
      posts: postsSlice,
      comments: CommentsSlice,
      commentsForProduct: commentsForProductSlice,
      order: orderSlice,
      [apiSlice.reducerPath]: apiSlice.reducer,
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(apiSlice.middleware),
   devTools: true,
});

