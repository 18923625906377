import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { COMMENTS_URL } from "../../utils/constants";

// Створення асинхронного thunk для отримання коментарів
export const getComments = createAsyncThunk(
   'comments/getComments',
   async (_, thunkAPI) => {
      try {
         const res = await axios(`${COMMENTS_URL}`);
         return res.data;
      } catch (err) {
         console.error(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

// Створення асинхронного thunk для додавання коментаря
export const addComment = createAsyncThunk(
   'comments/addComment',
   async (commentData, thunkAPI) => {
      try {
         const res = await axios.post(`http://localhost:4444/comments`, commentData);
         return res.data;
      } catch (err) {
         console.error(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

// Створення слайса для коментарів
const commentsSlice = createSlice({
   name: 'comments',
   initialState: {
      list: [],
      isLoading: false,
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(getComments.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getComments.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getComments.rejected, (state) => {
         state.isLoading = false;
      });

      builder.addCase(addComment.fulfilled, (state, { payload }) => {
         state.list.push(payload);
      });
   },
});

// Експорт редуктора та дій для використання в інших частинах додатку
export default commentsSlice.reducer;

