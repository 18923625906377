import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SUBCATEGORIES_URL } from "../../utils/constants";

// Створення асинхронного thunk для отримання брендів
export const getBrands = createAsyncThunk(
   'brands/getBrands',
   async (_, thunkAPI) => {
      try {
         const res = await axios(`${SUBCATEGORIES_URL}/brands`);
         return res.data;
      } catch (err) {
         console.log(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

// Створення слайса для брендів
const brandsSlice = createSlice({
   name: 'brands',
   initialState: {
      list: [],
      isLoading: false,
   },
   reducers: {
      // Додайте додаткові редуктори або дії для брендів, якщо необхідно
   },
   extraReducers: (builder) => {
      builder.addCase(getBrands.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getBrands.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getBrands.rejected, (state) => {
         state.isLoading = false;
      });
   },
});

// Експорт дій та редуктора для використання в інших частинах додатку
export const { /* Додайте інші дії, якщо потрібно */ } = brandsSlice.actions;

export default brandsSlice.reducer;
