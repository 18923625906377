import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts, selectProducts, setBrandId } from '../../features/categories/productsSlice';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import Cart from '../../images/headers/cart.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { addToCart } from '../../features/categories/productSlice';
import Pagination from '../pagination/Pagination';



const BrandsPage = () => {
   const { categoryId, subcategoryId, page = '1' } = useParams();
   const navigate = useNavigate();
   const [currentPage, setCurrentPage] = useState(parseInt(page, 10));
   const { brandId } = useParams();
   const dispatch = useDispatch();
   const { list: products, isLoading, isError, totalProducts, currentPage: storeCurrentPage, pageSize } = useSelector(selectProducts);

   useEffect(() => {
      if (brandId) {
         dispatch(setBrandId(brandId));
         dispatch(getAllProducts({ categoryId, subcategoryId, page: currentPage, brandId }));
      }
   }, [dispatch, categoryId, subcategoryId, currentPage, brandId]);

   useEffect(() => {
      setCurrentPage(1); // Скидаємо поточну сторінку при зміні brandId
   }, [brandId]);

   const filteredProducts = products.filter(product => {
      return (
         product.brands &&
         product.brands.length > 0 &&  // Перевірка, що масив brands не порожній
         product.brands.includes(brandId)
      );
   });

   const displayedProducts = filteredProducts.slice((currentPage - 1) * pageSize, currentPage * pageSize);

   const handleAddToCart = (product) => {
      dispatch(addToCart({
         productId: product._id,
         title: product.title,
         brands: product.brands,
         price: product.price,
         quantity: 1,
         imageUrl: product.imageUrl,
         article: product.article,
      }));

      toast.success(`Товар "${product.title}" додано до кошика!`);
   };

   const handlePageChange = (newPage) => {
      navigate(`/brands/${brandId}/pages/${newPage}`);
      setCurrentPage(newPage);
   };
   return (
      <div className='catalog'>
         <div className='products-container container'>
            <Sidebar />
            <div className='products-content'>
               {displayedProducts.length > 0 ? (
                  displayedProducts.map((product) => (
                     <Link
                        to={`/products/${product._id}`}
                        key={product._id}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                     >
                        <div className='product-card'>
                           <img src={product.imageUrl[0]} alt={product.title} className='product-card__image' />
                           <p className='product-card__title'>{product.title}</p>
                           <div className="product-card__available">
                              <div className={`product-card__avail ${product.isAvailable ? 'available' : 'unavailable'}`}></div>
                              <div className='product-card__text'>{product.isAvailable ? 'в наявності' : 'відсутній'}</div>
                           </div>
                           <div className="product-card__buy">
                              <div className="product-card__price">{product.price}₴</div>
                              <button
                                 type="button"
                                 className="btn-productcart"
                                 onClick={(e) => {
                                    e.preventDefault();
                                    handleAddToCart(product);
                                 }}
                              >
                                 <img src={Cart} alt="Cart" />
                              </button>
                           </div>
                        </div>
                     </Link>
                  ))
               ) : (
                  <p>No products available</p>
               )}
            </div>
            <ToastContainer />
         </div>
         <Pagination currentPage={currentPage} totalPages={Math.ceil(filteredProducts.length / pageSize)} onPageChange={handlePageChange} />
      </div>
   );
};

export default BrandsPage;