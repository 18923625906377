import React from 'react';

const Confidential = () => {
   return (
      <div className='confidential'>
         <div className="confidential__container container">
            <h2>Політика конфіденційності</h2>

            <p>
               Адміністрація – адміністрація веб-сайту інтернет-магазину "Агроснабмаркет" www.agrosnabmarket@gmail.com.
            </p>

            <p>
               Сайт – веб-сайт інтернет-магазину www.agrosnabmarket@gmail.com, включно з усіма веб-сторінками; власником
               інтернет-магазину є "Агроснабмаркет".
            </p>

            <p>Угода – дана Угода користувача.</p>

            <p>
               Продавець – юридична особа або фізична особа-підприємець, яка розміщує на Сайті інформацію про
               товари, які реалізує, та послуги, які пропонує. Продавцем є Адміністрація.
               Найменування Продавця вказується в документах на передачу Товару Одержувачеві (в акті
               приймання-передавання або інших документах, які підтверджують факт передачі Товару Одержувачеві).
            </p>

            <p>Товар – підшипники, ремені, сальники, фільтра, хомути, кільця, манжети, ланцюги, рукави високого тиску, масло-бензостійкі рукави, інформація про які розміщена на Сайті.</p>

            <p>
               Користувач (Споживач) – Споживач, який переглядає інформацію на Сайті і / або замовляє та/або отримує
               Товари з використанням інструментів Сайту. Одержувач, Платник є Користувачами.
            </p>

            <p>
               Замовлення – звернення Користувача через Сайт до Продавця з проханням продати Товар, а також сукупність
               Товарів, які замовив Користувач.
            </p>

            <p>Платник – особа, яка оплачує Замовлення.</p>

            <p>
               Одержувач – особа, яка зазначена Платником як та, що уповноважена отримати Товар відповідно до
               Замовлення. Якщо інше не зазначено в формі Замовлення, Одержувачем є Платник.
            </p>

            <p>
               Пропозиція - інформація, яка розміщена на Сайті про конкретний Товар, який може бути придбаний Споживачем.
               Пропозиція включає в себе: інформацію щодо самого товару, інформацію про його ціну, способи оплати та
               доставки, а також інші умови придбання Товару Споживачем. Умови Пропозиції визначаються Продавцем.
               Пропозиція не є офертою, а лише інформацією про можливі умови придбання Товару.
            </p>

            <h3>1. Збір інформації</h3>
            <p>
               1.1 Залишаючись на Сайті Користувач приймає умови та порядок направлення оферти Адміністрації (оформлення замовлення) та акцептування Адміністрацією оферти, а також надає Адміністрації згоду на обробку наданих ним персональних даних та інших відомостей, які вважає за необхідне надати, або які є обов’язковими для надання за Законодавством, а також приймає умови відповідальності за недобросовісне використання Сайту.
            </p>
            <p>
               1.2 Ця Угода є публічною офертою. Отримуючи доступ до матеріалів Сайту, Користувач тим самим приєднується до цієї Угоди.
            </p>
            <p>
               1.3 Користувач розуміє, що його оферта перенаправляється Адміністрацією Продавцю, якого обрала Адміністрація. Це положення жодним чином не означає, що Адміністрація діє як агент Продавця і/або є відповідальною за виконання Замовлення Продавцем.
            </p>
            <p>
               1.4 Оферта вважається такою, що прийнята Продавцем, якщо останній здійснив дії, які свідчать про прийняття оферти Користувача, а саме: фактично відвантажив товар, приступив до надання послуг або виконання робіт відповідно до умов, передбачених офертою Користувача.
            </p>
            <p>
               1.5 Адміністрація повідомляє, що опис товарів, розміщений на Сайті, в тому числі ціни, позначені на Cайті, можуть змінюватись. Остаточною є ціна, вказана в акцепті Адміністрації, направленому у відповідь на оферту Користувача (підтвердження замовлення). Будь-яка інформація про товар, що міститься на Сайті, розміщена, у тому числі, на підставі технічної та іншої інформації, наданої виробником товару. У випадку виявлення розбіжностей в інформації, зазначеній на Сайті та в документах виробника, Користувач має керуватися даними документів виробника товарів. Товариство має право в будь-який час вносити зміни в інформацію, розміщену на Сайті, без попереднього повідомлення Користувачів.
            </p>
            <p>
               1.6 Адміністрація повідомляє Користувача, що обрання та користування товаром, є відповідальною дією. Користувач має впевнитись, що всі вимоги щодо експлуатації товару ним дотримано.
            </p>

            <h3>2. ПОРЯДОК УКЛАДЕННЯ ДОГОВОРІВ З ВИКОРИСТАННЯМ САЙТУ</h3>
            <p><b>2.1.</b> Інформація про товари/роботи/послуги, розміщена на Сайті є запрошенням робити пропозиції укласти договір щодо придбання товарів/робіт/послуг та не є офертою Адміністрації, оскільки асортимент та характеристики товарів/робіт/послуг можуть змінюватись, товар може бути знятий з виробництва, товар може бути реалізовано без залишків, на товар може бути проведена переоцінка як в бік зменшення, так й в бік збільшення, щодо товарів/робіт/послуг можуть бути застосовані будь-які акції, розпродажі тощо.</p>
            <p><b>2.2.</b> Користувач після ознайомлення з розміщеною на Сайті інформацією має право зробити Адміністрації пропозицію укласти договір (оферту) шляхом заповнення замовлення на Сайті або через Колл-центр Адміністрації. Оформлення замовлення вважається офертою Користувача Адміністрації на придбання товару/робіт/послуг на умовах, зазначених у відповідному замовленні.</p>
            <p><b>2.3.</b> Направлення Користувачем оферти, як це описано в п.2.2. цих Правил, є підтвердженням того, що він ознайомився з описом обраних товарів/робіт/послуг, їх технічними характеристиками та умовами надання; це є також підтвердженням того, що обраний товар/робота/послуга повністю відповідають меті їх придбання; Користувач самостійно несе відповідальність за свідомий вибір товарів згідно з цими Правилами.</p>
            <p><b>2.4.</b> Прийняттям оферти Користувача (акцептом) є вчинення Адміністрацією дій відповідно до вказаних у пропозиції умов договору (відвантаження товарів, надання послуг, виконання робіт), що засвідчують бажання Адміністрації укласти відповідний договір.</p>
            <p><b>2.5.</b> Адміністрація має право запропонувати Користувачу придбати товари/роботи/послуги на інших умовах, ніж вказані в оферті Користувача. В такому випадку така пропозиція Адміністрації вважається зустрічною офертою, яка може бути прийнята Користувачем. Прийняттям зустрічної оферти Адміністрації вважається отримання Користувачем товару на умовах, передбачених зустрічною офертою. Товариство має право відкликати зустрічну оферту до моменту отримання товару Користувачем.</p>
            <p><b>2.6.</b> Користувач має право на підтвердження свого наміру вважати себе зобов’язаним за направленою офертою у разі її прийняття Адміністрацією здійснити перерахування коштів в розмірі ціни товарів, що вказані в оферті. У випадку акцепту Адміністрацією оферти, як це передбачено умовами цих Правил, перераховані грошові кошти вважаються оплатою Користувача за товар. У випадку незгоди Адміністрації з прийняттям оферти перераховані Користувачем грошові кошти підлягають поверненню Адміністрацією Користувачу.</p>
            <p> <b>2.7.</b> Умови укладених договорів придбання товарів регулюються нормами Цивільного кодексу України, Закону України «Про захист прав споживачів».</p>
            <p> <b>2.8.</b> Умови обміну та повернення товарів є загальноприйнятими та регулюються нормами діючого законодавства про захист прав споживачів.<a href="https://zakon.rada.gov.ua/laws/show/172-94-%D0%BF#Text" target="_blank">(https://zakon.rada.gov.ua/laws/show/172-94-%D0%BF#Text)</a></p>
            <h3>3. Захист інформації</h3>
            <p>
               Адміністрація використовує (cookies) куки, щоб забезпечити надання максимально повної інформації, щоб Користувачі отримали найкращий досвід на Сайті. Якщо Користувач продовжує користуватись Сайтом, це означає його згоду на використання куків (cookies) та отримання Товариством всіх файлів cookie, та можливість надсилати Користувачу найсвіжішу інформацію про найактуальніші пропозиції та акції. Користувач в будь-який час може відмовитись від розсилки або заборонити використовувати куки шляхом персональних налаштувань свого пристрою, за допомогою якого він здійснює користування Сайтом.
            </p>

            <h3>4. ОБСТАВИНИ НЕПЕРЕБОРНОЇ СИЛИ (ФОРС-МАЖОР)</h3>
            <p><b>4.1</b> Адміністрація та Користувач не несуть відповідальності за невиконання будь-якого з положень, якщо доведуть, що таке невиконання було викликано форс-мажорними обставинами, тобто подіями або обставинами, що дійсно знаходяться поза їх контролем, що носять непередбачений і невідворотний характер.</p>

            <p><b>4.2</b> До форс-мажорних обставин належать, зокрема, але не виключно, природні катаклізми, страйки, пожежі, повені, вибухи, обмерзання, війни (як оголошені, так і неоголошені), оголошення воєнного стану або надзвичайних ситуацій, проведення антитерористичних операцій (як оголошені, так і неоголошені), епідемії, епізоотії, введення повного чи обмеженого карантину, режиму надзвичайної ситуації, надзвичайного стану, введення локдаунів, заборон чи обмеження здійснення певних видів господарської діяльності, заколоти, загибель товару, затримки перевізників, викликані аваріями або несприятливими погодними умовами, небезпеки і випадковості на морі, ембарго, катастрофи, обмеження, що накладаються державними органами (включаючи розподілу, пріоритети, офіційні вимоги, квоти і ціновий контроль), якщо ці обставини безпосередньо вплинули на виконання прийнятих зобов’язань.</p>

            <p><b>4.3</b> Особа, для якої стало неможливим виконання зобов'язань через настання форс-мажорних обставин, повинна негайно інформувати іншу сторону.</p>


            <h3>5. Зміни в політиці конфіденційності</h3>
            <p>
               Адміністрація Сайту має право у будь-який час в односторонньому порядку змінювати умови цієї Угоди. Такі зміни вступають в силу з моменту розміщення нової версії Угоди на сайті
            </p>
            <h3>6. Інші умови</h3>

            <p><b>6.1.</b> Користувач має право призначити Одержувачем придбаного Товару третю особу. В цьому випадку Одержувач зобов'язаний вказати в формі Замовлення дані, необхідні для ідентифікації Одержувача і доставки йому Товару. У цьому випадку на відносини сторін поширюються положення ст. 636 Цивільного кодексу України.</p>
            <p><b>6.2.</b> Для видачі Товару Одержувачеві останній зобов'язаний надати при отриманні Товару документ, який засвідчує особу (паспорт).</p>
            <p><b>6.3.</b> При отриманні Товару Одержувач зобов'язаний поставити у документах свій підпис, який підтверджує те, що Товар він отримав.</p>
            <p><b>6.4.</b> Усі можливі суперечки, що випливають з цієї Угоди або пов'язані з нею, підлягають вирішенню відповідно до чинного законодавства України.</p>
            <p><b>6.5.</b> Ніщо в Угоді не може розумітися як встановлення між Користувачем і Адміністрацією Сайту агентських відносин, відносин товариства, відносин по спільній діяльності, відносин особистого найму або інших відносин, прямо не передбачених Угодою.</p>
            <p><b>6.6.</b> Визнання судом будь-якого положення Угоди недійсним або таким, що не підлягає примусовому виконанню, не свідчить про недійсність інших положень Угоди.</p>
            <p><b>6.7.</b> Бездіяльність з боку Адміністрації Сайту в разі порушення будь-ким з користувачів положень Угоди не позбавляє Адміністрацію Сайту права на пізніші відповідні дії на захист своїх інтересів і захист авторських прав матеріалів Сайту, які охороняються відповідно до чинного законодавства. Користувач підтверджує, що ознайомлений з усіма пунктами цієї Угоди і безумовно приймає їх.</p>
            <p><b>6.8.</b> Адміністрація сайту залишає за собою право вводити обмеження та внутрішні правила стосовно переміщення товару між магазинами.</p>








            <h3>7. Зв'язок з нами</h3>
            <p>
               Якщо у вас є питання або зауваження щодо цієї політики конфіденційності, будь ласка,{' '}
               <a href="mailto:agrosnabmarket@gmail.com">зв'яжіться з нами</a>.
            </p>
         </div>
      </div>
   );
};

export default Confidential;

