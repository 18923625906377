import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../utils/constants';

// Створення асинхронної функції для отримання інформації про замовлення
export const createOrder = createAsyncThunk('order/createOrder', async (orderData, thunkAPI) => {
   try {
      const response = await axios.post(`${BASE_URL}/cart/order`, orderData);
      return response.data;
   } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response.data);
   }
});

export const getAllOrdersForUser = createAsyncThunk('order/getAllOrdersForUser', async (_, thunkAPI) => {
   try {
      const response = await axios.get(`${BASE_URL}/cart/order`);
      return response.data;
   } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response.data);
   }
});

const orderSlice = createSlice({
   name: 'order',
   initialState: {
      orderDetails: null,
      isLoading: false,
      userOrders: [],
      error: null,
   },
   reducers: {
      clearOrderDetails: (state) => {
         state.orderDetails = null;
      },

   },
   extraReducers: (builder) => {
      builder.addCase(createOrder.pending, (state) => {
         state.isLoading = true;
         state.error = null;
      });
      builder.addCase(createOrder.fulfilled, (state, { payload }) => {
         state.orderDetails = payload;
         state.isLoading = false;
         state.error = null;
      });
      builder.addCase(createOrder.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload ? payload.message : 'Помилка при створенні замовлення';
      });
      builder.addCase(getAllOrdersForUser.pending, (state) => {
         state.isLoading = true;
         state.error = null;
      });
      builder.addCase(getAllOrdersForUser.fulfilled, (state, { payload }) => {
         state.userOrders = payload;
         state.isLoading = false;
         state.error = null;
      });
      builder.addCase(getAllOrdersForUser.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload ? payload.message : 'Помилка при отриманні замовлень користувача';
      });
   },
});

export const { clearOrderDetails } = orderSlice.actions;
export const selectUserOrders = (state) => state.order.userOrders;
// Створення селектора для отримання деталей замовлення
export const selectOrderDetails = (state) => state.order.orderDetails;

// Створення селектора для отримання статусу завантаження
export const selectOrderLoading = (state) => state.order.isLoading;

// Створення селектора для отримання помилки
export const selectOrderError = (state) => state.order.error;

export default orderSlice.reducer;
