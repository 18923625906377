import React, { useState } from 'react';

const CallbackForm = ({ onClose }) => {
   // Стани для полів форми
   const [name, setName] = useState('');
   const [phoneNumber, setPhoneNumber] = useState('');

   // Функція для обробки відправки форми
   const handleSubmit = (e) => {
      e.preventDefault();

      // Тут ви можете обробляти дані форми (ім'я та номер телефону)
      console.log('Ім\'я:', name);
      console.log('Номер телефону:', phoneNumber);

      // Закрити модальне вікно після обробки форми
      onClose();
   };

   return (
      <div>
         <h2>Зворотний виклик</h2>
         <form onSubmit={handleSubmit}>
            <label>
               Ім'я:
               <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
               />
            </label>
            <label>
               Номер телефону:
               <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
               />
            </label>
            <button type="submit">Відправити</button>
         </form>
      </div>
   );
};

export default CallbackForm;