import axios from 'axios';

const instance = axios.create({
   baseURL: 'https://server.agrosnabmarket.com.ua',
   // Інші налаштування, якщо необхідно
});

// Додаємо перехоплювач для встановлення токену у заголовки
instance.interceptors.request.use(
   (config) => {
      config.headers.Authorization = window.localStorage.getItem('token');;
      return config;
   },
   (error) => {
      return Promise.reject(error);
   }
);

export default instance;