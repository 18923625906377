import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../features/categories/productsSlice';
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToCart } from '../../features/categories/productSlice';
import Cart from '../../images/headers/cart.svg';
import Sidebar from '../Sidebar/Sidebar';
import Pagination from '../pagination/Pagination';
import { ROUTES } from '../../utils/routes';

// Move the function declaration here
const getRandomProducts = (products, count) => {
   const shuffledProducts = products.slice().sort(() => 0.5 - Math.random());
   return shuffledProducts.slice(0, count);
};

const Actions = ({ random }) => {
   const dispatch = useDispatch();
   const location = useLocation();
   const isHomePage = location.pathname === '/';

   const [currentPage, setCurrentPage] = useState(1);
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []); // Додаємо слухач подій при завантаженні компонента і видаляємо його при видаленні

   const isSmallScreen = windowWidth <= 768;
   const pageSize = isHomePage ? (isSmallScreen ? 3 : 5) : 12;

   useEffect(() => {
      dispatch(getProducts({ categoryId: '655ddaafbd342d93bef0dd16', subcategoryId: '655f50f5817af6082789b044' }));
   }, [dispatch]);

   const { list: actionProducts, isLoading, isError } = useSelector((state) => state.products);
   const filteredProducts = actionProducts.filter((product) => product.isAction);

   // Змінено розташування цієї логіки
   const displayedProducts = isHomePage ? getRandomProducts(filteredProducts, pageSize) : filteredProducts;
   const startIndex = (currentPage - 1) * pageSize;
   const endIndex = startIndex + pageSize;
   const displayedOnPage = displayedProducts.slice(startIndex, endIndex);

   const handleAddToCart = (product) => {
      dispatch(addToCart({
         productId: product._id,
         title: product.title,
         brands: product.brands,
         price: product.price,
         quantity: 1,
         imageUrl: product.imageUrl,
         article: product.article,
      }));

      toast.success(`Товар "${product.title}" додано до кошика!`);
   };

   const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
   };

   if (isLoading) {
      return <div>Loading...</div>;
   }

   if (isError) {
      return <div>Error fetching products</div>;
   }
   return (
      <div className='actions'>
         <div className="actions__container container">
            <div className="actions__header">
               <div className="actions__title">АКЦІЇ</div>
               <Link to={ROUTES.ACTIONS} className="actions__link">Всі акції</Link>
            </div>
            <div className='products-container container'>
               {isHomePage ? null : <Sidebar />} {/* Перевірка, чи на сторінці Home, щоб визначити, чи показувати Sidebar */}
               <div className='products-content'>
                  {displayedProducts.map((product) => (
                     <Link
                        to={`/products/${product._id}`}
                        key={product._id}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                     >
                        <div className='product-card'>
                           <img src={product.imageUrl[0]} alt={product.title} className='product-card__image' />
                           <p className='product-card__title'>{product.title}</p>
                           <div className="product-card__available">
                              <div className={`product-card__avail ${product.isAvailable ? 'available' : 'unavailable'}`}></div>
                              <div className='product-card__text'>{product.isAvailable ? 'в наявності' : 'відсутній'}</div>
                           </div>
                           <div className="product-card__buy">
                              <div className="product-card__price">{product.price}₴</div>
                              <button
                                 type="button"
                                 className="btn-productcart"
                                 onClick={(e) => {
                                    e.preventDefault();
                                    handleAddToCart(product);
                                 }}
                              >
                                 <img src={Cart} alt="Cart" />
                              </button>
                           </div>
                        </div>
                     </Link>
                  ))}
               </div>
            </div>
            <ToastContainer />
         </div>
         {isHomePage ? null : <Pagination currentPage={currentPage} totalPages={Math.ceil(actionProducts.length / pageSize)} onPageChange={handlePageChange} />}
      </div>
   );
};

export default Actions;