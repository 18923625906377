import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPosts } from '../../../features/categories/postsSlice';
import Calendar from '../../../images/post/calendar.svg'
import Eye from '../../../images/post/eye.svg'
import { Link } from 'react-router-dom';
const Posts = () => {
   const dispatch = useDispatch();
   const posts = useSelector(state => state.posts.list);
   const isLoading = useSelector(state => state.posts.isLoading);
   const isError = useSelector(state => state.posts.isError);

   useEffect(() => {
      // Запускайте отримання постів при завантаженні компонента
      dispatch(getPosts());
   }, [dispatch]);

   if (isLoading) {
      return <div>Loading...</div>;
   }

   if (isError) {
      return <div>Error loading posts</div>;
   }

   return (
      <div className='posts'>
         <div className="posts__container container">
            <div className='pages-title'><h1>Популярні питання</h1></div>
            <div className="posts__btns">
               {/* Додайте обробники подій для кнопок, які виберуть відповідні типи постів */}
               <button type="submit" className="posts__btn">Все</button>
               <button type="submit" className="posts__btn">Статті</button>
               <button type="submit" className="posts__btn">Відео</button>
               <button type="submit" className="posts__btn">Поради</button>
               <button type="submit" className="posts__btn">Порівняння</button>
               <button type="submit" className="posts__btn">Топ</button>
            </div>
            {/* Використовуйте PostList для відображення списку постів */}
            <div className="post-list">
               {posts.map(post => (
                  <Link to={`/posts/${post._id}`} key={post._id} className="post-item">
                     <img src={post.imageUrl} alt={post.title} className='post-item__image' />
                     <div className="post-item__content post-content">
                        <h2 className='post-content__title'>{post.title}</h2>
                        <p className='post-content__text'>{post.text}</p>
                        <div className="post-item__footer">
                           <div className="post-calendar">
                              <img src={Calendar} alt="Calendar" />
                              <p>{new Date(post.createdAt).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '.')}</p>
                           </div>
                           <div className="post-views">
                              <img src={Eye} alt="Eye" />
                              <p>{post.viewsCount}</p>
                           </div>
                        </div>
                        {/* Додайте інші поля, які вам потрібно відображати */}
                     </div>
                  </Link>
               ))}
            </div>
         </div>
      </div>
   );
}

export default Posts;
