import React from 'react'
import { Link } from 'react-router-dom';

const NotFound = () => {
   return (
      <div className="notfound-container container">
         <h3 className="notfound-title">404 - Сторінку не знайдено</h3>
         <p className="notfound-text">Вибачте, але сторінка, яку ви шукаєте, перенесено.</p>
         <p className="notfound-link">Будь ласка, скористайтеся пошуком або перейдіть на <Link to="/">головну сторінку</Link>.</p>
         <p className="notfound-text">
            Якщо не знайшли товар, який вас цікавить, зателефонуйте нам:
            {' '}
            <a className='notfound-phone ' href="tel:+380506726443">+380506726443</a>
            {' '}
            або
            {' '}
            <a className='notfound-phone' href="tel:+380970699023">+380970699023</a>.
         </p>
      </div>
   )
}

export default NotFound
