import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, loginUser, selectCurrentUser, selectError, setError, getUserByToken, setCurrentUser } from '../../features/user/UserSlice';
import { createOrder, selectOrderDetails, selectOrderLoading, selectOrderError, clearOrderDetails } from '../../features/api/orderSlice';
import Close from '../../images/other/close.svg'
const Login = ({ onClose, onLoginSuccess }) => {
   const dispatch = useDispatch();
   const currentUser = useSelector(selectCurrentUser);
   const error = useSelector(selectError);
   const [isLoginMode, setIsLoginMode] = useState(true);
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [login, setLogin] = useState('');
   const [fullName, setFullName] = useState('');
   const [loginError, setLoginError] = useState(null);





   const handleOrder = () => {
      if (currentUser) {
         // Якщо користувач увійшов у систему, включіть його ідентифікатор в замовлення
         const orderDataWithUser = {
            userId: currentUser.userId,
            // ... інші дані замовлення ...
         };
         dispatch(createOrder(orderDataWithUser));
      } else {
         // Якщо користувач не увійшов у систему, використовуйте існуючий код замовлення
         const orderDataWithoutUser = {
            // ... інші дані замовлення ...
         };
         dispatch(createOrder(orderDataWithoutUser));
      }
   };

   const handleClose = () => {
      onClose();
   };
   const handleLogin = async () => {
      try {
         await dispatch(loginUser({ email, password }));
         // Закриваємо модальне вікно після входу
         onClose();
         // Викликаємо колбек після успішного входу
         onLoginSuccess && onLoginSuccess();
      } catch (error) {
         // Обробка помилок під час входу
         if (error.response && error.response.status === 401) {
            // HTTP 401 - Невірний логін або пароль
            dispatch(setError("")); // Спочатку очищаємо змінну загальної помилки
            setLoginError("Невірний логін або пароль");
         } else {
            console.error("Помилка входу:", error); // Додайте вивід повної помилки
            return; // Зупиняємо виконання коду, якщо сталася інша помилка
         }
      }
   };

   const handleRegister = async () => {
      dispatch(registerUser({ email, fullName, password }));
      onClose(); // Закриваємо модальне вікно після входу
   };

   return (
      <div className='login'>
         <div className="login-container container">
            <button type="submit" class="btn-close" onClick={handleClose}>
               <img src={Close} alt="close" />
            </button>
            <div className="login-content">
               <div className="login-content__btns login-btns">
                  <button type="submit" class={`login-btn ${isLoginMode ? 'active' : ''}`} onClick={() => setIsLoginMode(true)}>Вхід</button>
                  <p className='login-btns__space'>|</p>
                  <button type="submit" class={`login-btn ${!isLoginMode ? 'active' : ''}`} onClick={() => setIsLoginMode(false)}>Реєстрація</button>
               </div>
               {isLoginMode ? (
                  <div className='exit-content'>
                     <label>
                        <p>Логін:</p>
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                     </label>
                     <label>
                        <p>Пароль:</p>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                     </label>
                     {loginError && <p style={{ color: 'red' }}>{loginError}</p>}
                     <button type="submit" className="btn-callback" onClick={handleLogin}>
                        Увійти
                     </button>
                  </div>

               ) : (
                  <div className='register-content'>
                     <label>
                        <p>Введіть Ваше імя:</p>
                        <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                     </label>
                     <label>
                        <p>Email:</p>
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                     </label>

                     <label>
                        <p>Пароль:</p>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                     </label>
                     <button type="submit" className="btn-callback" onClick={handleRegister}>Зареєструватися</button>
                  </div>
               )}
            </div>

         </div>
      </div>
   )
}

export default Login



