import React from 'react'
import Warning from '../../../images/other/warning.svg'
import NovaPoshta from '../../../images/delivery/np.png'
import UkrPoshta from '../../../images/delivery/up.png'
import SAT from '../../../images/delivery/sat.png'
import NPico from '../../../images/other/np-ico.svg'
import MASTERCARD from '../../../images/other/mastercard.svg'
import VISA from '../../../images/other/visa.svg'
import APay from '../../../images/other/apay.svg'
import GPay from '../../../images/other/google_pay.svg'
import Cash from '../../../images/delivery/Cash.svg'
import Free from '../../../images/delivery/Free.svg'

const Delivery = ({ isActive }) => {
   return (
      <div className='delivery-container container'>
         {isActive ? (
            <>
               <div className="delivery-text">
                  Ви можете отримати замовлення обравши один зі способів доставки:
               </div>
               <div className="delivery-courier">
                  <div className="delivery-courier__card courier">
                     <div className="courier-image">
                        <img src={NovaPoshta} alt="" />
                     </div>
                     <div className="courier__text">Нова Пошта</div>
                  </div>
                  <div className="delivery-courier__card courier">
                     <div className="courier-image">
                        <img src={UkrPoshta} alt="" />
                     </div>
                     <div className="courier__text">Укр Пошта</div>
                  </div>
                  <div className="delivery-courier__card courier">
                     <div className="courier-image">
                        <img src={SAT} alt="" />
                     </div>
                     <div className="courier__text">SAT</div>
                  </div>
               </div>

               <div className="delivery-title">Оплата</div>

               <div className="delivery-text">
                  Доступні способи оплати на нашому сайті
               </div>

               <div className="delivery-payment">
                  <div className="delivery-payment__item payment">
                     <div className="payment__title">Онлайн на сайті</div>
                     <div className="payment__text">Оплата відбувається через авторизаційний сервер процесингового центру банку у відповідності до вимог платіжних систем Visa Int. та MasterCard Europe Sprl.</div>
                     <div className="payment__images">
                        <img src={GPay} alt="GPay" />
                        <img src={APay} alt="APay" />
                        <img src={VISA} alt="VISA" />
                        <img src={MASTERCARD} alt="MASTERCARD" />
                     </div>
                  </div>
                  <div className="delivery-payment__item payment">
                     <div className="payment__title">Наложений платіж</div>
                     <div className="payment__text">Оплачуйте Ваше замовлення на пошті, перевіривши наявність всього замовленого</div>
                     <div className="payment__images">
                        <img src={NPico} alt="NPico" />
                     </div>
                  </div>
                  <div className="delivery-payment__item payment">
                     <div className="payment__title">Через інтернет-банкінг за рахунком</div>
                     <div className="payment__text">Оплачуйте замовлення на нашу банківську карту, отримавши реквізити від нашого менеджера</div>
                     <div className="payment__images">
                     </div>
                  </div>
                  <div className="delivery-payment__item payment">
                     <div className="payment__title">За рахунком (безготівковий рахунок)</div>
                     <div className="payment__text">Для ІП або Юридичних осіб.
                        Оплачуйте товар зі свого юридичного рахунку</div>
                     <div className="payment__images">
                     </div>
                  </div>
               </div></>
         ) : (<>
            <div className="pages-title">Доставка та оплата</div>
            <div className="delivery-title">Доставка</div>
            <div className="delivery-subtitle__info">(*при наявності товару на складі)</div>
            <div className="delivery-subtitle"><h4>Відправляємо по всій Україні в день замовлення</h4> </div>
            <div className="delivery-warning">
               <img src={Warning} alt="Warning" />
               <div className="delivery-warning__text">
                  Відправлення здійснюється на 1-й день з моменту оформлення замовлення, за умови оформлення до 15:00.
                  При оформленні замовлення після 15:00 відправлення буде здійснено на наступний робочий день.
               </div>
            </div>
            <div className="delivery-text">
               Ви можете отримати замовлення обравши один зі способів доставки:
            </div>
            <div className="delivery-courier__mobile mobile-courier">
               <img src={Cash} alt="Cash" />
               <div className="mobile-courier__title">Доставка по всій Україні</div>
               <div className="mobile-courier__text">через інтернет-банкінг за рахунком
                  (безготівковий розрахунок)</div>
               <div className="mobile-courier__images">
                  <img src={GPay} alt="GPay" />
                  <img src={APay} alt="APay" />
                  <img src={VISA} alt="VISA" />
                  <img src={MASTERCARD} alt="MASTERCARD" />
               </div>
            </div>
            <div className="delivery-courier__mobile mobile-courier">
               <img src={Free} alt="Free" />
               <div className="mobile-courier__title">Безкоштовна доставка</div>
               <div className="mobile-courier__text">при замовлені від 5000 гривень</div>
            </div>
            <div className="delivery-courier">
               <div className="delivery-courier__card courier">
                  <div className="courier-image__empty">
                     <img src={NovaPoshta} alt="" />
                  </div>
                  <div className="courier__text">Спосіб доставки</div>
                  <div className="courier-pay__text">Спосіб оплати</div>
                  <div className="courier__text">Орієнтовна вартість доставки/кг </div>
                  <div className="courier__text">Швидкість доставки</div>
               </div>
               <div className="delivery-courier__card courier">
                  <div className="courier-image">
                     <img src={NovaPoshta} alt="" />
                  </div>
                  <div className="courier__text">Нова Пошта</div>
                  <div className="courier-pay__text">Безготівковий розрахунок
                     Оплатою онлайн на сайті
                     Наложений платіж
                  </div>
                  <div className="courier__text">75-100грн/кг </div>
                  <div className="courier__text">1-2 дні</div>
               </div>
               <div className="delivery-courier__card courier">
                  <div className="courier-image">
                     <img src={UkrPoshta} alt="" />
                  </div>
                  <div className="courier__text">Укр Пошта</div>
                  <div className="courier-pay__text">Безготівковий розрахунок(Предоплата)</div>
                  <div className="courier__text">35-50/кг </div>
                  <div className="courier__text">2-5 дні</div>
               </div>
               <div className="delivery-courier__card courier">
                  <div className="courier-image">
                     <img src={SAT} alt="" />
                  </div>
                  <div className="courier__text">SAT</div>
                  <div className="courier-pay__text">Безготівковий розрахунок(Предоплата)</div>
                  <div className="courier__text">50-90/кг </div>
                  <div className="courier__text">1-3 дні</div>
               </div>

               <div className="delivery-courier__mobile mobile-courier">
                  <div className="mobile-courier__title">Оплата готівкою</div>
                  <div className="mobile-courier__text">готівкою
                     в магазині, чи при отриманні посилки на пошті</div>
                  <div className="mobile-courier__images">
                     <img src={GPay} alt="GPay" />
                     <img src={APay} alt="APay" />
                     <img src={VISA} alt="VISA" />
                     <img src={MASTERCARD} alt="MASTERCARD" />
                  </div>
               </div>
            </div>
            <div className="delivery-title">Оплата</div>
            <div className="delivery-text">
               Доступні способи оплати на нашому сайті
            </div>

            <div className="delivery-payment">
               <div className="delivery-payment__item payment">
                  <div className="payment__title">Онлайн на сайті</div>
                  <div className="payment__text">Оплата відбувається через авторизаційний сервер процесингового центру банку у відповідності до вимог платіжних систем Visa Int. та MasterCard Europe Sprl.</div>
                  <div className="payment__images">
                     <img src={GPay} alt="GPay" />
                     <img src={APay} alt="APay" />
                     <img src={VISA} alt="VISA" />
                     <img src={MASTERCARD} alt="MASTERCARD" />
                  </div>
               </div>
               <div className="delivery-payment__item payment">
                  <div className="payment__title">Наложений платіж</div>
                  <div className="payment__text">Оплачуйте Ваше замовлення на пошті, перевіривши наявність всього замовленого</div>
                  <div className="payment__images">
                     <img src={NPico} alt="NPico" />
                  </div>
               </div>
               <div className="delivery-payment__item payment">
                  <div className="payment__title">Через інтернет-банкінг за рахунком</div>
                  <div className="payment__text">Оплачуйте замовлення на нашу банківську карту, отримавши реквізити від нашого менеджера</div>
                  <div className="payment__images">
                  </div>
               </div>
               <div className="delivery-payment__item payment">
                  <div className="payment__title">За рахунком (безготівковий рахунок)</div>
                  <div className="payment__text">Для ІП або Юридичних осіб.
                     Оплачуйте товар зі свого юридичного рахунку</div>
                  <div className="payment__images">
                  </div>
               </div>
            </div>
         </>)}

      </div>
   )
}

export default Delivery
