import { useEffect } from 'react';

const Redirect = () => {
   useEffect(() => {
    const currentURL = window.location.href;

    const redirects = [
        { from: '/p1169581744-uplotnitelnye-koltsa-17h23h35', to: 'https://agrosnabmarket.com.ua/products/65a7b188863b701399b6aa10' },
        { from: '/p1144525295-podshipnik-6900-2nse', to: 'https://agrosnabmarket.com.ua/products/65db6e83a02ccf22f1e6bc2e' },
        { from: '/p1174650219-manzheta-gidravlicheskaya-90h70h10', to: 'https://agrosnabmarket.com.ua/products/65db7400b8543bf55e80cd37' },
        { from: '/p1148584846-remen-privodnoj-2450', to: 'https://agrosnabmarket.com.ua/products/65b295f5c117c47a12c0ec23' },
        { from: '/p1146743300-remen-privodnoj-1650', to: 'https://agrosnabmarket.com.ua/products/65db79d22be3e262a336c807' },
        { from: '/p1146744497-remen-privodnoj-1850', to: 'https://agrosnabmarket.com.ua/products/65db7b802be3e262a336c81a' },
        { from: '/p1146743691-remen-privodnoj-1550', to: 'https://agrosnabmarket.com.ua/products/65db7e172be3e262a336c83c' },
        { from: '/p1146743880-remen-privodnoj-1500', to: 'https://agrosnabmarket.com.ua/products/65db81b95334f1d5ee04f896' },
        { from: '/p1152524545-salnik-75h95h10h2', to: 'https://agrosnabmarket.com.ua/products/65970c61cc659c05588e9f93' },
        { from: '/p1176742221-salnik-20h40h10', to: 'https://agrosnabmarket.com.ua/products/65970e81cc659c05588e9fa3' },
        { from: '/p1351259536-salnik-19h30h7', to: 'https://agrosnabmarket.com.ua/products/6597a49bdf0f55c52a290e93' },
         { from: '/p1252621134-salnik-20h30h7', to: 'https://agrosnabmarket.com.ua/products/6597a581df0f55c52a290ea0' },
         { from: '/p1181261685-salnik-60h85h10', to: 'https://agrosnabmarket.com.ua/products/dba6ea4d4c4d1d74f7b5' },
         { from: '/p1236664033-remen-privodnoj-3500lw', to: 'https://agrosnabmarket.com.ua/products/65abdad80ec180bef22d5576' },
         { from: '/p1181260853-salnik-85h105h10', to: 'https://agrosnabmarket.com.ua/products/65983f32713cd957d011dddb' },
         { from: '/p1181260853-salnik-85h105h10', to: 'https://agrosnabmarket.com.ua/products/65983f32713cd957d011dddb' },
         { from: '/p1181567583-salnik-115h140h12', to: 'https://agrosnabmarket.com.ua/products/65995bfd5f561ecd9cb32689' },
         { from: '/p1177617629-salnik-35h62h12', to: 'https://agrosnabmarket.com.ua/products/65995e0a5f561ecd9cb3269a' },
         { from: '/p1181256362-salnik-85h110h10', to: 'https://agrosnabmarket.com.ua/products/659ab63365f3fe080e7759d3' },
         { from: '/p1178321274-salnik-40h90h8', to: 'https://agrosnabmarket.com.ua/products/659ab6fc65f3fe080e7759d8' },
         { from: '/p1177492169-salnik-35h50h8', to: 'https://agrosnabmarket.com.ua/products/659ab77e65f3fe080e7759dd' },
        { from: '/p1369104204-salnik-32h42h7', to: 'https://agrosnabmarket.com.ua/products/65a2b737bebff8e92413cdb2' },
        { from: '/p1145238621-salnik-17h35h10', to: 'https://agrosnabmarket.com.ua/products/65dc3069f508d3de57bbb48b' },
        { from: '/p1775494047-remin-1870', to: 'https://agrosnabmarket.com.ua/products/659abc0965f3fe080e7759f3' },
        { from: '/p1471885914-remen-privodnoj-2200', to: 'https://agrosnabmarket.com.ua/products/659cec824cfd6e0db770ff17' },
        { from: '/p1471885914-remen-privodnoj-2200', to: 'https://agrosnabmarket.com.ua/products/659ceefb4cfd6e0db770ff46' },
        { from: '/p1150553571-remen-privodnoj-800', to: 'https://agrosnabmarket.com.ua/products/659ea91fcd72a83f8b075d15' },
        { from: '/p1164877198-remen-privodnoj-850', to: 'https://agrosnabmarket.com.ua/products/659eaa8ccd72a83f8b075d24' },
        { from: '/p1617659103-remen-privodnoj-1050', to: 'https://agrosnabmarket.com.ua/products/65abf6ebb15ff93325027866' },
        { from: '/p1363296045-remen-zubchatyj-1226', to: 'https://agrosnabmarket.com.ua/products/65ad1fb508b3662cefb65e1d' },
        { from: '/p1155736924-remen-privodnoj-zubchatyj', to: 'https://agrosnabmarket.com.ua/products/65b00308a4e7dc56e9875367' },
        { from: '/p1144428813-remen-privodnoj-2800', to: 'https://agrosnabmarket.com.ua/products/65b137a5fa2b43628b618f45' },
        { from: '/p1143440470-remen-privodnoj-2750', to: 'https://agrosnabmarket.com.ua/products/65b13abbfa2b43628b618f54' },
        { from: '/p1495209907-fag-podshipnik-6208c3', to: 'https://agrosnabmarket.com.ua/products/65dc80f15a2e5ad81fe76c87' },
        { from: '/p1416396187-podshipnik-3204-2rs', to: 'https://agrosnabmarket.com.ua/products/656387f163ce531e22eb2318' },
        { from: '/p1172323820-uplotnitelnye-koltsa-140h145h31', to: 'https://agrosnabmarket.com.ua/products/65a95cdda1674f45036fd2d3' },
        { from: '/p1148562757-remen-privodnoj-3000', to: 'https://agrosnabmarket.com.ua/products/65b399c3c9764998ba1207ca' },
        { from: '/p1821649264-remen-privodnoj-1625', to: 'https://agrosnabmarket.com.ua/products/65b3ecce2f5cebb83d19e3f1' },
        { from: '/p1150562620-remen-privodnoj-950', to: 'https://agrosnabmarket.com.ua/products/65b8dd72b03f6d3547811db9' },
        { from: '/p1150578175-remen-privodnoj-1050', to: 'https://agrosnabmarket.com.ua/products/65c07959272af78702d36f3b' },
        { from: '/p1200705196-remen-privodnoj-2240lw', to: 'https://agrosnabmarket.com.ua/products/65c26f5a799cb8ab4529c734' },
        { from: '/p1146710638-remen-privodnoj-1700', to: 'https://agrosnabmarket.com.ua/products/65cfaaf36fd907579d45012a' },
        { from: '/p1819485585-remen-privodnoj-1700', to: 'https://agrosnabmarket.com.ua/products/65cfb1a26fd907579d45017a' },
        { from: '/p1819485585-remen-privodnoj-1700', to: 'https://agrosnabmarket.com.ua/products/65cfb1a26fd907579d45017a' },
        { from: '/p1819530606-remen-privodnoj-0101225', to: 'https://agrosnabmarket.com.ua/products/65d049586fd907579d450239' },
        { from: '/p1150554900-remen-privodnoj-850', to: 'https://agrosnabmarket.com.ua/products/65d2ee37276da6aa79b7f6a7' },
        { from: '/p1175782914-remen-privodnoj-1800', to: 'https://agrosnabmarket.com.ua/products/65d7831543804ba6019920bf' },
        { from: '/p1143435748-remen-privodnoj-1800', to: 'https://agrosnabmarket.com.ua/products/65d7848b43804ba6019920c8' },
        { from: '/p1175788276-remen-privodnoj-1800', to: 'https://agrosnabmarket.com.ua/products/65d785dd43804ba6019920cd' },
        { from: '/p1136690451-remen-privodnoj-670', to: 'https://agrosnabmarket.com.ua/products/65ad23e008b3662cefb65e40' },
        { from: '/p1136688937-remen-privodnoj-710', to: 'https://agrosnabmarket.com.ua/products/65ad245b08b3662cefb65e4e' },
        { from: '/p1139758156-remen-privodnoj-1000', to: 'https://agrosnabmarket.com.ua/products/65bd280d7522daf77d72058a' },
        { from: '/p1136701663-remen-privodnoj-530', to: 'https://agrosnabmarket.com.ua/products/65cc5449c61bae36c375925b' },
        { from: '/p1136680650-remen-privodnoj-800', to: 'https://agrosnabmarket.com.ua/products/65cc592cc61bae36c3759268' },
        { from: '/p1136684923-remen-privodnoj-800', to: 'https://agrosnabmarket.com.ua/products/65cc5a47c61bae36c3759277' },
        { from: '/p1434199751-remen-privodnoj-800', to: 'https://agrosnabmarket.com.ua/products/65cc5ae3c61bae36c375927e' },
        { from: '/p1136661754-remen-privodnoj-900', to: 'https://agrosnabmarket.com.ua/products/65cc5d0ac61bae36c3759291' },
        { from: '/p1136673477-remen-privodnoj-850', to: 'https://agrosnabmarket.com.ua/products/65cc5e09c61bae36c3759299' },
        { from: '/p1136693976-remen-privodnoj-630', to: 'https://agrosnabmarket.com.ua/products/65cc5eb0c61bae36c37592a2' },
        { from: '/p1136696670-remen-privodnoj-560', to: 'https://agrosnabmarket.com.ua/products/65cc7d11c61bae36c37592cd' },
        { from: '/p1196249878-remen-privodnoj-1000', to: 'https://agrosnabmarket.com.ua/products/65cc7f1bc61bae36c37592d1' },
        { from: '/p1197778448-remen-privodnoj-2120', to: 'https://agrosnabmarket.com.ua/products/65cc8050c61bae36c37592d7' },
        { from: '/p1318720108-remen-privodnoj-500', to: 'https://agrosnabmarket.com.ua/products/65cc83b5c61bae36c37596fb' },
        { from: '/p1139752369-remen-privodnoj-600', to: 'https://agrosnabmarket.com.ua/products/65cc85a7c61bae36c3759711' },
        { from: '/p1139754494-remen-privodnoj-750', to: 'https://agrosnabmarket.com.ua/products/65cc8640c61bae36c3759713' },
        { from: '/p1139767930-remen-privodnoj-1120', to: 'https://agrosnabmarket.com.ua/products/65cc8839c61bae36c3759715' },
        { from: '/p1139768072-remen-privodnoj-1180', to: 'https://agrosnabmarket.com.ua/products/65cc8de9c61bae36c375973f' },
        { from: '/p1139768737-remen-privodnoj-1320', to: 'https://agrosnabmarket.com.ua/products/65cc8e90c61bae36c3759741' },
        { from: '/p1139768846-remen-privodnoj-1350', to: 'https://agrosnabmarket.com.ua/products/65cc9425c61bae36c3759792' },
        { from: '/p1139769255-remen-privodnoj-1500', to: 'https://agrosnabmarket.com.ua/products/65cc956cc61bae36c37597e2' },
        { from: '/p1139769524-remen-privodnoj-2500', to: 'https://agrosnabmarket.com.ua/products/65cc9616c61bae36c37597e4' },
        { from: '/p1226285034-remen-privodnoj-2120lw', to: 'https://agrosnabmarket.com.ua/products/65cc974ac61bae36c3759844' },
        { from: '/p1139769168-remen-privodnoj-1400', to: 'https://agrosnabmarket.com.ua/products/65cc97d6c61bae36c3759893' },
        { from: '/p1313206979-remen-privodnoj-900', to: 'https://agrosnabmarket.com.ua/products/65cc9c43c61bae36c375998f' },
        { from: '/p1262258806-remen-privodnoj-950', to: 'https://agrosnabmarket.com.ua/products/65cc9b72c61bae36c3759987' },
        { from: '/p1247892241-remen-privodnoj-1250', to: 'https://agrosnabmarket.com.ua/products/65cca2dbc61bae36c3759a3c' },
        { from: '/p1248022241-remen-privodnoj-1200lp', to: 'https://agrosnabmarket.com.ua/products/65cca69bc61bae36c3759add' },
        { from: '/p1401615720-remen-privodnoj-1040', to: 'https://agrosnabmarket.com.ua/products/65ccaab4c61bae36c3759b2e' },
        { from: '/p1459869220-remen-privodnoj-500', to: 'https://agrosnabmarket.com.ua/products/65ccab92c61bae36c3759b30' },
        { from: '/p1463750252-remen-privodnoj-1800', to: 'https://agrosnabmarket.com.ua/products/65ccad8cc61bae36c3759b7f' },
        { from: '/p1136676341-remen-privodnoj-850', to: 'https://agrosnabmarket.com.ua/products/65ccad8cc61bae36c3759b7f' },
        { from: '/p1136676341-remen-privodnoj-850', to: 'https://agrosnabmarket.com.ua/products/65ccad8cc61bae36c3759b7f' },
        { from: '/p1139767741-remen-privodnoj-1120', to: 'https://agrosnabmarket.com.ua/products/65ccafa8c61bae36c3759bd3' },
        { from: '/p1377318771-homut-nerzhavejka', to: 'https://agrosnabmarket.com.ua/products/659a856dc55f326f962bdef9' },
        { from: '/p1181663506-homut-nerzhavejka', to: 'https://agrosnabmarket.com.ua/products/659a8729c55f326f962bdf7e' },
        { from: '/p1181658412-homut-nerzhavejka', to: 'https://agrosnabmarket.com.ua/products/659a8784c55f326f962bdf85' },
        { from: '/p1181654569-homut-nerzhavejka', to: 'https://agrosnabmarket.com.ua/products/659a88dfc55f326f962bdfa6' },
        { from: '/p1181653457-homut-nerzhavejka', to: 'https://agrosnabmarket.com.ua/products/659aacd465f3fe080e77595f' },
        { from: '/p1181654465-homut-nerzhavejka', to: 'https://agrosnabmarket.com.ua/products/659a8950c55f326f962bdfa8' },
        { from: '/p1377316584-homut-nerzhavejka', to: 'https://agrosnabmarket.com.ua/products/659a89d6c55f326f962bdfae' },
        { from: '/p1377322903-homut-nerzhavejka-100h120', to: 'https://agrosnabmarket.com.ua/products/659a8b1cc55f326f962bdfb4' },
        { from: '/p1377322087-homut-nerzhavejka-110', to: 'https://agrosnabmarket.com.ua/products/659aabf065f3fe080e775956' },
        { from: '/p1181654222-homut-nerzhavejka-100', to: 'https://agrosnabmarket.com.ua/products/659aac7465f3fe080e77595d' },
        { from: '/p1181661020-homut-nerzhavejka', to: 'https://agrosnabmarket.com.ua/products/659aad3e65f3fe080e775964' },
        { from: '/p1181659194-homut-nerzhavejka', to: 'https://agrosnabmarket.com.ua/products/659aad9265f3fe080e775966' },
        { from: '/p1177608605-salnik-30h55h10', to: 'https://agrosnabmarket.com.ua/products/65a2b8c4bebff8e92413cdc0' },
        { from: '/p1152547397-salnik-65h90h10h2', to: 'https://agrosnabmarket.com.ua/products/65a2ba38bebff8e92413cdd3' },
        { from: '/p1152568421-salnik-60h95h10', to: 'https://agrosnabmarket.com.ua/products/65a2bbb7bebff8e92413cdee' },
        { from: '/p1181042416-salnik-58h72h8', to: 'https://agrosnabmarket.com.ua/products/65a2bc4fbebff8e92413cdf0' },
        { from: '/p1152569851-salnik-60h80h8', to: 'https://agrosnabmarket.com.ua/products/65ad27bd08b3662cefb65e69' },
        { from: '/p1176122416-salnik-25h35h7', to: 'https://agrosnabmarket.com.ua/products/65ad29bd08b3662cefb65e9b' },
        { from: '/p1153305327-salnik-40h55h10', to: 'https://agrosnabmarket.com.ua/products/65aebae2745fff639625200f' },
        { from: '/p1175729187-salnik-25h62h7', to: 'https://agrosnabmarket.com.ua/products/65aec257745fff6396252104' },
        { from: '/p1179732821-salnik-55h72h10', to: 'https://agrosnabmarket.com.ua/products/65aff60ba4e7dc56e9875233' },
        { from: '/p1153308193-salnik-40h50h7', to: 'https://agrosnabmarket.com.ua/products/65affbbca4e7dc56e9875248' },
        { from: '/p1153300127-salnik-40h60h10', to: 'https://agrosnabmarket.com.ua/products/65affc26a4e7dc56e987525e' },
        { from: '/p1177609508-salnik-32h52h10', to: 'https://agrosnabmarket.com.ua/products/65df0f4affbe6aab274d7fa0' },
        { from: '/p1163849105-alyuminievaya-shajba-uplotnitelnaya', to: 'https://agrosnabmarket.com.ua/products/659a693f0694e676508c14c7' },
        { from: '/p1163945265-alyuminievaya-shajba-uplotnitelnaya', to: 'https://agrosnabmarket.com.ua/products/659a6a810694e676508c14cf' },
        { from: '/p1163859854-alyuminievaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a6ae10694e676508c14d7' },
        { from: '/p1163852771-alyumievaya-shajba-uplotnitelnaya', to: 'https://agrosnabmarket.com.ua/products/659a6b740694e676508c14dd' },
        { from: '/p1163852512-alyuminievaya-shajba-uplotnitelnaya', to: 'https://agrosnabmarket.com.ua/products/659a6bf90694e676508c14e2' },
        { from: '/p1163852347-alyuminievaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a6c6d0694e676508c14e7' },
        { from: '/p1163852347-alyuminievaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a6c6d0694e676508c14e7' },
        { from: '/p1163945177-alyuminievaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a6cdd0694e676508c14ed' },
        { from: '/p1163848106-alyuminievaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a6d440694e676508c14ef' },
        { from: '/p1163948969-alyuminievaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a6dcf0694e676508c14f1' },
        { from: '/p1163947903-alyuminievaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a6e8b0694e676508c14f7' },
        { from: '/p1163947903-alyuminievaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a6ee60694e676508c14f9' },
        { from: '/p1163951847-mednaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a44fc9778014f7db7eba8' },
        { from: '/p1163866980-mednaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a45ad9778014f7db7ebd7' },
        { from: '/p1163951909-mednaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a463d9778014f7db7ebdb' },
        { from: '/p1163950270-mednaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a47689778014f7db7ebe5' },
        { from: '/p1163949435-mednaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a481c9778014f7db7ebea' },
        { from: '/p1163951993-mednaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a48b99778014f7db7ebff' },
        { from: '/p1163952277-mednaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a49299778014f7db7ec01' },
        { from: '/p1163949629-mednaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a499c9778014f7db7ec06' },
        { from: '/p1163952210-mednaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a4a0f9778014f7db7ec08' },
        { from: '/p1163949524-mednaya-uplotnitelnaya-shajba', to: 'https://agrosnabmarket.com.ua/products/659a4a709778014f7db7ec0a' },
        { from: '/p1362753570-mednaya-shajba-16h20h15', to: 'https://agrosnabmarket.com.ua/products/659a4b099778014f7db7ec0f' },
        { from: '/p1362752891-mednaya-shajba-18h24h15', to: 'https://agrosnabmarket.com.ua/products/659a4b819778014f7db7ec11' },
        { from: '/p1362751986-mednaya-shajba-27h32h15', to: 'https://agrosnabmarket.com.ua/products/659a4be19778014f7db7ec13' },
        { from: '/p1134032582-remen-privodnoj-1100', to: 'https://agrosnabmarket.com.ua/products/65882065b0c79650e39827b8' },
        { from: '/p1134044428-remen-privodnoj-1120', to: 'https://agrosnabmarket.com.ua/products/65882309b0c79650e39827d5' },
        { from: '/p1134058806-remen-privodnoj-1180', to: 'https://agrosnabmarket.com.ua/products/658830c3b0c79650e39827e2' },
        { from: '/p1134065880-remen-privodnoj-1450', to: 'https://agrosnabmarket.com.ua/products/65885583a168e025606a5ba4' },
        { from: '/p1134074546-remen-privodnoj-1600', to: 'https://agrosnabmarket.com.ua/products/65885775a168e025606a5ba9' },
        { from: '/p1134079954-remen-privodnoj-1500', to: 'https://agrosnabmarket.com.ua/products/658859f9a168e025606a5bb1' },
        { from: '/p1134085299-remen-privodnoj-2240', to: 'https://agrosnabmarket.com.ua/products/65892d1bd93d57f5da78577e' },
        { from: '/p1134088793-remen-privodnoj-2900', to: 'https://agrosnabmarket.com.ua/products/658931c9d93d57f5da785794' },
        { from: '/p1204046754-remen-zubchatyj-1250', to: 'https://agrosnabmarket.com.ua/products/65979b98df0f55c52a290e45' },
        { from: '/p1204159925-remen-privodnoj-1060', to: 'https://agrosnabmarket.com.ua/products/65984d8c713cd957d011de2b' },
        { from: '/p1149875283-salnik-25x32x7', to: 'https://agrosnabmarket.com.ua/products/65df715ea32507df96e282af' },
        { from: '/p1149875283-salnik-25x32x7', to: 'https://agrosnabmarket.com.ua/products/65df715ea32507df96e282af' },
        { from: '/p1176002613-salnik-25h42h10', to: 'https://agrosnabmarket.com.ua/products/65affc7da4e7dc56e9875260' },
        { from: '/p1181034145-salnik-55h80h10', to: 'https://agrosnabmarket.com.ua/products/65b38ad182889f9fbd1b1e0b' },
        { from: '/p1177608481-salnik-30h52h10', to: 'https://agrosnabmarket.com.ua/products/65b5079ca098e2f78408e93f' },
        { from: '/p1181252799-salnik-60h75h8', to: 'https://agrosnabmarket.com.ua/products/65b6908a11b9c1255b459b62' },
        { from: '/p1176776513-salnik-28h42h7', to: 'https://agrosnabmarket.com.ua/products/65b7cfbf6b98d0e549ee53ab' },
        { from: '/p1173752919-salnik-15h30h7', to: 'https://agrosnabmarket.com.ua/products/65bcc8395594acb693fcae19' },
        { from: '/p1177499187-salnik-25h47h10', to: 'https://agrosnabmarket.com.ua/products/65bcc98b5594acb693fcae32' },
        { from: '/p1152569187-salnik-60h80h10', to: 'https://agrosnabmarket.com.ua/products/65c07b4e272af78702d36f52' },
        { from: '/p1179811494-salnik-95h120h12', to: 'https://agrosnabmarket.com.ua/products/65c07c48272af78702d36f5d' },
        { from: '/p1175594839-salnik-20h40h7', to: 'https://agrosnabmarket.com.ua/products/65c2078f94196b2b3bf643af' },
        { from: '/p1177600811-salnik-30h47h7', to: 'https://agrosnabmarket.com.ua/products/65c268d5799cb8ab4529c5df' },
        { from: '/p1364031714-salnik-28h40h8', to: 'https://agrosnabmarket.com.ua/products/65cba296c61bae36c37591af' },
        { from: '/p1152523802-salnik-75h100h10', to: 'https://agrosnabmarket.com.ua/products/65cba579c61bae36c37591d0' },
        { from: '/p1496607481-salnik-48h72h7', to: 'https://agrosnabmarket.com.ua/products/65cba63bc61bae36c37591e6' },
        { from: '/p1429820198-remen-privodnoj-3200', to: 'https://agrosnabmarket.com.ua/products/65e0d0f550c32a95284a7b09' },
        { from: '/p1144407345-remen-privodnoj-1200', to: 'https://agrosnabmarket.com.ua/products/65e0cf8350c32a95284a7b03' },
        { from: '/p1408340180-remen-privodnoj-2440', to: 'https://agrosnabmarket.com.ua/products/65e0c9b850c32a95284a7afa' },
        { from: '/p1160419898-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659b9d9749a81c3ccee89547' },
        { from: '/p1158936840-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba12b49a81c3ccee8955b' },
        { from: '/p1158932205-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba1d049a81c3ccee8957a' },
        { from: '/p1158939541-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba29449a81c3ccee89582' },
        { from: '/p1167239118-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba30149a81c3ccee89589' },
        { from: '/p1167290305-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba40a49a81c3ccee895c1' },
        { from: '/p1173282575-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba5b249a81c3ccee895d5' },
        { from: '/p1173268705-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba61349a81c3ccee895d7' },
        { from: '/p1173280198-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba67f49a81c3ccee895dd' },
        { from: '/p1173274790-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba70749a81c3ccee895df' },
        { from: '/p1173270124-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba7a049a81c3ccee895e5' },
        { from: '/p1173278328-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba81349a81c3ccee895e9' },
        { from: '/p1173284273-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba86e49a81c3ccee895eb' },
        { from: '/p1173276020-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659ba91149a81c3ccee895ed' },
        { from: '/p1369363065-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659bab1949a81c3ccee895ef' },
        { from: '/p1369866659-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659bab9649a81c3ccee895f7' },
        { from: '/p1377324738-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659bac0249a81c3ccee895f9' },
        { from: '/p1284628887-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659bac7149a81c3ccee895ff' },
        { from: '/p1160424868-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659bad2e49a81c3ccee89601' },
        { from: '/p1173277151-homut-silovoj-usilennyj', to: 'https://agrosnabmarket.com.ua/products/659bad9949a81c3ccee89607' },
        { from: '/p1178524771-salnik-50h70h10', to: 'https://agrosnabmarket.com.ua/products/65ccb447c61bae36c3759cac' },
        { from: '/p1176751576-salnik-20h35h7', to: 'https://agrosnabmarket.com.ua/products/65ccec2dc61bae36c3759e26' },
        { from: '/p1143973053-salnik-45h65h10', to: 'https://agrosnabmarket.com.ua/products/65cda8f7cf74a6143c1e13d3' },
        { from: '/p1176165481-salnik-23h37h7', to: 'https://agrosnabmarket.com.ua/products/65d331c5276da6aa79b7f72a' },
        { from: '/p1152545170-salnik-70h85h8', to: 'https://agrosnabmarket.com.ua/products/65d33501276da6aa79b7f746' },
        { from: '/p1179788729-salnik-45h72h12', to: 'https://agrosnabmarket.com.ua/products/65d63e4092466cab3e02d18a' },
        { from: '/p1176260148-salnik-20h47h10', to: 'https://agrosnabmarket.com.ua/products/65d6e88492466cab3e02d222' },
        { from: '/p1179797590-salnik-45h75h10', to: 'https://agrosnabmarket.com.ua/products/65d6ecb892466cab3e02d22f' },
        { from: '/p1175595158-salnik-20h42h7', to: 'https://agrosnabmarket.com.ua/products/65d6f03d92466cab3e02d267' },
        { from: '/p1175595560-salnik-20h45h7', to: 'https://agrosnabmarket.com.ua/products/65d6f0ef92466cab3e02d269' },
        { from: '/p1143975157-salnik-45h68h10', to: 'https://agrosnabmarket.com.ua/products/65db8c28a769d8fc134aad64' },
        { from: '/p1143975945-salnik-45h70h10', to: 'https://agrosnabmarket.com.ua/products/65dc2217f508d3de57bbb41e' },
        { from: '/p1143976063-salnik-85h115h12', to: 'https://agrosnabmarket.com.ua/products/65dc22f4f508d3de57bbb433' },
        { from: '/p1173829474-salnik-12h30h7', to: 'https://agrosnabmarket.com.ua/products/65dc2473f508d3de57bbb45a' },
        { from: '/p1143977645-salnik-90h110h10', to: 'https://agrosnabmarket.com.ua/products/65dc2780f508d3de57bbb46f' },
        { from: '/p1173830585-salnik-12h25h7', to: 'https://agrosnabmarket.com.ua/products/65dc2903f508d3de57bbb486' },
        { from: '/p1173832062-salnik-12h22h7', to: 'https://agrosnabmarket.com.ua/products/65dc322ee6b729080b20d752' },
        { from: '/p1176155462-salnik-24h40h7', to: 'https://agrosnabmarket.com.ua/products/65dc330ce6b729080b20d754' },
        { from: '/p1145239068-salnik-17h28h7', to: 'https://agrosnabmarket.com.ua/products/65dc3447e6b729080b20d771' },
        { from: '/p1175500081-salnik-8h22h7', to: 'https://agrosnabmarket.com.ua/products/65dc34a2e6b729080b20d773' },
        { from: '/p1175592841-salnik-11h22h7', to: 'https://agrosnabmarket.com.ua/products/65dc352ee6b729080b20d786' },
        { from: '/p1149874738-salnik-16h38h7', to: 'https://agrosnabmarket.com.ua/products/65dc3586e6b729080b20d788' },
        { from: '/p1175594485-salnik-12h26h7', to: 'https://agrosnabmarket.com.ua/products/65dc7c4d5a2e5ad81fe76c66' },
        { from: '/p1175594597-salnik-20h37h7', to: 'https://agrosnabmarket.com.ua/products/65dc7d3c5a2e5ad81fe76c7a' },
        { from: '/p1175598712-salnik-16h24h7', to: 'https://agrosnabmarket.com.ua/products/65dccaa1c93347ccc180d427' },
        { from: '/p1175599030-salnik-16h28h7h2', to: 'https://agrosnabmarket.com.ua/products/65dccb48c93347ccc180d42a' },
        { from: '/p1175690475-salnik-25h37h5', to: 'https://agrosnabmarket.com.ua/products/65dccc45c93347ccc180d42c' },
        { from: '/p1176168526-salnik-22h35h7', to: 'https://agrosnabmarket.com.ua/products/65dccd2dc93347ccc180d42e' },
        { from: '/p1176247686-salnik-21h40h7', to: 'https://agrosnabmarket.com.ua/products/65dcd071c93347ccc180d430' },
        { from: '/p1149875155-salnik-24h42h10', to: 'https://agrosnabmarket.com.ua/products/65dcd10fc93347ccc180d442' },
        { from: '/p1149876918-salnik-42h68h10', to: 'https://agrosnabmarket.com.ua/products/65de1f48cb50e0a56f9fb214' },
        { from: '/p1175596616-salnik-20h52h10', to: 'https://agrosnabmarket.com.ua/products/65de20bccb50e0a56f9fb227' },
        { from: '/p1175598597-salnik-16h24h5', to: 'https://agrosnabmarket.com.ua/products/65de248bcb50e0a56f9fb233' },
        { from: '/p1175599150-salnik-16h30h10', to: 'https://agrosnabmarket.com.ua/products/65de2507cb50e0a56f9fb23f' },
        { from: '/p1175697440-salnik-25h37h7', to: 'https://agrosnabmarket.com.ua/products/65de2602cb50e0a56f9fb252' },
        { from: '/p1175699114-salnik-25h38h7', to: 'https://agrosnabmarket.com.ua/products/65de272acb50e0a56f9fb254' },
        { from: '/p1175727821-salnik-115h145h12', to: 'https://agrosnabmarket.com.ua/products/65de2830cb50e0a56f9fb266' },
        { from: '/p1175753796-salnik-25h47h7', to: 'https://agrosnabmarket.com.ua/products/65de2946cb50e0a56f9fb273' },
        { from: '/p1175764087-salnik-25h50h10', to: 'https://agrosnabmarket.com.ua/products/65de2c9ccb50e0a56f9fb285' },
        { from: '/p1175769934-salnik-25h62h10', to: 'https://agrosnabmarket.com.ua/products/65de2cf2cb50e0a56f9fb28f' },
        { from: '/p1152520701-salnik-80h110h10', to: 'https://agrosnabmarket.com.ua/products/65df608fa4920c224c3c944e' },
        { from: '/p1152522173-salnik-80h105h10', to: 'https://agrosnabmarket.com.ua/products/65df61aba4920c224c3c9453' },
        { from: '/p1175997987-salnik-25h45h10', to: 'https://agrosnabmarket.com.ua/products/65df627ca4920c224c3c946e' },
        { from: '/p1176000556-salnik-25h45h7', to: 'https://agrosnabmarket.com.ua/products/65df6412a4920c224c3c9482' },
        { from: '/p1152523410-salnik-80h100h10', to: 'https://agrosnabmarket.com.ua/products/65df62f3a4920c224c3c947c' },
        { from: '/p1176772693-salnik-25h32h4', to: 'https://agrosnabmarket.com.ua/products/65df7474a32507df96e282cf' },
        { from: '/p1177462111-salnik-32h47h7', to: 'https://agrosnabmarket.com.ua/products/65df7588a32507df96e282f9' },
        { from: '/p1177495316-salnik-35h55h10', to: 'https://agrosnabmarket.com.ua/products/65df7687a32507df96e28307' },
        { from: '/p1177498751-salnik-25h47h7', to: 'https://agrosnabmarket.com.ua/products/65df76f4a32507df96e2830c' },
        { from: '/p1178317834-salnik-42h52h7', to: 'https://agrosnabmarket.com.ua/products/65e0a29b0e39692875cd6028' },
        { from: '/p1177619250-salnik-40h52h7', to: 'https://agrosnabmarket.com.ua/products/65e0a5120e39692875cd602f' },
        { from: '/p1177627393-salnik-42h75h10', to: 'https://agrosnabmarket.com.ua/products/65e0a5a10e39692875cd6034' },
        { from: '/p1178333615-salnik-35h80h12', to: 'https://agrosnabmarket.com.ua/products/65e0b6dccf9f1aed3be0c2ef' },
        { from: '/p1179753179-salnik-45h62h10', to: 'https://agrosnabmarket.com.ua/products/65e0b781cf9f1aed3be0c2f7' },
        { from: '/p1179795105-salnik-45h75h10', to: 'https://agrosnabmarket.com.ua/products/65e0b7ffcf9f1aed3be0c2fc' },
        { from: '/p1179827267-salnik-50h72h12', to: 'https://agrosnabmarket.com.ua/products/65e0b88ecf9f1aed3be0c2fe' },
        { from: '/p1176004034-salnik-25h42h8', to: 'https://agrosnabmarket.com.ua/products/65e1baba049f06c744409fac' },
        { from: '/p1152525785-salnik-75h90h8h2', to: 'https://agrosnabmarket.com.ua/products/65e1bd80049f06c744409fb2' },
        { from: '/p1176105915-salnik-25h40h7', to: 'https://agrosnabmarket.com.ua/products/65e1c0fc049f06c744409fc6' },
        { from: '/p1283966305-toplivnyj-filtr-r550148', to: 'https://agrosnabmarket.com.ua/products/65dcd3ebc93347ccc180d46b' },
        { from: '/p1514255979-filtr-r551027', to: 'https://agrosnabmarket.com.ua/products/65dcd530c93347ccc180d474' },
        { from: '/p1514276633-filtr-r551026', to: 'https://agrosnabmarket.com.ua/products/65dcd90fc93347ccc180d484' },
        { from: '/p1170505962-uplotnitelnye-koltsa-40h44h25', to: 'https://agrosnabmarket.com.ua/products/65983115713cd957d011dd73' },
        { from: '/p1170627243-uplotnitelnye-koltsa-42h48h3', to: 'https://agrosnabmarket.com.ua/products/65983338713cd957d011dd82' },
        { from: '/p1169577576-uplotnitelnye-koltsa-18h22h25', to: 'https://agrosnabmarket.com.ua/products/659957af5f561ecd9cb3260f' },
        { from: '/p1169575922-uplotnitelnye-koltsa-18h23h31', to: 'https://agrosnabmarket.com.ua/products/659958b75f561ecd9cb32624' },
        { from: '/p1168968898-uplotnitelnye-koltsa-23h29h36', to: 'https://agrosnabmarket.com.ua/products/659959fb5f561ecd9cb32644' },
        { from: '/p1169582930-uplotnitelnye-koltsa-30h33h19', to: 'https://agrosnabmarket.com.ua/products/659e9dd8cd72a83f8b075b9c' },
        { from: '/p1283914709-uplotnitelnye-koltsa-40h50h5', to: 'https://agrosnabmarket.com.ua/products/659e9f3dcd72a83f8b075bb3' },
        { from: '/p1142419343-uplotnitelnye-koltsa-76h82h35', to: 'https://agrosnabmarket.com.ua/products/659ea0c0cd72a83f8b075bc7' },
        { from: '/p1142408010-uplotnitelnye-koltsa-78h83h31', to: 'https://agrosnabmarket.com.ua/products/659ea130cd72a83f8b075bc9' },
        { from: '/p1170499688-uplotnitelnye-koltsa-39h45h35', to: 'https://agrosnabmarket.com.ua/products/659ea3cbcd72a83f8b075c82' },
        { from: '/p1163845933-uplotnitelnye-koltsa-3h6h19', to: 'https://agrosnabmarket.com.ua/products/659ea499cd72a83f8b075c91' },
        { from: '/p1163847596-uplotnitelnye-koltsa-4h6h14', to: 'https://agrosnabmarket.com.ua/products/659ea504cd72a83f8b075c93' },
        { from: '/p1168370981-uplotnitelnye-koltsa-29h33h24', to: 'https://agrosnabmarket.com.ua/products/65a2c302bebff8e92413ce7b' },
        { from: '/p1168378950-uplotnitelnye-koltsa-28h32h25', to: 'https://agrosnabmarket.com.ua/products/65a2c374bebff8e92413ce81' },
        { from: '/p1168372190-uplotnitelnye-koltsa-29h32h19', to: 'https://agrosnabmarket.com.ua/products/65a2c3d5bebff8e92413ce83' },
        { from: '/p1168391305-uplotnitelnye-koltsa-26h30h25', to: 'https://agrosnabmarket.com.ua/products/65a2c47abebff8e92413ce91' },
        { from: '/p1168403934-uplotnitelnye-koltsa-25h28h19', to: 'https://agrosnabmarket.com.ua/products/65a2c50abebff8e92413ce93' },
        { from: '/p1168392716-uplotnitelnye-koltsa-26h29h19', to: 'https://agrosnabmarket.com.ua/products/65a2c56abebff8e92413ce95' },
        { from: '/p1168380079-uplotnitelnye-koltsa-28h31h19', to: 'https://agrosnabmarket.com.ua/products/65a2c5c3bebff8e92413ce97' },
        { from: '/p1168385886-uplotnitelnye-koltsa-27h30h19', to: 'https://agrosnabmarket.com.ua/products/65a2c615bebff8e92413ce99' },
        { from: '/p1141326096-uplotnitelnye-koltsa-87h92h31', to: 'https://agrosnabmarket.com.ua/products/65a384ac765a062f9ffc3acb' },
        { from: '/p1141351735-uplotnitelnye-koltsa-100x108x46', to: 'https://agrosnabmarket.com.ua/products/65a3b079a8f40ff60dec7738' },
        { from: '/p1141356528-uplotnitelnye-koltsa-100x106x35', to: 'https://agrosnabmarket.com.ua/products/65a3b0e4a8f40ff60dec773a' },
        { from: '/p1141321600-uplotnitelnye-koltsa-88h92h24', to: 'https://agrosnabmarket.com.ua/products/65a38419765a062f9ffc3ac5' },
        { from: '/p1141359147-uplotnitelnye-koltsa-100x105x24', to: 'https://agrosnabmarket.com.ua/products/65a3b245a8f40ff60dec773f' },
        { from: '/p1141373135-uplotnitelnye-koltsa-100x105x33', to: 'https://agrosnabmarket.com.ua/products/65a3b3b8a8f40ff60dec7748' },
        { from: '/p1141361540-uplotnitelnye-koltsa-100x105x31', to: 'https://agrosnabmarket.com.ua/products/65a3b2bda8f40ff60dec7741' },
        { from: '/p1141374883-uplotnitelnye-koltsa-98x110x75', to: 'https://agrosnabmarket.com.ua/products/65a3b468a8f40ff60dec774d' },
        { from: '/p1141376352-uplotnitelnye-koltsa-99x105x35', to: 'https://agrosnabmarket.com.ua/products/65a3b4fba8f40ff60dec7752' },
        { from: '/p1141581446-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a3b5cca8f40ff60dec7754' },
        { from: '/p1141582266-uplotnitelnye-koltsa-98x105x45', to: 'https://agrosnabmarket.com.ua/products/65a3b67fa8f40ff60dec7756' },
        { from: '/p1141583294-uplotnitelnye-koltsa-98x104x35', to: 'https://agrosnabmarket.com.ua/products/65a3b6fea8f40ff60dec775b' },
        { from: '/p1141583365-uplotnitelnye-koltsa-98x102x31', to: 'https://agrosnabmarket.com.ua/products/65a3b9cbfba20d4af8f33b17' },
        { from: '/p1141585788-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a3ba45fba20d4af8f33b1c' },
        { from: '/p1142045462-uplotnitelnye-koltsa-98x101x19', to: 'https://agrosnabmarket.com.ua/products/65a4269eca52c8cf8690a761' },
        { from: '/p1142046403-uplotnitelnye-koltsa-96x102x35', to: 'https://agrosnabmarket.com.ua/products/65a427f3ca52c8cf8690a763' },
        { from: '/p1142047787-uplotnitelnye-koltsa-95x105x57', to: 'https://agrosnabmarket.com.ua/products/65a42883ca52c8cf8690a765' },
        { from: '/p1142049735-uplotnitelnye-koltsa-95x105x53', to: 'https://agrosnabmarket.com.ua/products/65a42937ca52c8cf8690a76e' },
        { from: '/p1142050963-uplotnitelnye-koltsa-95x102x45', to: 'https://agrosnabmarket.com.ua/products/65a429b6ca52c8cf8690a770' },
        { from: '/p1142052130-uplotnitelnye-koltsa-95x101x35', to: 'https://agrosnabmarket.com.ua/products/65a42a06ca52c8cf8690a775' },
        { from: '/p1142054632-uplotnitelnye-koltsa-95x100x31', to: 'https://agrosnabmarket.com.ua/products/65a42a98ca52c8cf8690a777' },
        { from: '/p1142057262-uplotnitelnye-koltsa-95x100x25', to: 'https://agrosnabmarket.com.ua/products/65a42aeaca52c8cf8690a779' },
        { from: '/p1142063369-uplotnitelnye-koltsa-92x100x45', to: 'https://agrosnabmarket.com.ua/products/65a42b4fca52c8cf8690a77b' },
        { from: '/p1142064277-uplotnitelnye-koltsa-92x98x35', to: 'https://agrosnabmarket.com.ua/products/65a42c18ca52c8cf8690a77d' },
        { from: '/p1142064707-uplotnitelnye-koltsa-92x98x31', to: 'https://agrosnabmarket.com.ua/products/65a42cb1ca52c8cf8690a77f' },
        { from: '/p1142066350-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a42d35ca52c8cf8690a781' },
        { from: '/p1142117348-uplotnitelnye-koltsa-90x98x46', to: 'https://agrosnabmarket.com.ua/products/65a42da6ca52c8cf8690a786' },
        { from: '/p1142118773-uplotnitelnye-koltsa-90x96x35', to: 'https://agrosnabmarket.com.ua/products/65a42e3eca52c8cf8690a788' },
        { from: '/p1142121710-uplotnitelnye-koltsa-90x95x3', to: 'https://agrosnabmarket.com.ua/products/65a42f22ca52c8cf8690a78a' },
        { from: '/p1142124235-uplotnitelnye-koltsa-90x95x25', to: 'https://agrosnabmarket.com.ua/products/65a42fa4ca52c8cf8690a78c' },
        { from: '/p1142125817-uplotnitelnye-koltsa-89x95x35', to: 'https://agrosnabmarket.com.ua/products/65a43006ca52c8cf8690a78e' },
        { from: '/p1142131264-uplotnitelnye-koltsa-88x98x57', to: 'https://agrosnabmarket.com.ua/products/65a43065ca52c8cf8690a790' },
        { from: '/p1142297216-uplotnitelnye-koltsa-88x95x45', to: 'https://agrosnabmarket.com.ua/products/65a432dfca52c8cf8690a796' },
        { from: '/p1142297336-uplotnitelnye-koltsa-88x94x35', to: 'https://agrosnabmarket.com.ua/products/65a43342ca52c8cf8690a798' },
        { from: '/p1142297484-uplotnitelnye-koltsa-100h110h5', to: 'https://agrosnabmarket.com.ua/products/65a433b8ca52c8cf8690a79a' },
        { from: '/p1142297636-uplotnitelnye-koltsa-86h92h35', to: 'https://agrosnabmarket.com.ua/products/65a4343aca52c8cf8690a79c' },
        { from: '/p1142297859-uplotnitelnye-koltsa-85h91h35', to: 'https://agrosnabmarket.com.ua/products/65a43552ca52c8cf8690a7a2' },
        { from: '/p1142297901-uplotnitelnye-koltsa-85h90h31', to: 'https://agrosnabmarket.com.ua/products/65a4376bca52c8cf8690a7a7' },
        { from: '/p1142299111-uplotnitelnye-koltsa-85h90h24', to: 'https://agrosnabmarket.com.ua/products/65a437baca52c8cf8690a7a9' },
        { from: '/p1142299249-uplotnitelnye-koltsa-84h90h35', to: 'https://agrosnabmarket.com.ua/products/65a4382dca52c8cf8690a7b8' },
        { from: '/p1142299410-uplotnitelnye-koltsa-84h88h24', to: 'https://agrosnabmarket.com.ua/products/65a43893ca52c8cf8690a7ba' },
        { from: '/p1142299730-uplotnitelnye-koltsa-82h92h57', to: 'https://agrosnabmarket.com.ua/products/65a43932ca52c8cf8690a7bc' },
        { from: '/p1142335534-uplotnitelnye-koltsa-82h90h46', to: 'https://agrosnabmarket.com.ua/products/65a43986ca52c8cf8690a7be' },
        { from: '/p1142369352-uplotnitelnye-koltsa-82h88h31', to: 'https://agrosnabmarket.com.ua/products/65a439e7ca52c8cf8690a7c0' },
        { from: '/p1142386592-uplotnitelnye-koltsa-82h86h25', to: 'https://agrosnabmarket.com.ua/products/65a43a82ca52c8cf8690a932' },
        { from: '/p1142389457-uplotnitelnye-koltsa-80h90h57', to: 'https://agrosnabmarket.com.ua/products/65a43affca52c8cf8690a94d' },
        { from: '/p1142391932-uplotnitelnye-koltsa-80h88h45', to: 'https://agrosnabmarket.com.ua/products/65a43bd1ca52c8cf8690a969' },
        { from: '/p1142395174-uplotnitelnye-koltsa-80h86h35', to: 'https://agrosnabmarket.com.ua/products/65a43c1fca52c8cf8690a96b' },
        { from: '/p1142398555-uplotnitelnye-koltsa-80h85h3', to: 'https://agrosnabmarket.com.ua/products/65a43c6bca52c8cf8690a96d' },
        { from: '/p1142398555-uplotnitelnye-koltsa-80h85h3', to: 'https://agrosnabmarket.com.ua/products/65a43c6bca52c8cf8690a96d' },
        { from: '/p1142399820-uplotnitelnye-koltsa-80h85h24', to: 'https://agrosnabmarket.com.ua/products/65a43cc2ca52c8cf8690a96f' },
        { from: '/p1142401948-uplotnitelnye-koltsa-78h88h57', to: 'https://agrosnabmarket.com.ua/products/65a43d45ca52c8cf8690a98d' },
        { from: '/p1142404894-uplotnitelnye-koltsa-78h84h36', to: 'https://agrosnabmarket.com.ua/products/65a43dd2ca52c8cf8690a9aa' },
        { from: '/p1142410598-uplotnitelnye-koltsa-78h82h24', to: 'https://agrosnabmarket.com.ua/products/65a43e74ca52c8cf8690a9cf' },
        { from: '/p1142412730-uplotnitelnye-koltsa-78h81h19', to: 'https://agrosnabmarket.com.ua/products/65a43f20ca52c8cf8690a9ea' },
        { from: '/p1142416252-uplotnitelnye-koltsa-77h85h45', to: 'https://agrosnabmarket.com.ua/products/65a43f81ca52c8cf8690a9ec' },
        { from: '/p1142422908-uplotnitelnye-koltsa-75h85h57', to: 'https://agrosnabmarket.com.ua/products/65a44082ca52c8cf8690aa24' },
        { from: '/p1142424636-uplotnitelnye-koltsa-75h83h45', to: 'https://agrosnabmarket.com.ua/products/65a4412fca52c8cf8690aa26' },
        { from: '/p1142428118-uplotnitelnye-koltsa-75h81h35', to: 'https://agrosnabmarket.com.ua/products/65a44181ca52c8cf8690aa41' },
        { from: '/p1142428939-uplotnitelnye-koltsa-75h80h24', to: 'https://agrosnabmarket.com.ua/products/65a441d8ca52c8cf8690aa43' },
        { from: '/p1142429213-uplotnitelnye-koltsa-74h82h45', to: 'https://agrosnabmarket.com.ua/products/65a44238ca52c8cf8690aa45' },
        { from: '/p1142431166-uplotnitelnye-koltsa-74h80h35', to: 'https://agrosnabmarket.com.ua/products/65a44290ca52c8cf8690aa47' },
        { from: '/p1142432381-uplotnitelnye-koltsa-73h78h24', to: 'https://agrosnabmarket.com.ua/products/65a44337ca52c8cf8690aa62' },
        { from: '/p1142465556-uplotnitelnye-koltsa-72h80h45', to: 'https://agrosnabmarket.com.ua/products/65a44398ca52c8cf8690aa64' },
        { from: '/p1142488020-uplotnitelnye-koltsa-72h78h35', to: 'https://agrosnabmarket.com.ua/products/65a4440eca52c8cf8690aa82' },
        { from: '/p1142489989-uplotnitelnye-koltsa-70h80h58', to: 'https://agrosnabmarket.com.ua/products/65a4446fca52c8cf8690aa84' },
        { from: '/p1142493767-uplotnitelnye-koltsa-70h80h5', to: 'https://agrosnabmarket.com.ua/products/65a444b5ca52c8cf8690aa86' },
        { from: '/p1142500442-uplotnitelnye-koltsa-70h76h35', to: 'https://agrosnabmarket.com.ua/products/65a4c28d3117bae55c9c8305' },
        { from: '/p1143311992-podshipnik-lm67048lm67010', to: 'https://agrosnabmarket.com.ua/products/65e4a30a0d7066349f8a1a9f' },
        { from: '/p1523575610-podshipnik-lm2974910-timken', to: 'https://agrosnabmarket.com.ua/products/65e49f310d7066349f8a1a94' },
        { from: '/p1516426340-podshipnik-jlm50684910', to: 'https://agrosnabmarket.com.ua/products/65e47ce40d7066349f8a1a6e' },
        { from: '/p1240184051-podshipnik-32028', to: 'https://agrosnabmarket.com.ua/products/65e479ccb1f76cea9a76fac0' },
        { from: '/p1149846025-remen-privodnoj-1900', to: 'https://agrosnabmarket.com.ua/products/65e4bae90d7066349f8a1b19' },
        { from: '/p1149846224-remen-privodnoj-2120', to: 'https://agrosnabmarket.com.ua/products/65e4bf2d0d7066349f8a1b29' },
        { from: '/p1460270168-remen-privodnoj-3000', to: 'https://agrosnabmarket.com.ua/products/65e4c86d8a6262d22ac2f2bf' },
        { from: '/p1149845884-remen-privodnoj-2240', to: 'https://agrosnabmarket.com.ua/products/65e4c88c8a6262d22ac2f2ca' },
        { from: '/p1218248173-remen-privodnoj-3550', to: 'https://agrosnabmarket.com.ua/products/65e4ca148a6262d22ac2f2cf' },
        { from: '/p1149845784-remen-privodnoj-3350', to: 'https://agrosnabmarket.com.ua/products/65e4cac48a6262d22ac2f2d1' },
        { from: '/p1446477458-remen-privodnoj-0203201', to: 'https://agrosnabmarket.com.ua/products/65e60ec9104a2ad186b455e2' },
        { from: '/p1266131982-remen-privodnoj-7068290', to: 'https://agrosnabmarket.com.ua/products/65e610ea104a2ad186b455f3' },
        { from: '/p1215973928-remen-privodnoj-5300lp', to: 'https://agrosnabmarket.com.ua/products/65e61251104a2ad186b45603' },
        { from: '/p1149825754-remen-privodnoj-3475', to: 'https://agrosnabmarket.com.ua/products/65e61327104a2ad186b45608' },
        { from: '/p1142503992-uplotnitelnye-koltsa-70h73h19', to: 'https://agrosnabmarket.com.ua/products/65a4c2ea3117bae55c9c8307' },
        { from: '/p1142508959-uplotnitelnye-koltsa-68h76h45', to: 'https://agrosnabmarket.com.ua/products/65a4c3513117bae55c9c8309' },
        { from: '/p1142516837-uplotnitelnye-koltsa-68h74h35', to: 'https://agrosnabmarket.com.ua/products/65a4c3bf3117bae55c9c830b' },
        { from: '/p1142534072-uplotnitelnye-koltsa-70h75h3', to: 'https://agrosnabmarket.com.ua/products/65a4c4303117bae55c9c830d' },
        { from: '/p1142546493-uplotnitelnye-koltsa-70h75h24', to: 'https://agrosnabmarket.com.ua/products/65a4c4923117bae55c9c8328' },
        { from: '/p1142549551-uplotnitelnye-koltsa-67h75h45', to: 'https://agrosnabmarket.com.ua/products/65a4c4f73117bae55c9c832a' },
        { from: '/p1142550719-uplotnitelnye-koltsa-68h73h31', to: 'https://agrosnabmarket.com.ua/products/65a4c55e3117bae55c9c832c' },
        { from: '/p1142551320-uplotnitelnye-koltsa-66h72h35', to: 'https://agrosnabmarket.com.ua/products/65a4c68f3117bae55c9c8347' },
        { from: '/p1142551836-uplotnitelnye-koltsa-68h72h24', to: 'https://agrosnabmarket.com.ua/products/65a4c7513117bae55c9c8349' },
        { from: '/p1162427055-uplotnitelnye-koltsa-40h43h19', to: 'https://agrosnabmarket.com.ua/products/65a4c7ca3117bae55c9c8366' },
        { from: '/p1162430241-uplotnitelnye-koltsa-55h60h31', to: 'https://agrosnabmarket.com.ua/products/65a4cb7b3117bae55c9c8381' },
        { from: '/p1162434285-uplotnitelnye-koltsa-55h61h35', to: 'https://agrosnabmarket.com.ua/products/65a4cc173117bae55c9c8383' },
        { from: '/p1162438010-uplotnitelnye-koltsa-55h63h45', to: 'https://agrosnabmarket.com.ua/products/65a4e3a27c3e73c3750c9bec' },
        { from: '/p1162440492-uplotnitelnye-koltsa-55h65h57', to: 'https://agrosnabmarket.com.ua/products/65a4e3f87c3e73c3750c9bee' },
        { from: '/p1163841507-uplotnitelnye-koltsa-2h5h19', to: 'https://agrosnabmarket.com.ua/products/65a4e45f7c3e73c3750c9bf0' },
        { from: '/p1163843368-uplotnitelnye-koltsa-3h5h14', to: 'https://agrosnabmarket.com.ua/products/65a4e4d17c3e73c3750c9c0b' },
        { from: '/p1163846559-uplotnitelnye-koltsa-3h7h25', to: 'https://agrosnabmarket.com.ua/products/65a4e57b7c3e73c3750c9c0d' },
        { from: '/p1163846702-uplotnitelnye-koltsa-4h6h1', to: 'https://agrosnabmarket.com.ua/products/65a4e61d7c3e73c3750c9c0f' },
        { from: '/p1164545490-uplotnitelnye-koltsa-4h7h19', to: 'https://agrosnabmarket.com.ua/products/65a4e6a27c3e73c3750c9c2a' },
        { from: '/p1149261607-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65dd9e46bdfe34311e3b0065' },
        { from: '/p1149264716-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65ddb067bdfe34311e3b0074' },
        { from: '/p1155727974-remen-privodnoj-spaua', to: 'https://agrosnabmarket.com.ua/products/65ddb14fbdfe34311e3b0079' },
        { from: '/p1155728633-remen-privodnoj-spaua', to: 'https://agrosnabmarket.com.ua/products/65ddb378bdfe34311e3b007b' },
        { from: '/p1155734683-remen-privodnoj-spaua', to: 'https://agrosnabmarket.com.ua/products/65ddb62bbdfe34311e3b0080' },
        { from: '/p1165799546-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65ddb80bbdfe34311e3b0086' },
        { from: '/p1155732234-remen-privodnoj-spaua', to: 'https://agrosnabmarket.com.ua/products/65de1874cb50e0a56f9fb1e8' },
        { from: '/p1199268480-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65e1cf86049f06c74440a069' },
        { from: '/p1199433925-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65e1d29c049f06c74440a06e' },
        { from: '/p1540243151-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65e1f2bc049f06c74440a085' },
        { from: '/p1247889581-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65e1f365049f06c74440a087' },
        { from: '/p1251499562-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65e1f430049f06c74440a089' },
        { from: '/p1392402426-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65e1f652049f06c74440a099' },
        { from: '/p1308121517-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65e1f7c8049f06c74440a0a4' },
        { from: '/p1308148466-remen-privodnoj-h101813', to: 'https://agrosnabmarket.com.ua/products/65e1f8f2049f06c74440a0aa' },
        { from: '/p1199401729-remen-privodnoj-11h10h1120', to: 'https://agrosnabmarket.com.ua/products/65e33341286386ff5c3aafca' },
        { from: '/p1199421785-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65e33428286386ff5c3aafd6' },
        { from: '/p1199426532-remen-privodnoj-11h10h1250', to: 'https://agrosnabmarket.com.ua/products/65e336b9286386ff5c3aafe7' },
        { from: '/p1199434017-remen-privodnoj-11h10h1400', to: 'https://agrosnabmarket.com.ua/products/65e337b3286386ff5c3aafee' },
        { from: '/p1199433968-remen-privodnoj-11h10h900', to: 'https://agrosnabmarket.com.ua/products/65e33892286386ff5c3aaff8' },
        { from: '/p1199434281-remen-privodnoj-11h10h1600', to: 'https://agrosnabmarket.com.ua/products/65e33984286386ff5c3ab003' },
        { from: '/p1204046354-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65e33a26286386ff5c3ab008' },
        { from: '/p1540241770-remen-privodnoj-spa', to: 'https://agrosnabmarket.com.ua/products/65e33aef286386ff5c3ab00e' },
        { from: '/p1164549345-uplotnitelnye-koltsa-5h8h19', to: 'https://agrosnabmarket.com.ua/products/65a4e7517c3e73c3750c9c2c' },
        { from: '/p1166610870-uplotnitelnye-koltsa-6h8h14', to: 'https://agrosnabmarket.com.ua/products/65a4eab77c3e73c3750c9c96' },
        { from: '/p1166617872-uplotnitelnye-koltsa-6h9h19', to: 'https://agrosnabmarket.com.ua/products/65a4eb1c7c3e73c3750c9cb1' },
        { from: '/p1166619714-uplotnitelnye-koltsa-6h10h25', to: 'https://agrosnabmarket.com.ua/products/65a4eb6d7c3e73c3750c9cb3' },
        { from: '/p1167210456-uplotnitelnye-koltsa-6h12h31', to: 'https://agrosnabmarket.com.ua/products/65a4ebe67c3e73c3750c9cce' },
        { from: '/p1167211218-uplotnitelnye-koltsa-7h9h14', to: 'https://agrosnabmarket.com.ua/products/65a4ec837c3e73c3750c9cd0' },
        { from: '/p1167211807-uplotnitelnye-koltsa-7h10h19', to: 'https://agrosnabmarket.com.ua/products/65a4ed827c3e73c3750c9cd2' },
        { from: '/p1167533828-uplotnitelnye-koltsa-7h11h24', to: 'https://agrosnabmarket.com.ua/products/65a4fb737c3e73c3750c9ced' },
        { from: '/p1167533905-uplotnitelnye-koltsa-7h13h35', to: 'https://agrosnabmarket.com.ua/products/65a4fbfc7c3e73c3750c9cef' },
        { from: '/p1167533928-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a4fef27c3e73c3750c9d26' },
        { from: '/p1167792716-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a502b37c3e73c3750c9d46' },
        { from: '/p1167804958-uplotnitelnye-koltsa-8h12h25', to: 'https://agrosnabmarket.com.ua/products/65a57cc4685e240063985f86' },
        { from: '/p1167805304-uplotnitelnye-koltsa-8h14h35', to: 'https://agrosnabmarket.com.ua/products/65a57d4a685e240063985ff2' },
        { from: '/p1167805993-uplotnitelnye-koltsa-9h12h19', to: 'https://agrosnabmarket.com.ua/products/65a57dbe685e240063985ff4' },
        { from: '/p1167807388-uplotnitelnye-koltsa-9h14h31', to: 'https://agrosnabmarket.com.ua/products/65a61b10db49c75cec10be11' },
        { from: '/p1167809841-uplotnitelnye-koltsa-10h12h14', to: 'https://agrosnabmarket.com.ua/products/65a61b8bdb49c75cec10be19' },
        { from: '/p1167811628-uplotnitelnye-koltsa-10h16h35', to: 'https://agrosnabmarket.com.ua/products/65a61be7db49c75cec10be1b' },
        { from: '/p1167813563-uplotnitelnye-koltsa-11h13h15', to: 'https://agrosnabmarket.com.ua/products/65a61c44db49c75cec10be1d' },
        { from: '/p1167827555-uplotnitelnye-koltsa-11h14h19', to: 'https://agrosnabmarket.com.ua/products/65a61c98db49c75cec10be1f' },
        { from: '/p1167829619-uplotnitelnye-koltsa-11h15h24', to: 'https://agrosnabmarket.com.ua/products/65a61cf9db49c75cec10be21' },
        { from: '/p1167830334-uplotnitelnye-koltsa-11h16h31', to: 'https://agrosnabmarket.com.ua/products/65a61d61db49c75cec10be23' },
        { from: '/p1167830912-uplotnitelnye-koltsa-12h15h19', to: 'https://agrosnabmarket.com.ua/products/65a61dd5db49c75cec10be29' },
        { from: '/p1167831398-uplotnitelnye-koltsa-12h16h25', to: 'https://agrosnabmarket.com.ua/products/65a61e4edb49c75cec10be2e' },
        { from: '/p1167834533-uplotnitelnye-koltsa-12h17h3', to: 'https://agrosnabmarket.com.ua/products/65a61eafdb49c75cec10be30' },
        { from: '/p1167838935-uplotnitelnye-koltsa-13h16h19', to: 'https://agrosnabmarket.com.ua/products/65a61f29db49c75cec10be37' },
        { from: '/p1224168367-uplotnitelnye-koltsa-32x38x35', to: 'https://agrosnabmarket.com.ua/products/65a64984c1ca3ba42c6fb93e' },
        { from: '/p1168368816-uplotnitelnye-koltsa-16h22h35', to: 'https://agrosnabmarket.com.ua/products/65a64c34c1ca3ba42c6fb940' },
        { from: '/p1168389054-uplotnitelnye-koltsa-26h32h35', to: 'https://agrosnabmarket.com.ua/products/65a64d90c1ca3ba42c6fb942' },
        { from: '/p1169113150-uplotnitelnye-koltsa-21h25h24', to: 'https://agrosnabmarket.com.ua/products/65a65e7cc1ca3ba42c6fba27' },
        { from: '/p1168975996-uplotnitelnye-koltsa-22h28h35', to: 'https://agrosnabmarket.com.ua/products/65a66179c1ca3ba42c6fbab6' },
        { from: '/p1168084155-uplotnitelnye-koltsa-13h17h25', to: 'https://agrosnabmarket.com.ua/products/65a6c9119431f97952b7b1fd' },
        { from: '/p1168084185-uplotnitelnye-koltsa-13h18h3', to: 'https://agrosnabmarket.com.ua/products/65a6c9609431f97952b7b1ff' },
        { from: '/p1168084200-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a6c9b99431f97952b7b201' },
        { from: '/p1168084325-uplotnitelnye-koltsa-14h17h19', to: 'https://agrosnabmarket.com.ua/products/65a6ca0d9431f97952b7b203' },
        { from: '/p1168084334-uplotnitelnye-koltsa-14h18h25', to: 'https://agrosnabmarket.com.ua/products/65a6ca5a9431f97952b7b205' },
        { from: '/p1168084363-uplotnitelnye-koltsa-14h19h3', to: 'https://agrosnabmarket.com.ua/products/65a6cb029431f97952b7b213' },
        { from: '/p1168085223-uplotnitelnye-koltsa-14h20h35', to: 'https://agrosnabmarket.com.ua/products/65a6cb529431f97952b7b215' },
        { from: '/p1168085238-uplotnitelnye-koltsa-15h18h19', to: 'https://agrosnabmarket.com.ua/products/65a6cbe29431f97952b7b217' },
        { from: '/p1168363433-uplotnitelnye-koltsa-15h19h24', to: 'https://agrosnabmarket.com.ua/products/65a6cc489431f97952b7b219' },
        { from: '/p1168364501-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a6cca09431f97952b7b21b' },
        { from: '/p1168365997-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a6ccea9431f97952b7b21d' },
        { from: '/p1168366693-uplotnitelnye-koltsa-16h20h25', to: 'https://agrosnabmarket.com.ua/products/65a6cd449431f97952b7b21f' },
        { from: '/p1168367716-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a6cd8d9431f97952b7b221' },
        { from: '/p1168369746-uplotnitelnye-koltsa-17h22h3', to: 'https://agrosnabmarket.com.ua/products/65a6cddc9431f97952b7b223' },
        { from: '/p1168370529-uplotnitelnye-koltsa-29h35h35', to: 'https://agrosnabmarket.com.ua/products/65a6ce3e9431f97952b7b225' },
        { from: '/p1168372852-uplotnitelnye-koltsa-28h36h45', to: 'https://agrosnabmarket.com.ua/products/65a6cec59431f97952b7b22b' },
        { from: '/p1168377958-uplotnitelnye-koltsa-28h35h41', to: 'https://agrosnabmarket.com.ua/products/65a6cf099431f97952b7b22d' },
        { from: '/p1168381052-uplotnitelnye-koltsa-27h33h35', to: 'https://agrosnabmarket.com.ua/products/65a6cf7f9431f97952b7b22f' },
        { from: '/p1168382824-uplotnitelnye-koltsa-27h32h3', to: 'https://agrosnabmarket.com.ua/products/65a6cfed9431f97952b7b231' },
        { from: '/p1168384256-uplotnitelnye-koltsa-27h31h24', to: 'https://agrosnabmarket.com.ua/products/65a6d0339431f97952b7b233' },
        { from: '/p1168390213-uplotnitelnye-koltsa-26h31h31', to: 'https://agrosnabmarket.com.ua/products/65a6d0a59431f97952b7b235' },
        { from: '/p1168394062-uplotnitelnye-koltsa-25h32h41', to: 'https://agrosnabmarket.com.ua/products/65a6d1229431f97952b7b237' },
        { from: '/p1168395443-uplotnitelnye-koltsa-25h31h35', to: 'https://agrosnabmarket.com.ua/products/65a6d17d9431f97952b7b239' },
        { from: '/p1168399144-uplotnitelnye-koltsa-25h30h3', to: 'https://agrosnabmarket.com.ua/products/65a6d1c39431f97952b7b23b' },
        { from: '/p1168402724-uplotnitelnye-koltsa-25h29h24', to: 'https://agrosnabmarket.com.ua/products/65a6d20e9431f97952b7b23d' },
        { from: '/p1168406220-uplotnitelnye-koltsa-24h30h36', to: 'https://agrosnabmarket.com.ua/products/65a6d2c89431f97952b7b241' },
        { from: '/p1168407453-uplotnitelnye-koltsa-24h29h31', to: 'https://agrosnabmarket.com.ua/products/65a6d4979431f97952b7b247' },
        { from: '/p1168408830-uplotnitelnye-koltsa-24h28h24', to: 'https://agrosnabmarket.com.ua/products/65a6d5369431f97952b7b249' },
        { from: '/p1168409893-uplotnitelnye-koltsa-24h27h19', to: 'https://agrosnabmarket.com.ua/products/65a6d5e79431f97952b7b24e' },
        { from: '/p1168970062-uplotnitelnye-koltsa-23h28h31', to: 'https://agrosnabmarket.com.ua/products/65a6d70d9431f97952b7b253' },
        { from: '/p1168970786-uplotnitelnye-koltsa-23h27h25', to: 'https://agrosnabmarket.com.ua/products/65a6d7cd9431f97952b7b255' },
        { from: '/p1168978241-uplotnitelnye-koltsa-22h27h3', to: 'https://agrosnabmarket.com.ua/products/65a6d8299431f97952b7b257' },
        { from: '/p1168989559-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a6d8f39431f97952b7b259' },
        { from: '/p1168993549-uplotnitelnye-koltsa-22h25h19', to: 'https://agrosnabmarket.com.ua/products/65a6d97b9431f97952b7b25b' },
        { from: '/p1169115233-uplotnitelnye-koltsa-20h25h31', to: 'https://agrosnabmarket.com.ua/products/65a6dac79431f97952b7b25f' },
        { from: '/p1169115643-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a6dbd39431f97952b7b264' },
        { from: '/p1168996000-uplotnitelnye-koltsa-21h27h36', to: 'https://agrosnabmarket.com.ua/products/65a6d9d39431f97952b7b25d' },
        { from: '/p1169115863-uplotnitelnye-koltsa-20h23h19', to: 'https://agrosnabmarket.com.ua/products/65a6dc1f9431f97952b7b266' },
        { from: '/p1169116552-uplotnitelnye-koltsa-19h25h35', to: 'https://agrosnabmarket.com.ua/products/65a6dc809431f97952b7b268' },
        { from: '/p1169118382-uplotnitelnye-koltsa-19h23h24', to: 'https://agrosnabmarket.com.ua/products/65a6dcc89431f97952b7b26a' },
        { from: '/p1169125486-uplotnitelnye-koltsa-19h22h19', to: 'https://agrosnabmarket.com.ua/products/65a6dd2c9431f97952b7b26c' },
        { from: '/p1169280819-uplotnitelnye-koltsa-18h24h36', to: 'https://agrosnabmarket.com.ua/products/65a6ddbc9431f97952b7b26e' },
        { from: '/p1224169314-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a784f7863b701399b6a8c5' },
        { from: '/p1169603496-uplotnitelnye-koltsa-30h35h3', to: 'https://agrosnabmarket.com.ua/products/65a7b1fe863b701399b6aa12' },
        { from: '/p1169611930-uplotnitelnye-koltsa-30h34h24', to: 'https://agrosnabmarket.com.ua/products/65a7b250863b701399b6aa14' },
        { from: '/p1169675600-uplotnitelnye-koltsa-30h36h35', to: 'https://agrosnabmarket.com.ua/products/65a7b2b8863b701399b6aa16' },
        { from: '/p1169677970-uplotnitelnye-koltsa-30h38h46', to: 'https://agrosnabmarket.com.ua/products/65a7b2ff863b701399b6aa18' },
        { from: 'p1169690799-uplotnitelnye-koltsa-32h35h19', to: 'https://agrosnabmarket.com.ua/products/65a7b3c2863b701399b6aa1c' },
        { from: '/p1169722436-uplotnitelnye-koltsa-32h36h25', to: 'https://agrosnabmarket.com.ua/products/65a7b498863b701399b6aa22' },
        { from: '/p1169724633-uplotnitelnye-koltsa-32h37h3', to: 'https://agrosnabmarket.com.ua/products/65a7b4e6863b701399b6aa24' },
        { from: '/p1169725586-uplotnitelnye-koltsa-32h40h46', to: 'https://agrosnabmarket.com.ua/products/65a7b566863b701399b6aa26' },
        { from: '/p1169727100-uplotnitelnye-koltsa-33h36h19', to: 'https://agrosnabmarket.com.ua/products/65a7b666863b701399b6aa28' },
        { from: '/p1170442652-uplotnitelnye-koltsa-34h38h25', to: 'https://agrosnabmarket.com.ua/products/65a8023faf53a8ade598b755' },
        { from: '/p1170444380-uplotnitelnye-koltsa-34h39h31', to: 'https://agrosnabmarket.com.ua/products/65a8028daf53a8ade598b75a' },
        { from: '/p1170446175-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a802e0af53a8ade598b75c' },
        { from: '/p1170449680-uplotnitelnye-koltsa-35h40h3', to: 'https://agrosnabmarket.com.ua/products/65a80334af53a8ade598b75e' },
        { from: '/p1170450752-uplotnitelnye-koltsa-35h41h35', to: 'https://agrosnabmarket.com.ua/products/65a80372af53a8ade598b760' },
        { from: '/p1170459456-uplotnitelnye-koltsa-35h42h4', to: 'https://agrosnabmarket.com.ua/products/65a8135caf53a8ade598b762' },
        { from: '/p1170462630-uplotnitelnye-koltsa-35h43h45', to: 'https://agrosnabmarket.com.ua/products/65a813edaf53a8ade598b77c' },
        { from: '/p1170464696-uplotnitelnye-koltsa-35h45h57', to: 'https://agrosnabmarket.com.ua/products/65a8143baf53a8ade598b77e' },
        { from: '/p1170467564-uplotnitelnye-koltsa-36h39h19', to: 'https://agrosnabmarket.com.ua/products/65a8148faf53a8ade598b780' },
        { from: '/p1170470812-uplotnitelnye-koltsa-36h42h35', to: 'https://agrosnabmarket.com.ua/products/65a814daaf53a8ade598b782' },
        { from: '/p1170476158-uplotnitelnye-koltsa-36h44h46', to: 'https://agrosnabmarket.com.ua/products/65a81523af53a8ade598b784' },
        { from: '/p1170467564-uplotnitelnye-koltsa-36h39h19', to: 'https://agrosnabmarket.com.ua/products/65a8148faf53a8ade598b780' },
        { from: '/p1170470812-uplotnitelnye-koltsa-36h42h35', to: 'https://agrosnabmarket.com.ua/products/65a814daaf53a8ade598b782' },
        { from: '/p1170476158-uplotnitelnye-koltsa-36h44h46', to: 'https://agrosnabmarket.com.ua/products/65a81523af53a8ade598b784' },
        { from: '/p1170480426-uplotnitelnye-koltsa-37h40h19', to: 'https://agrosnabmarket.com.ua/products/65a81604af53a8ade598b786' },
        { from: '/p1170484250-uplotnitelnye-koltsa-37h41h25', to: 'https://agrosnabmarket.com.ua/products/65a81650af53a8ade598b788' },
        { from: '/p1170486129-uplotnitelnye-koltsa-37h42h31', to: 'https://agrosnabmarket.com.ua/products/65a81693af53a8ade598b78a' },
        { from: '/p1170489657-uplotnitelnye-koltsa-37h45h45', to: 'https://agrosnabmarket.com.ua/products/65a816ddaf53a8ade598b78c' },
        { from: '/p1170492252-uplotnitelnye-koltsa-38h42h25', to: 'https://agrosnabmarket.com.ua/products/65a81786af53a8ade598b78e' },
        { from: '/p1170495056-uplotnitelnye-koltsa-38h44h35', to: 'https://agrosnabmarket.com.ua/products/65a817c8af53a8ade598b790' },
        { from: '/p1170496808-uplotnitelnye-koltsa-38h46h45', to: 'https://agrosnabmarket.com.ua/products/65a81809af53a8ade598b792' },
        { from: '/p1170498416-uplotnitelnye-koltsa-kruglgo', to: 'https://agrosnabmarket.com.ua/products/65a81a06af53a8ade598b794' },
        { from: '/p1170508951-uplotnitelnye-koltsa-40h45h31', to: 'https://agrosnabmarket.com.ua/products/65a81bf0af53a8ade598b798' },
        { from: '/p1170511321-uplotnitelnye-koltsa-40h46h35', to: 'https://agrosnabmarket.com.ua/products/65a81c41af53a8ade598b79a' },
        { from: '/p1170514309-uplotnitelnye-koltsa-40h48h46', to: 'https://agrosnabmarket.com.ua/products/65a81c98af53a8ade598b79c' },
        { from: '/p1170517547-uplotnitelnye-koltsa-40h50h57', to: 'https://agrosnabmarket.com.ua/products/65a81ce0af53a8ade598b79e' },
        { from: '/p1170519594-uplotnitelnye-koltsa-41h45h24', to: 'https://agrosnabmarket.com.ua/products/65a81d34af53a8ade598b7a0' },
        { from: '/p1170536490-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a8b5849d8e562723081cff' },
        { from: '/p1170630019-uplotnitelnye-koltsa-42h48h35', to: 'https://agrosnabmarket.com.ua/products/65a8b6129d8e562723081d01' },
        { from: '/p1170631632-uplotnitelnye-koltsa-43h47h24', to: 'https://agrosnabmarket.com.ua/products/65a8b6789d8e562723081d03' },
        { from: '/p1170633503-uplotnitelnye-koltsa-59h65h35', to: 'https://agrosnabmarket.com.ua/products/65a8b6fb9d8e562723081d05' },
        { from: '/p1170634998-uplotnitelnye-koltsa-58h66h45', to: 'https://agrosnabmarket.com.ua/products/65a8b7b89d8e562723081d32' },
        { from: '/p1170637644-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a8b8109d8e562723081d34' },
        { from: '/p1170641220-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a8bb049d8e562723081d39' },
        { from: '/p1170645968-uplotnitelnye-koltsa-58h62h24', to: 'https://agrosnabmarket.com.ua/products/65a8bb6a9d8e562723081d3b' },
        { from: '/p1170648668-uplotnitelnye-koltsa-57h65h45', to: 'https://agrosnabmarket.com.ua/products/65a8bbf89d8e562723081d3d' },
        { from: '/p1170651315-uplotnitelnye-koltsa-57h63h35', to: 'https://agrosnabmarket.com.ua/products/65a8bc3f9d8e562723081d3f' },
        { from: '/p1170655827-uplotnitelnye-koltsa-56h66h57', to: 'https://agrosnabmarket.com.ua/products/65a8bca99d8e562723081d45' },
        { from: '/p1170657150-uplotnitelnye-koltsa-56h62h35', to: 'https://agrosnabmarket.com.ua/products/65a8bd209d8e562723081d47' },
        { from: '/p1170658492-uplotnitelnye-koltsa-56h61h31', to: 'https://agrosnabmarket.com.ua/products/65a8bd949d8e562723081d49' },
        { from: '/p1170659546-uplotnitelnye-koltsa-56h60h24', to: 'https://agrosnabmarket.com.ua/products/65a8be099d8e562723081d4e' },
        { from: '/p1170661385-uplotnitelnye-koltsa-54h62h45', to: 'https://agrosnabmarket.com.ua/products/65a8be739d8e562723081d50' },
        { from: '/p1170666401-uplotnitelnye-koltsa-54h60h36', to: 'https://agrosnabmarket.com.ua/products/65a8bece9d8e562723081d52' },
        { from: '/p1170725717-uplotnitelnye-koltsa-52h58h35', to: 'https://agrosnabmarket.com.ua/products/65a8bf269d8e562723081d54' },
        { from: '/p1170725955-uplotnitelnye-koltsa-52h56h24', to: 'https://agrosnabmarket.com.ua/products/65a8bf7c9d8e562723081d56' },
        { from: '/p1170726234-uplotnitelnye-koltsa-52h55h19', to: 'https://agrosnabmarket.com.ua/products/65a8bfd89d8e562723081d5b' },
        { from: '/p1170726736-uplotnitelnye-koltsa-51h56h31', to: 'https://agrosnabmarket.com.ua/products/65a8c0339d8e562723081d5d' },
        { from: '/p1170727592-uplotnitelnye-koltsa-50h60h57', to: 'https://agrosnabmarket.com.ua/products/65a8c0e29d8e562723081d63' },
        { from: '/p1170728461-uplotnitelnye-koltsa-50h60h5', to: 'https://agrosnabmarket.com.ua/products/65a8c2879d8e562723081d69' },
        { from: '/p1170729125-uplotnitelnye-koltsa-50h56h35', to: 'https://agrosnabmarket.com.ua/products/65a8c2df9d8e562723081d6b' },
        { from: '/p1170735516-uplotnitelnye-koltsa-49h55h35', to: 'https://agrosnabmarket.com.ua/products/65a8c4e69d8e562723081d6d' },
        { from: '/p1171440459-uplotnitelnye-koltsa-48h56h45', to: 'https://agrosnabmarket.com.ua/products/65a8c5729d8e562723081d6f' },
        { from: '/p1171479506-uplotnitelnye-koltsa-48h54h35', to: 'https://agrosnabmarket.com.ua/products/65a8c61a9d8e562723081d71' },
        { from: '/p1171484049-uplotnitelnye-koltsa-48h52h25', to: 'https://agrosnabmarket.com.ua/products/65a8c7fd9d8e562723081d75' },
        { from: '/p1171502757-uplotnitelnye-koltsa-47h55h45', to: 'https://agrosnabmarket.com.ua/products/65a8c8c29d8e562723081d77' },
        { from: '/p1171507482-uplotnitelnye-koltsa-47h52h31', to: 'https://agrosnabmarket.com.ua/products/65a8c9089d8e562723081d79' },
        { from: '/p1171726984-uplotnitelnye-koltsa-46h52h35', to: 'https://agrosnabmarket.com.ua/products/65a8c9559d8e562723081d7b' },
        { from: '/p1171822818-uplotnitelnye-koltsa-46h50h24', to: 'https://agrosnabmarket.com.ua/products/65a8c99e9d8e562723081d7d' },
        { from: '/p1171826888-uplotnitelnye-koltsa-45h55h5', to: 'https://agrosnabmarket.com.ua/products/65a8c9e99d8e562723081d7f' },
        { from: '/p1171830799-uplotnitelnye-koltsa-45h52h41', to: 'https://agrosnabmarket.com.ua/products/65a8ca339d8e562723081d81' },
        { from: '/p1171833803-uplotnitelnye-koltsa-45h51h35', to: 'https://agrosnabmarket.com.ua/products/65a8ca739d8e562723081d83' },
        { from: '/p1188288633-podshipnik-32220-7520', to: 'https://agrosnabmarket.com.ua/products/65eb3a248cb7ae189a4cbb7f' },
        { from: '/p1171838604-uplotnitelnye-koltsa-45h50h3', to: 'https://agrosnabmarket.com.ua/products/65a8cac19d8e562723081d85' },
        { from: '/p1171841599-uplotnitelnye-koltsa-45h50h24', to: 'https://agrosnabmarket.com.ua/products/65a8f59de25b8a4e2b166012' },
        { from: '/p1171844316-uplotnitelnye-koltsa-45h48h19', to: 'https://agrosnabmarket.com.ua/products/65a8f614e25b8a4e2b166014' },
        { from: '/p1171845938-uplotnitelnye-koltsa-44h52h45', to: 'https://agrosnabmarket.com.ua/products/65a8f735e25b8a4e2b16601e' },
        { from: '/p1171848538-uplotnitelnye-koltsa-44h50h35', to: 'https://agrosnabmarket.com.ua/products/65a8f8c1e25b8a4e2b166023' },
        { from: '/p1171849940-uplotnitelnye-koltsa-44h48h25', to: 'https://agrosnabmarket.com.ua/products/65a8f926e25b8a4e2b166025' },
        { from: '/p1171852037-uplotnitelnye-koltsa-60h64h24', to: 'https://agrosnabmarket.com.ua/products/65a8fb0ae25b8a4e2b166027' },
        { from: '/p1171853542-uplotnitelnye-koltsa-60h66h35', to: 'https://agrosnabmarket.com.ua/products/65a8fcade25b8a4e2b166029' },
        { from: '/p1171854265-uplotnitelnye-koltsa-60h68h45', to: 'https://agrosnabmarket.com.ua/products/65a8fd51e25b8a4e2b16602b' },
        { from: '/p1171854476-uplotnitelnye-koltsa-60h70h5', to: 'https://agrosnabmarket.com.ua/products/65a8fe00e25b8a4e2b16602d' },
        { from: '/p1171855215-uplotnitelnye-koltsa-60h70h57', to: 'https://agrosnabmarket.com.ua/products/65a8ff13e25b8a4e2b16602f' },
        { from: '/p1171856774-uplotnitelnye-koltsa-61h71h57', to: 'https://agrosnabmarket.com.ua/products/65a8ffc1e25b8a4e2b166031' },
        { from: '/p1171907173-uplotnitelnye-koltsa-62h66h24', to: 'https://agrosnabmarket.com.ua/products/65a90059e25b8a4e2b166033' },
        { from: '/p1171907463-uplotnitelnye-koltsa-62h68h36', to: 'https://agrosnabmarket.com.ua/products/65a90216e25b8a4e2b166035' },
        { from: '/p1171907959-uplotnitelnye-koltsa-62h70h4', to: 'https://agrosnabmarket.com.ua/products/65a9026fe25b8a4e2b166037' },
        { from: '/p1171908556-uplotnitelnye-koltsa-62h70h45', to: 'https://agrosnabmarket.com.ua/products/65a903dee25b8a4e2b166039' },
        { from: '/p1171920077-uplotnitelnye-koltsa-63h68h31', to: 'https://agrosnabmarket.com.ua/products/65a90473e25b8a4e2b16603b' },
        { from: '/p1171930687-uplotnitelnye-koltsa-63h69h35', to: 'https://agrosnabmarket.com.ua/products/65a9055be25b8a4e2b16603d' },
        { from: '/p1171930900-uplotnitelnye-koltsa-63h71h45', to: 'https://agrosnabmarket.com.ua/products/65a90604e25b8a4e2b16603f' },
        { from: '/p1171931290-uplotnitelnye-koltsa-63h73h57', to: 'https://agrosnabmarket.com.ua/products/65a9066ee25b8a4e2b166041' },
        { from: '/p1171932525-uplotnitelnye-koltsa-64h68h24', to: 'https://agrosnabmarket.com.ua/products/65a9076ee25b8a4e2b166043' },
        { from: '/p1171939017-uplotnitelnye-koltsa-64h70h35', to: 'https://agrosnabmarket.com.ua/products/65a9087de25b8a4e2b166049' },
        { from: '/p1171942819-uplotnitelnye-koltsa-65h70h24', to: 'https://agrosnabmarket.com.ua/products/65a908d3e25b8a4e2b16604b' },
        { from: '/p1171950788-uplotnitelnye-koltsa-65h70h3', to: 'https://agrosnabmarket.com.ua/products/65a90925e25b8a4e2b16604d' },
        { from: '/p1171953071-uplotnitelnye-koltsa-65h71h35', to: 'https://agrosnabmarket.com.ua/products/65a90976e25b8a4e2b16604f' },
        { from: '/p1171953222-uplotnitelnye-koltsa-65h73h45', to: 'https://agrosnabmarket.com.ua/products/65a909cfe25b8a4e2b166051' },
        { from: '/p1171954947-uplotnitelnye-koltsa-65h75h5', to: 'https://agrosnabmarket.com.ua/products/65a90a26e25b8a4e2b166053' },
        { from: '/p1171980810-uplotnitelnye-koltsa-66h71h3', to: 'https://agrosnabmarket.com.ua/products/65a90a78e25b8a4e2b166055' },
        { from: '/p1171982400-uplotnitelnye-koltsa-125h138h75', to: 'https://agrosnabmarket.com.ua/products/65a90b22e25b8a4e2b166057' },
        { from: '/p1171982539-uplotnitelnye-koltsa-125h135h58', to: 'https://agrosnabmarket.com.ua/products/65a92ec59b505070c5cf3f71' },
        { from: '/p1171983127-uplotnitelnye-koltsa-125h130h35', to: 'https://agrosnabmarket.com.ua/products/65a92f1a9b505070c5cf3f73' },
        { from: '/p1171983281-uplotnitelnye-koltsa-125h130h31', to: 'https://agrosnabmarket.com.ua/products/65a92f639b505070c5cf3f75' },
        { from: '/p1171983689-uplotnitelnye-koltsa-122h135h7', to: 'https://agrosnabmarket.com.ua/products/65a9302b9b505070c5cf3f77' },
        { from: '/p1171984099-uplotnitelnye-koltsa-120h130h57', to: 'https://agrosnabmarket.com.ua/products/65a9308f9b505070c5cf3f79' },
        { from: '/p1171986365-uplotnitelnye-koltsa-120h128h45', to: 'https://agrosnabmarket.com.ua/products/65a930e99b505070c5cf3f7b' },
        { from: '/p1171986704-uplotnitelnye-koltsa-120h126h35', to: 'https://agrosnabmarket.com.ua/products/65a9317f9b505070c5cf3f7d' },
        { from: '/p1171986797-uplotnitelnye-koltsa-118h128h57', to: 'https://agrosnabmarket.com.ua/products/65a932169b505070c5cf3f7f' },
        { from: '/p1171987190-uplotnitelnye-koltsa-118h125h45', to: 'https://agrosnabmarket.com.ua/products/65a932679b505070c5cf3f81' },
        { from: '/p1171988603-uplotnitelnye-koltsa-118h124h36', to: 'https://agrosnabmarket.com.ua/products/65a932be9b505070c5cf3f83' },
        { from: '/p1171988703-uplotnitelnye-koltsa-115h128h75', to: 'https://agrosnabmarket.com.ua/products/65a9336c9b505070c5cf3f85' },
        { from: '/p1171988888-uplotnitelnye-koltsa-115h121h35', to: 'https://agrosnabmarket.com.ua/products/65a933e39b505070c5cf3f8b' },
        { from: '/p1171989047-uplotnitelnye-koltsa-110h120h57', to: 'https://agrosnabmarket.com.ua/products/65a934429b505070c5cf3f8d' },
        { from: '/p1171989624-uplotnitelnye-koltsa-110h120h5', to: 'https://agrosnabmarket.com.ua/products/65a934829b505070c5cf3f8f' },
        { from: '/p1171989719-uplotnitelnye-koltsa-110h116h36', to: 'https://agrosnabmarket.com.ua/products/65a935099b505070c5cf3f91' },
        { from: '/p1171992407-uplotnitelnye-koltsa-110h115h31', to: 'https://agrosnabmarket.com.ua/products/65a936409b505070c5cf3f93' },
        { from: '/p1171994510-uplotnitelnye-koltsa-108h118h57', to: 'https://agrosnabmarket.com.ua/products/65a937ea9b505070c5cf3f95' },
        { from: '/p1172072013-uplotnitelnye-koltsa-108h114h35', to: 'https://agrosnabmarket.com.ua/products/65a938ab9b505070c5cf3f97' },
        { from: '/p1172072180-uplotnitelny-koltsa-105h115h57', to: 'https://agrosnabmarket.com.ua/products/65a939109b505070c5cf3f99' },
        { from: '/p1172072230-uplotnitelnye-koltsa-105h110h3', to: 'https://agrosnabmarket.com.ua/products/65a93adf9b505070c5cf3f9b' },
        { from: '/p1172072614-uplotnitelnye-koltsa-105h110h24', to: 'https://agrosnabmarket.com.ua/products/65a93b489b505070c5cf3f9d' },
        { from: '/p1172072674-uplotnitelnye-koltsa-104h110h35', to: 'https://agrosnabmarket.com.ua/products/65a951c0a1674f45036fd299' },
        { from: '/p1172072707-uplotnitelnye-koltsa-102h110h45', to: 'https://agrosnabmarket.com.ua/products/65a95214a1674f45036fd29b' },
        { from: '/p1172266692-uplotnitelnye-koltsa-102h108h35', to: 'https://agrosnabmarket.com.ua/products/65a953b0a1674f45036fd29d' },
        { from: '/p1172269125-uplotnitelnye-koltsa-102h108h31', to: 'https://agrosnabmarket.com.ua/products/65a953f8a1674f45036fd29f' },
        { from: '/p1172269450-uplotnitelnye-koltsa-102h108h24', to: 'https://agrosnabmarket.com.ua/products/65a95446a1674f45036fd2a1' },
        { from: '/p1172270084-uplotnitelnye-koltsa-195h200h35', to: 'https://agrosnabmarket.com.ua/products/65a954c5a1674f45036fd2a3' },
        { from: '/p1172288664-uplotnitelnye-koltsa-190h195h35', to: 'https://agrosnabmarket.com.ua/products/65a95515a1674f45036fd2a5' },
        { from: '/p1172291070-uplotnitelnye-koltsa-185h195h57', to: 'https://agrosnabmarket.com.ua/products/65a95581a1674f45036fd2a7' },
        { from: '/p1172291884-uplotnitelnye-koltsa-185h195h45', to: 'https://agrosnabmarket.com.ua/products/65a955eea1674f45036fd2a9' },
        { from: '/p1172292323-uplotnitelnye-koltsa-185h190h35', to: 'https://agrosnabmarket.com.ua/products/65a9563fa1674f45036fd2ab' },
        { from: '/p1172293064-uplotnitelnye-koltsa-180h185h35', to: 'https://agrosnabmarket.com.ua/products/65a956aba1674f45036fd2ad' },
        { from: '/p1172293868-uplotnitelnye-koltsa-175h190h85', to: 'https://agrosnabmarket.com.ua/products/65a9570ba1674f45036fd2af' },
        { from: '/p1172294833-uplotnitelnye-koltsa-175h185h45', to: 'https://agrosnabmarket.com.ua/products/65a95774a1674f45036fd2b1' },
        { from: '/p1172296562-uplotnitelnye-koltsa-170h185h85', to: 'https://agrosnabmarket.com.ua/products/65a957c6a1674f45036fd2b3' },
        { from: '/p1172298784-uplotnitelnye-koltsa-170h175h35', to: 'https://agrosnabmarket.com.ua/products/65a95815a1674f45036fd2b5' },
        { from: '/p1172299814-uplotnitelnye-koltsa-160h170h57', to: 'https://agrosnabmarket.com.ua/products/65a95890a1674f45036fd2b7' },
        { from: '/p1172301980-uplotnitelnye-koltsa-160h165h35', to: 'https://agrosnabmarket.com.ua/products/65a958fba1674f45036fd2bd' },
        { from: '/p1172306059-uplotnitelnye-koltsa-156h162h35', to: 'https://agrosnabmarket.com.ua/products/65a95950a1674f45036fd2bf' },
        { from: '/p1172308825-uplotnitelnye-koltsa-150h160h5', to: 'https://agrosnabmarket.com.ua/products/65a959baa1674f45036fd2c1' },
        { from: '/p1172312417-uplotnitelnye-koltsa-150h160h45', to: 'https://agrosnabmarket.com.ua/products/65a95a5da1674f45036fd2c3' },
        { from: '/p1172314308-uplotnitelnye-koltsa-kruglogo', to: 'https://agrosnabmarket.com.ua/products/65a95b08a1674f45036fd2c9' },
        { from: '/p1172321686-uplotnitelnye-koltsa-150h155h31', to: 'https://agrosnabmarket.com.ua/products/65a95b59a1674f45036fd2cb' },
        { from: '/p1172321834-uplotnitelnye-koltsa-145h155h57', to: 'https://agrosnabmarket.com.ua/products/65a95bc9a1674f45036fd2cd' },
        { from: '/p1172321993-uplotnitelnye-koltsa-145h150h31', to: 'https://agrosnabmarket.com.ua/products/65a95c23a1674f45036fd2cf' },
        { from: '/p1172322291-uplotnitelnye-koltsa-140h150h58', to: 'https://agrosnabmarket.com.ua/products/65a95c8aa1674f45036fd2d1' },
        { from: '/p1172323820-uplotnitelnye-koltsa-140h145h31', to: 'https://agrosnabmarket.com.ua/products/65a95cdda1674f45036fd2d3' },
        { from: '/p1172324090-uplotnitelnye-koltsa-135h145h57', to: 'https://agrosnabmarket.com.ua/products/65a95d3ea1674f45036fd2d5' },
        { from: '/p1172325754-uplotnitelnye-koltsa-135h140h35', to: 'https://agrosnabmarket.com.ua/products/65a95d99a1674f45036fd2d7' },
        { from: '/p1172327267-uplotnitelnye-koltsa-135h140h33', to: 'https://agrosnabmarket.com.ua/products/65a95e52a1674f45036fd2d9' },
        { from: '/p1172327895-uplotnitelnye-koltsa-135h140h31', to: 'https://agrosnabmarket.com.ua/products/65a95eb8a1674f45036fd2de' },
        { from: '/p1172328822-uplotnitelnye-koltsa-130h140h57', to: 'https://agrosnabmarket.com.ua/products/65a95f0aa1674f45036fd2e0' },
        { from: '/p1172330181-uplotnitelnye-koltsa-130h135h35', to: 'https://agrosnabmarket.com.ua/products/65a95f79a1674f45036fd2e2' },
        { from: '/p1172331425-uplotnitelnye-koltsa-130h135h31', to: 'https://agrosnabmarket.com.ua/products/65a95fbfa1674f45036fd2e4' },
        { from: '/p1172341618-uplotnitelnye-koltsa-200h205h35', to: 'https://agrosnabmarket.com.ua/products/65a9603ea1674f45036fd2e6' },
        { from: '/p1172342132-uplotnitelnye-koltsa-200h215h85', to: 'https://agrosnabmarket.com.ua/products/65a96096a1674f45036fd2e8' },
        { from: '/p1172343243-uplotnitelnye-koltsa-205h210h35', to: 'https://agrosnabmarket.com.ua/products/65a960e6a1674f45036fd2ea' },
        { from: '/p1172343971-uplotnitelnye-koltsa-235h245h57', to: 'https://agrosnabmarket.com.ua/products/65a9614ba1674f45036fd2ec' },
        { from: '/p1172344673-uplotnitelnye-koltsa-240h250h57', to: 'https://agrosnabmarket.com.ua/products/65a963b6a1674f45036fd2f5' },
        { from: '/p1172344896-uplotnitelnye-koltsa-245h255h57', to: 'https://agrosnabmarket.com.ua/products/65a96446a1674f45036fd2f7' },
        { from: '/p1172346250-uplotnitelnye-koltsa-250h260h45', to: 'https://agrosnabmarket.com.ua/products/65a964b3a1674f45036fd2f9' },
        { from: '/p1172346685-uplotnitelnye-koltsa-250h260h58', to: 'https://agrosnabmarket.com.ua/products/65a96548a1674f45036fd2fb' },
        { from: '/p1172346926-uplotnitelnye-koltsa-165h175h57', to: 'https://agrosnabmarket.com.ua/products/65a9662ea1674f45036fd2fd' },
        { from: '/p1172347294-uplotnitelnye-koltsa-182h190h46', to: 'https://agrosnabmarket.com.ua/products/65a966d6a1674f45036fd2ff' },
        { from: '/p1223803374-uplotnitelnye-koltsa-10x13x19', to: 'https://agrosnabmarket.com.ua/products/65a96740a1674f45036fd301' },
        { from: '/p1172349514-uplotnitelnye-koltsa-185h200h85', to: 'https://agrosnabmarket.com.ua/products/65a967e6a1674f45036fd303' },
        { from: '/p1223812906-uplotnitelnye-koltsa-9x13x25', to: 'https://agrosnabmarket.com.ua/products/65a96875a1674f45036fd305' },
        { from: '/p1223812956-uplotnitelnye-koltsa-17x20x19', to: 'https://agrosnabmarket.com.ua/products/65a968dfa1674f45036fd307' },
        { from: '/p1223861842-uplotnitelnye-koltsa-34x40x36', to: 'https://agrosnabmarket.com.ua/products/65a96961a1674f45036fd309' },
        { from: '/p1224168764-uplotnitelnye-koltsa-28x33x3', to: 'https://agrosnabmarket.com.ua/products/65a96a2ea1674f45036fd310' },
        { from: '/p1224168826-uplotnitelnye-koltsa-38h45h4', to: 'https://agrosnabmarket.com.ua/products/65a96b50a1674f45036fd315' },
        { from: '/p1224169386-uplotnitelnye-koltsa-10x14x25', to: 'https://agrosnabmarket.com.ua/products/65a96bd1a1674f45036fd317' },
        { from: '/p1279540011-uplotnitelnye-koltsa-120h125h25', to: 'https://agrosnabmarket.com.ua/products/65a96c46a1674f45036fd319' },
        { from: '/p1279554135-uplotnitelnye-koltsa-28h34h36', to: 'https://agrosnabmarket.com.ua/products/65a96daaa1674f45036fd31e' },
        { from: '/p1283915155-uplotnitelnye-koltsa-42h46h24', to: 'https://agrosnabmarket.com.ua/products/65a971c1a1674f45036fd323' },
        { from: '/p1293509751-uplotnitelnye-koltsa-180h195h85', to: 'https://agrosnabmarket.com.ua/products/65a9724aa1674f45036fd325' },
        { from: '/p1293573426-uplotnitelnye-koltsa-115h120h24', to: 'https://agrosnabmarket.com.ua/products/65a972e5a1674f45036fd327' },
        { from: '/p1293574877-uplotnitelnye-koltsa-115h120h31', to: 'https://agrosnabmarket.com.ua/products/65a97389a1674f45036fd32d' },
        { from: '/p1293579361-uplotnitelnye-koltsa-115h125h58', to: 'https://agrosnabmarket.com.ua/products/65a973eca1674f45036fd32f' },
        { from: '/p1213442178-remen-mnogoruchejnyj-ap1001571', to: 'https://agrosnabmarket.com.ua/products/65f32c160d35f5fe1cff7c40' },
        { from: '/p1459856737-remen-privodnoj-1420', to: 'https://agrosnabmarket.com.ua/products/65f91abe7390c6837da0d04f' },
        { from: '/p1401610602-remen-privodnoj-1520', to: 'https://agrosnabmarket.com.ua/products/659cf6404cfd6e0db770ff7d' },
        
        
        
         // Додайте інші умови перенаправлення
      ];

      redirects.forEach(redirect => {
         if (currentURL.endsWith(redirect.from)) {
            window.location.replace(redirect.to);
         }
      });
   }, []); 

  return null;
};
export default Redirect
