// UserPage.jsx

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, selectCurrentUser, selectError } from '../../features/user/UserSlice';
import { selectOrderLoading, selectOrderError, getAllOrdersForUser } from '../../features/api/orderSlice';
import { Link } from 'react-router-dom';
import OrderList from '../Cart/OrderList';

const UserPage = () => {
   const dispatch = useDispatch();
   const currentUser = useSelector(selectCurrentUser);
   const error = useSelector(selectError);
   const orderLoading = useSelector(selectOrderLoading);
   const orderError = useSelector(selectOrderError);

   useEffect(() => {
      const fetchData = async () => {
         if (!currentUser) {
            await dispatch(getUser())
               .then(() => {
                  // Використовуйте currentUser.orders
                  const userOrders = currentUser.orders;
                  dispatch(getAllOrdersForUser(userOrders));
               })
               .catch((err) => {
                  console.error('Error fetching user:', err);
               });
         }
      };

      fetchData();
   }, [currentUser, dispatch]);


   if (error) {
      return <div>Error: {error.message}</div>;
   }

   if (!currentUser) {
      return (
         <div>
            <p>You are not logged in. Please <a href="/login">login</a> or <a href="/register">register</a>.</p>
         </div>
      );
   }

   return (
      <div className='user'>
         <div className="user__container container">
            <img src={currentUser.avatarUrl} alt="Avatar" className='user__avatar' />
            <div className="user__content content-user">
               <div className="content-user__title">
                  <h1>Вітаємо, {currentUser.fullName}!</h1>
               </div>
               {orderLoading ? <p>Loading your orders...</p> : null}
               {orderError ? <p>Error loading your orders: {orderError}</p> : null}
               <OrderList />
            </div>


         </div>


      </div>
   );
};

export default UserPage;
