import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOnePost, selectSelectedPost } from '../../../features/categories/postsSlice';

const FullPost = () => {
   const { id } = useParams();
   const dispatch = useDispatch();
   const selectedPost = useSelector((state) => state.posts.list.find((post) => post._id === id));

   useEffect(() => {
      dispatch(getOnePost(id));
   }, [dispatch, id]);

   if (!selectedPost) {
      return <div>Loading...</div>;
   }

   return (
      <div className="full-post">
         <div className="full-post__container container">
            <div className="full-post__item post-full">
               <img src={selectedPost.imageUrl} alt={selectedPost.title} className='post-full__image' />
               <div className="post-full__content post-full-content">
                  <h2 className='post-full-content__title'>{selectedPost.title}</h2>
                  <p className='post-full-content__text'>{selectedPost.text}</p>
               </div>
               {/* Додайте інші поля поста, які вам потрібно відображати */}
            </div>
         </div>
      </div>
   );
}

export default FullPost;
