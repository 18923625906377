// Pagination.js
import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
   const pagesToShow = [];

   if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
         pagesToShow.push(i);
      }
   } else {
      const startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(totalPages, startPage + 2);

      if (startPage > 1) {
         pagesToShow.push(1, '...');
      }

      for (let i = startPage; i <= endPage; i++) {
         pagesToShow.push(i);
      }

      if (endPage < totalPages) {
         pagesToShow.push('...', totalPages);
      }
   }

   return (
      <div className="pagination">
         {pagesToShow.map((pageNumber, index) => (
            <button
               key={index}
               className={`button-page ${pageNumber === currentPage ? 'current-page' : ''}`}
               onClick={() => {
                  if (typeof pageNumber === 'number') {
                     onPageChange(pageNumber);
                  }
               }}
            >
               {pageNumber}
            </button>
         ))}
      </div>
   );
};

export default Pagination;
