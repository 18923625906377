export const ROUTES = {
   HOME: '/',
   CART: '/cart',
   COMPARE: '/compare',
   CATEGORY: '/categories/:id',
   PRODUCTS: '/:categoryId/:subcategoryId/products',
   BRANDS: '/brands/:id',
   CONTACTS: '/contacts',
   DELIVERY: '/delivery',
   ACTIONS: '/actions',
   ABOUT: '/about',
   POSTS: '/posts',
   COMMENTS: '/comments/all',
   CONFIDENTIONAL: '/confidentional',
   EXCHANGE: '/exchange',
   USER: '/auth/me',
   ALLPRODUCTS: '/products/:id'
}