import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Categories from '../Categories/Categories';
import { ROUTES } from '../../utils/routes';
import { Link } from 'react-router-dom';
import { useGetBrandsQuery } from '../../features/api/ApiSlice';

const Sidebar = ({
   showAutoProducts,
   setShowAutoProducts,
   showIndustrialProducts,
   setShowIndustrialProducts,
   showTechProducts,
   setShowTechProducts,
   showCombineProducts,
   setShowCombineProducts,
   showMotoTractorProducts,
   setShowMotoTractorProducts,
   showBalersProducts,
   setShowBalersProducts,
   showTractorProducts,
   setShowTractorProducts,
   showReapersProducts,
   setShowReapersProducts,
   showBikeProducts,
   setShowBikeProducts,
   showOtherTechnicProducts,
   setShowOtherTechnicProducts,
   setInternalSizeFilter,
   setExternalSizeFilter,
   setWidthFilter,
}) => {
   const [customSize, setCustomSize] = useState("");
   const [maxSize, setMaxSize] = useState("");
   const [customExternalSize, setCustomExternalSize] = useState("");
   const [maxExternalSize, setMaxExternalSize] = useState("");
   const [customWidth, setCustomWidth] = useState("");
   const [maxWidth, setMaxWidth] = useState("");
   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);



   const toggleMobileMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
   };


   const handleFilter = () => {
      // Встановлюємо межі фільтрації на безкінечність за замовчуванням
      let internalMin = -Infinity;
      let internalMax = Infinity;

      let externalMin = -Infinity;
      let externalMax = Infinity;

      let widthMin = -Infinity;
      let widthMax = Infinity;

      // Якщо користувач ввів значення для customSize, встановлюємо internalMin та internalMax
      if (customSize !== "") {
         const size = parseInt(customSize, 10);
         internalMin = size;
         internalMax = size;
      }

      // Якщо користувач ввів значення для maxSize, оновлюємо internalMax
      if (maxSize !== "") {
         internalMax = parseInt(maxSize, 10);
      }

      // Аналогічно для externalSize
      if (customExternalSize !== "") {
         const externalSize = parseInt(customExternalSize, 10);
         externalMin = externalSize;
         externalMax = externalSize;
      }

      if (maxExternalSize !== "") {
         externalMax = parseInt(maxExternalSize, 10);
      }

      // Аналогічно для width
      if (customWidth !== "") {
         const width = parseInt(customWidth, 10);
         widthMin = width;
         widthMax = width;
      }

      if (maxWidth !== "") {
         widthMax = parseInt(maxWidth, 10);
      }

      console.log("Internal Size Filter:", {
         min: internalMin,
         max: internalMax,
      });

      console.log("External Size Filter:", {
         min: externalMin,
         max: externalMax,
      });

      console.log("Width Filter:", {
         min: widthMin,
         max: widthMax,
      });

      setInternalSizeFilter({
         min: internalMin,
         max: internalMax,
      });

      setExternalSizeFilter({
         min: externalMin,
         max: externalMax,
      });

      setWidthFilter({
         min: widthMin,
         max: widthMax,
      });
      setIsMobileMenuOpen(false);
   };
   const { data: brands, isLoading } = useGetBrandsQuery();
   const [showAllBrands, setShowAllBrands] = useState(false);

   if (isLoading) {
      return <p>Loading brands...</p>;
   }

   const brandsToDisplay = showAllBrands ? brands : brands.slice(0, 8);


   // Вставте код для відображення списку брендів тут
   const brandList = (
      <ul className='brand-nav'>
         {brandsToDisplay.map((brand) => (
            <li key={brand._id} className='brands-list'>
               <Link to={`/brands/${brand._id}`} className='brands-link'>
                  <p>{brand.name}</p>
               </Link>
            </li>
         ))}
      </ul>
   );
   const showMoreButton = (
      <button onClick={() => setShowAllBrands(!showAllBrands)} className='sidebar-btn'>
         {showAllBrands ? "Сховати" : "Показати більше"}
      </button>
   );

   const handleCheckboxChange = (setter) => {
      setter((prevValue) => !prevValue);
   };

   return (
      <section className='sidebar'>
         <div className="sidebar-container container">
            <div className="size-filter">
               <h4>Фільтр за внутрішнім розміром:</h4>
               <input
                  type="text"
                  placeholder="Введіть точний розмір або мінімальний"
                  value={customSize}
                  onChange={(e) => setCustomSize(e.target.value)}
               />
               <input
                  type="text"
                  placeholder="Максимальний розмір"
                  value={maxSize}
                  onChange={(e) => setMaxSize(e.target.value)}
               />
            </div>

            <div className="size-filter">
               <h4>Фільтр за зовнішнім розміром:</h4>
               <input
                  type="text"
                  placeholder="Введіть точний розмір або мінімальний"
                  value={customExternalSize}
                  onChange={(e) => setCustomExternalSize(e.target.value)}
               />
               <input
                  type="text"
                  placeholder="Максимальний розмір"
                  value={maxExternalSize}
                  onChange={(e) => setMaxExternalSize(e.target.value)}
               />
            </div>

            <div className="size-filter">
               <h4>Фільтр за шириною:</h4>
               <input
                  type="text"
                  placeholder="Введіть точний розмір або мінімальний"
                  value={customWidth}
                  onChange={(e) => setCustomWidth(e.target.value)}
               />
               <input
                  type="text"
                  placeholder="Максимальний розмір"
                  value={maxWidth}
                  onChange={(e) => setMaxWidth(e.target.value)}
               />
            </div>
            <button onClick={handleFilter} className='btn-filter'>Відфільтрувати товари</button>
            <Categories isSidebar={true} />

            {/* Чекбокс для фільтрації за місцем використання - Авто */}
            <div className="place-of-use-filter">
               <h4>Фільтр за місцем використання:</h4>
               <label>
                  <input
                     type="checkbox"
                     checked={showAutoProducts}
                     onChange={() => handleCheckboxChange(setShowAutoProducts)}
                  />
                  Показувати тільки товари для авто
               </label>
            </div>
            <div className="place-of-use-filter">

               <label>
                  <input
                     type="checkbox"
                     checked={showIndustrialProducts}
                     onChange={() => handleCheckboxChange(setShowIndustrialProducts)}
                  />
                  Показувати тільки промислові товари
               </label>
            </div>
            <div className="place-of-use-filter">
               <label>
                  <input
                     type="checkbox"
                     checked={showTechProducts}
                     onChange={() => handleCheckboxChange(setShowTechProducts)}
                  />
                  Показувати тільки товари для техніки
               </label>
            </div>
            <h4>Фільтр за технікою:</h4>
            <div className="manufacturer-filter">
               <label>
                  <input
                     type="checkbox"
                     checked={showCombineProducts}
                     onChange={() => handleCheckboxChange(setShowCombineProducts)}
                  />
                  Комбайни
               </label>
            </div>
            <div className="manufacturer-filter">
               <label>
                  <input
                     type="checkbox"
                     checked={showMotoTractorProducts}
                     onChange={() => handleCheckboxChange(setShowMotoTractorProducts)}
                  />
                  Мотоблоки
               </label>
            </div>

            <div className="manufacturer-filter">
               <label>
                  <input
                     type="checkbox"
                     checked={showBalersProducts}
                     onChange={() => handleCheckboxChange(setShowBalersProducts)}
                  />
                  Прес-підбирачі
               </label>

            </div>
            <div className="manufacturer-filter">
               <label>
                  <input
                     type="checkbox"
                     checked={showTractorProducts}
                     onChange={() => handleCheckboxChange(setShowTractorProducts)}
                  />
                  Трактори
               </label>
            </div>
            <div className="manufacturer-filter">
               <label>
                  <input
                     type="checkbox"
                     checked={showReapersProducts}
                     onChange={() => handleCheckboxChange(setShowReapersProducts)}
                  />
                  Жатки
               </label>
            </div>
            <div className="manufacturer-filter">
               <label>
                  <input
                     type="checkbox"
                     checked={showBikeProducts}
                     onChange={() => handleCheckboxChange(setShowBikeProducts)}
                  />
                  Мотоцикли
               </label>
            </div>
            <div className="manufacturer-filter">
               <label>
                  <input
                     type="checkbox"
                     checked={showAutoProducts}
                     onChange={() => handleCheckboxChange(setShowAutoProducts)}
                  />
                  Авто
               </label>
            </div>
            <div className="manufacturer-filter">
               <label>
                  <input
                     type="checkbox"
                     checked={showOtherTechnicProducts}
                     onChange={() => handleCheckboxChange(setShowOtherTechnicProducts)}
                  />
                  Інша техніка
               </label>
            </div>
            <div className="brands-filter">
               <h4 className="brands-filter__title">Фільтр за брендами:</h4>
               {brandList}
               {brands.length > 8 && showMoreButton}
            </div>
            {/* Інші елементи сайдбару */}
            <Link to={ROUTES.ACTIONS}>Акції</Link>
            {/* Кнопка для перемикання мобільного меню */}

         </div>
         <button onClick={toggleMobileMenu} className='mobile-menu-btn'>
            {isMobileMenuOpen ? "Фільтри" : "Фільтри"}
         </button>

         {/* Вміст мобільного меню */}
         {isMobileMenuOpen && (
            <div className="mobile-menu-content">
               {/* ... Рендерінг вашого існуючого вмісту тут ... */}
               <div className="size-filter">
                  <h4>Фільтр за внутрішнім розміром:</h4>
                  <input
                     type="text"
                     placeholder="Введіть точний розмір або мінімальний"
                     value={customSize}
                     onChange={(e) => setCustomSize(e.target.value)}
                  />
                  <input
                     type="text"
                     placeholder="Максимальний розмір"
                     value={maxSize}
                     onChange={(e) => setMaxSize(e.target.value)}
                  />
               </div>

               <div className="size-filter">
                  <h4>Фільтр за зовнішнім розміром:</h4>
                  <input
                     type="text"
                     placeholder="Введіть точний розмір або мінімальний"
                     value={customExternalSize}
                     onChange={(e) => setCustomExternalSize(e.target.value)}
                  />
                  <input
                     type="text"
                     placeholder="Максимальний розмір"
                     value={maxExternalSize}
                     onChange={(e) => setMaxExternalSize(e.target.value)}
                  />
               </div>

               <div className="size-filter">
                  <h4>Фільтр за шириною:</h4>
                  <input
                     type="text"
                     placeholder="Введіть точний розмір або мінімальний"
                     value={customWidth}
                     onChange={(e) => setCustomWidth(e.target.value)}
                  />
                  <input
                     type="text"
                     placeholder="Максимальний розмір"
                     value={maxWidth}
                     onChange={(e) => setMaxWidth(e.target.value)}
                  />
               </div>
               <button onClick={handleFilter} className='btn-filter'>Відфільтрувати товари</button>
               <Categories isSidebar={true} />

               {/* Чекбокс для фільтрації за місцем використання - Авто */}
               <div className="place-of-use-filter">
                  <h4>Фільтр за місцем використання:</h4>
                  <label>
                     <input
                        type="checkbox"
                        checked={showAutoProducts}
                        onChange={() => handleCheckboxChange(setShowAutoProducts)}
                     />
                     Показувати тільки товари для авто
                  </label>
               </div>
               <div className="place-of-use-filter">

                  <label>
                     <input
                        type="checkbox"
                        checked={showIndustrialProducts}
                        onChange={() => handleCheckboxChange(setShowIndustrialProducts)}
                     />
                     Показувати тільки промислові товари
                  </label>
               </div>
               <div className="place-of-use-filter">
                  <label>
                     <input
                        type="checkbox"
                        checked={showTechProducts}
                        onChange={() => handleCheckboxChange(setShowTechProducts)}
                     />
                     Показувати тільки товари для техніки
                  </label>
               </div>
               <h4>Фільтр за технікою:</h4>
               <div className="manufacturer-filter">
                  <label>
                     <input
                        type="checkbox"
                        checked={showCombineProducts}
                        onChange={() => handleCheckboxChange(setShowCombineProducts)}
                     />
                     Комбайни
                  </label>
               </div>
               <div className="manufacturer-filter">
                  <label>
                     <input
                        type="checkbox"
                        checked={showMotoTractorProducts}
                        onChange={() => handleCheckboxChange(setShowMotoTractorProducts)}
                     />
                     Мотоблоки
                  </label>
               </div>

               <div className="manufacturer-filter">
                  <label>
                     <input
                        type="checkbox"
                        checked={showBalersProducts}
                        onChange={() => handleCheckboxChange(setShowBalersProducts)}
                     />
                     Прес-підбирачі
                  </label>

               </div>
               <div className="manufacturer-filter">
                  <label>
                     <input
                        type="checkbox"
                        checked={showTractorProducts}
                        onChange={() => handleCheckboxChange(setShowTractorProducts)}
                     />
                     Трактори
                  </label>
               </div>
               <div className="manufacturer-filter">
                  <label>
                     <input
                        type="checkbox"
                        checked={showReapersProducts}
                        onChange={() => handleCheckboxChange(setShowReapersProducts)}
                     />
                     Жатки
                  </label>
               </div>
               <div className="manufacturer-filter">
                  <label>
                     <input
                        type="checkbox"
                        checked={showBikeProducts}
                        onChange={() => handleCheckboxChange(setShowBikeProducts)}
                     />
                     Мотоцикли
                  </label>
               </div>
               <div className="manufacturer-filter">
                  <label>
                     <input
                        type="checkbox"
                        checked={showAutoProducts}
                        onChange={() => handleCheckboxChange(setShowAutoProducts)}
                     />
                     Авто
                  </label>
               </div>
               <div className="manufacturer-filter">
                  <label>
                     <input
                        type="checkbox"
                        checked={showOtherTechnicProducts}
                        onChange={() => handleCheckboxChange(setShowOtherTechnicProducts)}
                     />
                     Інша техніка
                  </label>
               </div>
               <div className="brands-filter">
                  <h4 className="brands-filter__title">Фільтр за брендами:</h4>
                  {brandList}
                  {brands.length > 8 && showMoreButton}
               </div>
               {/* Інші елементи сайдбару */}
               <Link to={ROUTES.ACTIONS}>Акції</Link>
            </div>
         )}
      </section>
   );
};

export default Sidebar;
