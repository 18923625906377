import React from 'react';

const Exchange = () => {
   return (
      <div className='exchange'>
         <div className="exchange__container container">
            <p><strong>На товари, придбані на сайті Агроснабмаркет, поширюється дія закону України “Про захист прав споживачів”.</strong></p>

            <p>Щоб запобігти непорозумінню, просимо Вас звернути увагу на вказаний нижче порядок здійснення процедури обміну або повернення товарів.</p>

            <p><strong>Покупець має право на повернення або обмін придбаного товару:</strong></p>
            <ol>
               <li>Товар неналежної якості: несправний, має істотні недоліки, що унеможливлюють його використання (провина компанії-виробника або компанії-постачальника);</li>
               <li>Товар належної якості, але з якихось причин не підійшов покупцю, при цьому товар не був у використанні, збережена цілісність упаковки (пломби, тощо) та розрахунковий документ.</li>
            </ol>

            <p>В магазині представлені різні групи товарів, які згідно законодавства - підпадають під обмін та повернення чи, навпаки, не підлягають обміну чи поверненню в зв'язку з такою групою.</p>

            <p><strong>Обмін товару в зв'язку з його не кондицією, псуванням відбувається безкоштовно за рахунок відправника за наявності "Акту" про такий випадок від пошти, який отримувач просить скласти співробітника поштової служби в момент виявлення такого випадку.</strong></p>

            <p><strong>Повернення товару (в зв'язку з особистими причинами покупця після "оформлення" - це і є фактично момент підтвердження та надання згоди, прояв бажання на придбання товару або під час телефонної розмови з менеджером і підтвердження, оформлення замовлення) - відбувається за рахунок покупця.</strong></p>

            <p><strong>Адреса для повернення та обміну товару:</strong></p>
            <ol>
               <li>Надішліть нам товар "Новою поштою" м. Олександрія, Нова Пошта, Поштове відділення №7 (до 30 кг)  Соборний проспект, 4</li>
               <li>Нечитайло Володимир Миколайович</li>
               <li>Вкажіть оціночну вартість, згідно ціни товару</li>
               <li>Послуги «Зворотна доставка» та «Післясплата» потрібно скасувати.</li>
            </ol>

            <p><strong>Повернення коштів за товар:</strong></p>
            <ol>
               <li>На картку (номер картки та ПІБ власника банківської картки)</li>
               <li>Кошти повертаються на вказану Вами карту протягом 2-7 днів після отримання і огляду нами Вашого повернення.</li>
            </ol>

            <p><strong>Товари належної якості</strong></p>

            <p>До товарів належної якості відносяться всі товари, які: не були у використанні, а також збережені їх товарний вигляд. Поверненню підлягає тільки новий товар, який не має слідів використання і не перебував в експлуатації, за умови:</p>

            <ul>
               <li>збереження його товарного вигляду і споживчих властивостей;</li>
               <li>відсутність слідів використання і забруднень;</li>
               <li>збереження пломб, ярликів, бирок, захисних плівок;</li>
               <li>збереження заводського маркування;</li>
               <li>цілісність, неушкодженість,</li>
               <li>збереження всіх частин індивідуальної (заводської) упаковки;</li>
            </ul>

            <p><strong>Хто сплачує за пересилку товара належної і неналежної якості?</strong></p>

            <p>При поверненні чи обміні товару належної якості ми не відшкодовуємо витрат, пов'язаних із вартістю послуг з доставки товару. У разі відмови від товару вартість послуги з доставки оплачується покупцем.</p>

            <p>При поверненні чи обміні товару неналежної якості ми відшкодовуємо всі витрати, пов'язаних із вартістю послуг з доставки товару, а саме: виїзд кур'єра або вартість пересилання транспортною компанією до іншого міста, згідно з декларацією (ТТН). У разі відмови від товару вартість послуги з доставки оплачується покупцем!</p>

            <p><strong>Повернення коштів</strong></p>

            <p>Після отримання та огляду товару, протягом 5 робочих днів кошти буде повернуто на реквізити з яких здійснювалася оплата.</p>

            <p><strong>Будь які питання стосовно обміну та повернення товару можливо врегулювати шляхом перемовин між покупцем та адміністрацією магазину. Для цього потрібно просто зв'язатися з менеджером будь-яким зручним для вас способом.</strong></p>

            <p><strong>Всі відносини регулюються згідно Закону України «Про захист прав споживачів».</strong></p>
         </div>
      </div>
   );
}

export default Exchange;
