import React from 'react'
import { Link } from 'react-router-dom'
import Phone from '../../../images/other/Phone.svg'
import Local from '../../../images/other/local.svg'
import Mail from '../../../images/other/mail.svg'
const Contacts = () => {
   return (
      <div class='contacts-container container'>
         <div class='pages-title'><h1>Контакти</h1></div>
         <div class='contacts-body'>
            <div class='contacts-body__content content-contacts'>
               <div class='content-contacts__phone phone-contacts'>
                  <div class='phone-contacts__icon'>
                     <img src={Phone} alt="Phone" />
                  </div>
                  <div class='phone-contacts__info'>
                     <div class='phone-contacts__title'>
                        Відділ продажів:
                     </div>
                     <Link to='tel: +380506726443' class='phone-contacts__number'>
                        +38(050)672-64-43
                     </Link>
                  </div>
                  <div class='phone-contacts__info'>
                     <div class='phone-contacts__title'>
                        Технічний відділ
                        (підбір запчастин)
                     </div>
                     <Link to='tel: +380970699023' class='phone-contacts__number'>
                        +38(097)069-90-23
                     </Link>
                  </div>
               </div>
               <div class='content-contacts__location location-contacts'>
                  <div class='location-contacts__icon'>
                     <img src={Local} alt="Phone" />
                  </div>
                  <div className="location-contacts__info">
                     <div class='location-contacts__map'>Кіровоградська обл, м.Олександрія, вул. Козацький шлях 89</div>
                     <div class='location-contacts__time'>Пн-Сб   9:00 - 18:00</div>
                  </div>
               </div>
               <div class='content-contacts__mail mail-contacts'>
                  <div class='mail-contacts__icon'>
                     <img src={Mail} alt="Phone" />
                  </div>
                  <div class='mail-contacts__info'>
                     <div class='mail-contacts__title'>
                        По всім питанням:
                     </div>
                     <div class='mail-contacts__text'>
                        agrosnabmarket@gmail.com
                     </div>
                  </div>
                  <div class='mail-contacts__info'>
                     <div class='mail-contacts__title'>
                        Для співпраці:
                     </div>
                     <div class='mail-contacts__text'>
                        agrosnabmarket@gmail.com
                     </div>
                  </div>
               </div>
            </div>
            <div class='contacts-body__map'>
               <iframe title='map' className='map-footer' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d854.3353151328367!2d33.083359533045545!3d48.66474991213199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d09d3a8d417e0f%3A0xd6a5d55a3490acf7!2z0JDQs9GA0L7RgdC90LDQsQ!5e0!3m2!1sru!2sua!4v1699333950711!5m2!1sru!2sua" width="570" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
         </div>
      </div>
   )
}

export default Contacts
