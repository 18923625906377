import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetBrandsQuery } from '../../features/api/ApiSlice';
import { Link } from 'react-router-dom';

const Brands = ({ showAll }) => {

   const dispatch = useDispatch();
   const { data: brands, isLoading } = useGetBrandsQuery();
   const [showAllBrands, setShowAllBrands] = useState(false);

   useEffect(() => {
      // Возможно, вам нужно добавить дополнительный код для обработки данных брендов, например, сохранения их в хранилище или вывода на экран.
   }, [brands, dispatch]);

   if (isLoading) {
      return <p>Loading brands...</p>;
   }

   const brandsToDisplay = showAll ? brands : brands.slice(0, 8);

   return (
      <div className="brand-container container">
         <div className="brand-header">
            <h2 className='brand-title'>Представлені виробники</h2>
            <Link to="/brands" onClick={() => setShowAllBrands(true)} className='brand-link'>
               Дивитись всіх
            </Link>
         </div>
         <ul className='brand-nav'>
            {brandsToDisplay.map((brand) => (
               <li key={brand._id} className='brands-list'>
                  <Link to={`/brands/${brand._id}`}>
                     <img src={brand.image} alt={`Brand: ${brand.name}`} classname='brand-image' />

                  </Link>
               </li>
            ))}
         </ul>
      </div>
   );
};

export default Brands;
