import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from "react-router-dom"
import './styles/styles.scss'
import App from './components/App/App'
import { Provider } from 'react-redux'
import { store } from './features/store'




createRoot(document.getElementById("root")).render(
   <Provider store={store}>
      <BrowserRouter><App /></BrowserRouter>
   </Provider>)