import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getSubcategories, selectSubcategories } from '../../features/categories/subCategoriesSlice';

const Subcategories = ({ categoryId, style }) => {
   const dispatch = useDispatch();
   const subcategories = useSelector(selectSubcategories);

   useEffect(() => {
      dispatch(getSubcategories(categoryId));
   }, [dispatch, categoryId]);

   console.log('Subcategories in component:', subcategories);

   if (!subcategories || subcategories.length === 0) {
      return <p>Loading subcategories...</p>;
   }

   const filteredSubcategories = subcategories.filter(subcategory => subcategory.category === categoryId);

   if (!filteredSubcategories || filteredSubcategories.length === 0) {
      return null; // Вместо <p>No subcategories available</p>
   }

   return (
      <ul className="subcategories-list">
         {filteredSubcategories.map((subcategory) => (
            <li key={subcategory._id} className="subcategories-item">
               <NavLink to={`/${categoryId}/${subcategory._id}/products`} className='subcategory-title'>
                  <p className='subcategories-item__title'>{subcategory.title}</p>
               </NavLink>
            </li>
         ))}
      </ul>
   );
};

export default Subcategories;