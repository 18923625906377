import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Categories from '../../Categories/Categories';
import Brands from '../../Brands/Brands';
import { Link, useNavigate } from 'react-router-dom';
import Actions from '../../Products/Actions';
import { ROUTES } from '../../../utils/routes';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../../node_modules/swiper/swiper-bundle.min.css';
import SearchModel from '../../../images/home/search.png'
import PersonalModel from '../../../images/home/personal.png'
import SwiperComponent from '../../Swiper/Swiper';


const Home = () => {
   const [showAllBrands, setShowAllBrands] = useState(false);
   const [showAllActions, setShowAllActions] = useState(false);
   const [random, setRandom] = useState(5);
   const navigate = useNavigate();
   const [showAutoProducts, setShowAutoProducts] = useState(false);

   const swiperItems = [
      { title: 'Запчастини для авто', description: 'Виберіть запчастини для Вашого автомобіля', backgroundImage: 'url("/images/home/auto.png")', alt: 'Alt for Slide 1', searchParam: 'showAutoProducts=true' },
      { title: 'Запчастини для агро техніки', description: 'Виберіть запчастини для Вашої техніки', backgroundImage: 'url("/images/home/fermer.png")', alt: 'Alt for Slide 2', searchParam: 'showTechProducts=true' },
      { title: 'Запчастини для промисловості', description: 'Виберіть запчастини для Вашого підприємства', backgroundImage: 'url("/images/home/factory.png")', alt: 'Alt for Slide 3', searchParam: 'showIndustrialProducts=true' },
   ];



   // Додайте функцію для переходу
   const navigateWithFilters = (filters) => {
      const queryParams = new URLSearchParams(filters);
      navigate(`${ROUTES.PRODUCTS}?${queryParams}`);
   };

   return (


      <div className='home'>
         <div className="home-container container" >
            <div className="swiper-container container">
               <SwiperComponent items={swiperItems} />
            </div>
            <Categories isSidebar={false} />
            <div className="home-filter">
               <Link
                  to={{
                     pathname: '/655ddaafbd342d93bef0dd16/655f50f5817af6082789b044/products',
                     search: '?showAutoProducts=true',
                  }}
                  className="home-filter__item item-filter auto"
               >
                  <h4 className='item-filter__title'>ЗАПЧАСТИНИ ДЛЯ АВТОМОБІЛЕЙ</h4>
               </Link>

               <Link
                  to={{
                     pathname: '/655ddaafbd342d93bef0dd16/655f50f5817af6082789b044/products', // Замініть це на відповідний шлях
                     search: '?showTechProducts=true',
                  }}
                  className="home-filter__item item-filter fermer"
               >
                  <h4 className='item-filter__title'>ЗАПЧАСТИНИ ДЛЯ ТЕХНІКИ</h4>
               </Link>
               <Link
                  to={{
                     pathname: '/655ddaafbd342d93bef0dd16/655f50f5817af6082789b044/products', // Замініть це на відповідний шлях
                     search: '?showIndustrialProducts=true',
                  }}
                  className="home-filter__item item-filter factory"
               >
                  <h4 className='item-filter__title'>ЗАПЧАСТИНИ ДЛЯ ПРОМИСЛОВОСТІ</h4>
               </Link>
            </div>
            <Actions random={random} />
            <div className="home-advertisment">
               <Link>
                  <img src={SearchModel} alt="SearchModel" />
               </Link>
               <Link>
                  <img src={PersonalModel} alt="PersonalModel" />
               </Link>
            </div>
            <Brands showAll={showAllBrands} />

         </div>

      </div>
   );
};

export default Home;
