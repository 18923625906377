import React from 'react'
import Categories from '../../Categories/Categories';

const Catalog = () => {
   return (

      <div className="floating-catalog">
         <h4 className='floating-catalog__title'>Каталог</h4>
         <Categories isSidebar={true} />
      </div>
   )
}

export default Catalog
