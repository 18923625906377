import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constants";

export const getPosts = createAsyncThunk(
   'posts/getPosts',
   async (_, thunkAPI) => {
      try {
         const res = await axios.get(`${BASE_URL}/posts`);
         return res.data;
      } catch (err) {
         console.error(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

export const getOnePost = createAsyncThunk(
   'posts/getOnePost',
   async (postId, thunkAPI) => {
      try {
         const res = await axios.get(`${BASE_URL}/posts/${postId}`);
         return res.data;
      } catch (err) {
         console.error(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

export const updatePost = createAsyncThunk(
   'posts/updatePost',
   async ({ postId, updatedData }, thunkAPI) => {
      try {
         const res = await axios.put(`${BASE_URL}/posts/${postId}`, updatedData);
         return res.data;
      } catch (err) {
         console.error(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

export const removePost = createAsyncThunk(
   'posts/removePost',
   async (postId, thunkAPI) => {
      try {
         const res = await axios.delete(`${BASE_URL}/posts/${postId}`);
         return res.data;
      } catch (err) {
         console.error(err);
         return thunkAPI.rejectWithValue(err);
      }
   }
);

const postsSlice = createSlice({
   name: 'posts',
   initialState: {
      list: [],
      selectedPost: null,
      isLoading: false,
      isError: false,
   },
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(getPosts.pending, (state) => {
         state.isLoading = true;
         state.isError = false;
      });
      builder.addCase(getPosts.fulfilled, (state, { payload }) => {
         if (Array.isArray(payload)) {
            state.list = payload;
            state.isLoading = false;
         } else {
            console.error('Invalid payload structure.');
            state.isLoading = false;
            state.isError = true;
         }
      });
      builder.addCase(getPosts.rejected, (state, action) => {
         console.error('Get posts rejected:', action.error);
         state.isLoading = false;
         state.isError = true;
      });

      builder.addCase(getOnePost.pending, (state) => {
         state.isLoading = true;
         state.isError = false;
      });
      builder.addCase(getOnePost.fulfilled, (state, { payload }) => {
         state.selectedPost = payload;
         state.isLoading = false;
      });
      builder.addCase(getOnePost.rejected, (state, action) => {
         console.error('Get one post rejected:', action.error);
         state.isLoading = false;
         state.isError = true;
      });

      builder.addCase(updatePost.pending, (state) => {
         state.isLoading = true;
         state.isError = false;
      });
      builder.addCase(updatePost.fulfilled, (state, { payload }) => {
         // Оновіть стан за необхідністю
         const updatedList = state.list.map(post => {
            if (post._id === payload._id) {
               // Перевірте, чи payload.viewsCount існує перед оновленням
               if (payload.viewsCount !== undefined) {
                  return {
                     ...post,
                     viewsCount: payload.viewsCount,
                  };
               }
            }
            return post;
         });

         state.list = updatedList;
         state.isLoading = false;
      });
      builder.addCase(updatePost.rejected, (state, action) => {
         console.error('Update post rejected:', action.error);
         state.isLoading = false;
         state.isError = true;
      });

      builder.addCase(removePost.pending, (state) => {
         state.isLoading = true;
         state.isError = false;
      });
      builder.addCase(removePost.fulfilled, (state, { payload }) => {
         if (payload.success) {
            state.list = state.list.filter(post => post._id !== payload.removedPostId);
         }
         state.isLoading = false;
      });
      builder.addCase(removePost.rejected, (state, action) => {
         console.error('Remove post rejected:', action.error);
         state.isLoading = false;
         state.isError = true;
      });
   },
});

export default postsSlice.reducer;
export const selectPosts = (state) => state.posts;
export const selectSelectedPost = (state) => state.posts.selectedPost;
