import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductById, selectProduct, addToCart } from '../../features/categories/productSlice';
import { useParams } from 'react-router-dom';
import Eyes from '../../images/other/eyes.svg'
import { getSimilarProductsByTitle, selectSimilarProducts } from '../../features/categories/subCategoriesSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getComments } from '../../features/categories/CommentsSlice';
import { getCommentsForProduct, addCommentForProduct, selectCommentsForProduct } from '../../features/categories/commentsForProductSlice';
import Cart from '../../images/headers/cart.svg';
import { Link } from 'react-router-dom';
import Delivery from '../Pages/Other/Delivery';
import Comments from '../Pages/Other/Comments';
import { updateProductRating } from '../../features/categories/productSlice';
import { Helmet } from 'react-helmet';

const ProductDetails = ({ currentPage }) => {
   const { categoryId, subcategoryId, productId } = useParams();
   const dispatch = useDispatch();
   const productState = useSelector(selectProduct);
   const [activeTab, setActiveTab] = useState('description'); // Додайте локальний стан
   const [similarProductsLoading, setSimilarProductsLoading] = useState(false);
   const similarProducts = useSelector(selectSimilarProducts);
   const [comments, setComments] = useState([]);
   const [commentsLoading, setCommentsLoading] = useState(false);
   const allComments = useSelector((state) => state.comments.list);
   const [commentText, setCommentText] = useState('');
   const commentsForProduct = useSelector(selectCommentsForProduct);
   const [isHoveredStar, setIsHoveredStar] = useState(null);


   useEffect(() => {
      dispatch(getCommentsForProduct({ categoryId, subcategoryId, productId }));
   }, [dispatch, categoryId, subcategoryId, productId]);

   const handleCommentSubmit = () => {
      if (commentText.trim() !== '') {
         dispatch(addCommentForProduct({ categoryId, subcategoryId, productId, comment: { text: commentText } }));
         setCommentText('');
      } else {
         toast.error('Пустий коментар! Введіть текст коментаря.');
      }
   };
   const handleRatingClick = (newRating) => {
      dispatch(
         updateProductRating({
            categoryId,
            subcategoryId,
            productId,
            newRating,
         })
      );
   };


   const handleAddToCart = (product) => {
      dispatch(addToCart({
         productId: data._id,
         title: data.title,
         brands: data.brands, // Передайте ідентифікатори брендів, якщо це є в даних
         price: data.price,
         quantity: 1, // Наприклад, додаємо одиницю товару
         imageUrl: data.imageUrl,
         article: data.article,
      })); // Викликайте дію addToCart для додавання товару до кошика
      toast.success(`Товар "${product.title}" додано до кошика!`); // Виводимо повідомлення про успішне додавання

   };

   const handleAnalogButtonClick = () => {
      setActiveTab('analog');
      setSimilarProductsLoading(true);
      dispatch(getSimilarProductsByTitle(data?.title)); // Викликайте дію для отримання схожих товарів
   };

   useEffect(() => {
      setSimilarProductsLoading(false);
   }, [similarProducts]);

   useEffect(() => {
      dispatch(getProductById({ categoryId, subcategoryId, productId }));
   }, [dispatch, categoryId, subcategoryId, productId]);

   const { data, product, isLoading, isError } = productState;
   // Логіка для зміни активного блоку
   const handleTabClick = (tab) => {
      setActiveTab(tab);

      // Отримання всіх відгуків при натисканні на вкладку "Відгуки"
      if (tab === 'comment' && !allComments.length) {
         setCommentsLoading(true);
         dispatch(getComments())
            .unwrap()
            .then((data) => {
               setCommentsLoading(false);
            })
            .catch((error) => {
               console.error('Error fetching comments:', error);
               setCommentsLoading(false);
            });
      }
   };

   const isCharacterEqual = (product1, product2) => {
      return (
         product1.Type === product2.Type &&
         product1.internalSize === product2.internalSize &&
         product1.externalSize === product2.externalSize &&
         product1.width === product2.width
      );
   };
   const filteredComments = allComments.filter((comment) => comment.productId === data?._id);

   if (isLoading) {
      return <p>Loading...</p>;
   }

   if (isError) {
      return <p>Error loading product.</p>;
   }
   console.log(data?.categoryId)
   return (
      <div className='singl-product'>
         <div className="singl-product__container container">
            <div className="singl-product__left">
               <img src={data?.imageUrl[0]} alt="3204 2RS CT" className='product__image'></img>
               <div>
                  {data?.imageUrl.length > 1 && (
                     <div>
                        {data.imageUrl.slice(1).map((image, index) => (
                           <img key={index} src={image} alt={`Additional Image ${index + 1}`} className='product-image__second'></img>
                        ))}
                     </div>
                  )}
               </div>
            </div>


            <div className="singl-product__right">
               <div className="singl-product__block">
                  <h2>{data?.title}</h2>
                  <div className="product-visual">
                     <div className="product-rating">
                        <p>Оцінка покупців:</p>
                        {Array.from({ length: 5 }, (_, index) => (
                           <span
                              key={index}
                              className='product-rating__star'
                              style={{
                                 color: (isHoveredStar !== null ? index < isHoveredStar + 1 : index < data?.rating) ? 'gold' : 'gray',
                              }}
                              onMouseEnter={() => setIsHoveredStar(index)}
                              onMouseLeave={() => setIsHoveredStar(null)}
                              onClick={() => handleRatingClick(index + 1)}
                           >
                              ★
                           </span>
                        ))}
                        <p className="product-rating__number">{data?.rating}</p>
                        {/* Виправлено помилку в назві класу */}
                        <div className="product-views">
                           <div className="product-views__icon">
                              <img src={Eyes} alt="Eyes" />
                           </div>
                           {data?.views}
                           <p className="product-views__text"> покупців переглядали товар</p>
                        </div>
                     </div>
                     {/* Видалено пустий блок product-commen */}
                  </div>
                  <div className="product-article">
                     <h6>Артикул:</h6>
                     {data?.article}
                  </div>
                  <div className="product-card__available">
                     <div className={`product-card__avail ${data?.isAvailable ? 'available' : 'unavailable'}`}></div>
                     <div className='product-card__text'>  {data?.isAvailable ? 'в наявності' : 'відсутній'}</div>
                  </div>
                  <div className="product__price">
                     <h3>{data?.price}₴</h3>
                     <h3>{(data?.price * 0.8).toFixed(0)}₴ {['655dd72abd342d93bef0dd11', '655ddaafbd342d93bef0dd16', '655ddc1ebd342d93bef0dd23', '655ddc90bd342d93bef0dd28'].includes(categoryId) ? 'від 4шт' : 'від 10шт'}</h3>
                  </div>
                  <div className="product__btns">
                     <button type="submit" className="product-card__cart btn-product-cart" onClick={() => handleAddToCart(data)}>В кошик</button>
                     <button type="submit" class="btn-oneclick">Замовлення в 1 клік</button>
                  </div>
                  <ToastContainer />
               </div>
               <div className="singl-product__block">
                  <div className="singl-product__character character">
                     <div className="character__title">
                        <h5>Коротка характеристика:</h5>
                     </div>
                     {data?.categoryId === '655dd72abd342d93bef0dd11' ? (
                        <>
                           <div className="character__info">
                              <h5>Тип:</h5> {data?.Type}
                           </div>
                           <div className="character__info">
                              <h5>Ширина:</h5> {data?.width}
                           </div>
                           <div className="character__info">
                              <h5>Робоча довжина:</h5> {data?.workSize}
                           </div>
                        </>
                     ) : (
                        <>
                           <div className="character__info">
                              <h5>Тип:</h5> {data?.Type}
                           </div>
                           <div className="character__info">
                              <h5>Внутрішній розмір:</h5> {data?.internalSize}
                           </div>
                           <div className="character__info">
                              <h5>Зовнішній розмір:</h5> {data?.externalSize}
                           </div>
                           <div className="character__info">
                              <h5>Ширина:</h5> {data?.width}
                           </div>
                        </>
                     )}
                  </div>
               </div>




            </div>
         </div>
         <div className="singl-product__content container">
            <div class="btns-info">
               <button className={`btn-info desc ${activeTab === 'description' ? 'active' : ''}`} onClick={() => handleTabClick('description')}>
                  Опис
               </button>
               <button className={`btn-info character ${activeTab === 'character' ? 'active' : ''}`} onClick={() => handleTabClick('character')}>
                  Характеристика
               </button>
               <button className={`btn-info delivery ${activeTab === 'delivery' ? 'active' : ''}`} onClick={() => handleTabClick('delivery')}>
                  Доставка та оплата
               </button>
               <button className={`btn-info analog ${activeTab === 'analog' ? 'active' : ''}`} onClick={handleAnalogButtonClick}>
                  Аналог
               </button>
               <button className={`btn-info comment ${activeTab === 'comment' ? 'active' : ''}`} onClick={() => handleTabClick('comment')}>
                  Відгуки
               </button>

            </div>
            {/* Логіка для відображення відповідного блоку */}
            {activeTab === 'description' && (
               <div className="product-content description">
                  <div className='description__title'>{data?.title}</div>
                  <div className='description__text'>{data?.description}</div>
               </div>
            )}
            {activeTab === 'character' && (
               <div className="product-content full-character">
                  {/* Логіка для відображення характеристик */}
                  <div className="full-character__title">
                     <h5> Характеристика:</h5>
                  </div>
                  <div className="full-character__info">
                     <h5>Тип:</h5> {data?.Type}
                  </div>
                  <div className="full-character__info">
                     <h5>Внутрішній розмір:</h5> {data?.internalSize}
                  </div>
                  <div className="full-character__info">
                     <h5>Зовнішній розмір:</h5> {data?.externalSize}
                  </div>
                  <div className="full-character__info">
                     <h5>Ширина:</h5> {data?.width}
                  </div>
                  <div className="full-character__info">
                     <h5>Валюта:</h5> {data?.currency}
                  </div>
                  <div className="full-character__info">
                     <h5>Наявність:</h5>
                     <div className='product-card__text'>  {data?.isAvailable ? 'в наявності' : 'відсутній'}</div>
                  </div>
                  <div className="full-character__info">
                     <h5>Тип:</h5> {data?.Type}
                  </div>
                  <div className="full-character__info">
                     <h5>Місця застосування:</h5> {Array.isArray(data?.placeOfUse) ? data.placeOfUse.join(' ') : data?.placeOfUse}
                  </div>
                  <div className="full-character__info">
                     <h5>Техніка:</h5> {Array.isArray(data?.manufacturer) ? data.manufacturer.join(' ') : data?.manufacturer}
                  </div>

                  {/* ... інша логіка для відображення характеристик */}
               </div>
            )}
            {activeTab === 'delivery' && (
               <Delivery isActive={true} />
            )}


            {activeTab === 'analog' && (
               <div className="similar-products">
                  {similarProducts.length > 0 ? (
                     <div>
                        <h3>Аналогічні товари:</h3>
                        <ul>
                           {similarProducts
                              .filter((similarProduct) => isCharacterEqual(data, similarProduct))
                              .filter((similarProduct) => similarProduct._id !== data._id)
                              .map((similarProduct) => (
                                 <li key={similarProduct._id}>
                                    {similarProduct._id === data._id ? (
                                       <p>Схожих товарів не має</p>
                                    ) : (
                                       <Link
                                          to={`/${categoryId}/${subcategoryId}/products/${similarProduct._id}`} // Додано посилання на сторінку товару
                                          style={{ textDecoration: 'none', color: 'inherit' }}
                                       >
                                          <div className="product-card">
                                             <img src={similarProduct.imageUrl[0]} alt={similarProduct.title} className="product-card__image" />
                                             <h3 className="product-card__title">{similarProduct.title}</h3>
                                             <div className="product-card__buy">
                                                <p className="product-card__price">{similarProduct.price}₴</p>
                                                <button
                                                   type="button"
                                                   className="btn-productcart"
                                                   onClick={(e) => {
                                                      e.preventDefault();
                                                      handleAddToCart(similarProduct);
                                                   }}
                                                >
                                                   <img src={Cart} alt="Cart" />
                                                </button>
                                             </div>
                                          </div>
                                       </Link>
                                    )}
                                 </li>
                              ))}
                        </ul>
                     </div>
                  ) : (
                     <p>Схожих товарів не має</p>
                  )}

               </div>
            )}
            {activeTab === 'comment' && (
               <div className="comments-section">
                  <h3>Відгуки</h3>
                  <Comments currentPage={currentPage} productId={productId} />
               </div>
            )}
         </div>
         <Helmet>
            <title>{data?.title}</title>
            <meta name="description" content={data?.description ? `${data.description.slice(0, 150)}...` : ''} />
            <meta property="og:image" content={data?.imageUrl[0]} />
            <meta name="keywords" content={data?.tags.join(', ')} />

            {/* Додайте інші мета-теги за потребою */}
         </Helmet>
      </div>
   );
};

export default ProductDetails;

