import React, { useEffect, useState } from 'react';
import Brands from '../../Brands/Brands';
import CheckCircle from '../../../images/other/CheckCircle.svg'
import Basket from '../../../images/about/basket.svg'
import Wallet from '../../../images/about/wallet.svg'
import Guarantee from '../../../images/about/guarantee.svg'
import Delivery from '../../../images/about/delivery.svg'
import Profit from '../../../images/about/profit.svg'


const About = () => {
   const [showAllBrands, setShowAllBrands] = useState(false);
   return (
      <div className='about'>
         <div className="about__container container">
            <div className="about__banner">
               <div className="about__title">
                  АГРОСНАБ - це інтернет-магазин, орієнтований на покупця
               </div>
               <div className="about__banner-content">
                  <div className="about-block">
                     <div className="about-block__title">Початок роботи з</div>
                     <div className="about-block__text">2007</div>
                  </div>
                  <div className="about-block">
                     <div className="about-block__title">Асортимент продукції</div>
                     <div className="about-block__text"> {'>'}3500</div>
                  </div>
                  <div className="about-block">
                     <div className="about-block__title">Постійних клієнтів в Україні та інших країнах </div>
                     <div className="about-block__text">{'>'}1000</div>
                  </div>
               </div>
            </div>

         </div>
         <div className="about-content container">
            <div className="about-content__title"><h2>Ми постійно удосконалюємось для того, щоб Ви Могли Розраховувати на Нас</h2>
            </div>
            <div className="about-content__text text-about">
               <h4>Наша ціль</h4>
               <h5>Наша компанія гарантує якісні запчастини та надає індивідуальний підхід для кожного клієнта. Ми працюємо з різними виробниками, щоб задовольнити ваші потреби та забезпечити надійність та ефективність вашого обладнання. Звертайтеся до нас для отримання оптимальних рішень та професійного обслуговування. Наша мета - зробити ваш досвід замовлення запчастин простим та задовільним.</h5>
               <h4>Ми спеціалізуємось виключно на брендових та якісних запчастинах, з кожним днем ​​покращуємо якість обслуговування, тому Ви отримаєте:</h4>
               <div className="text-about__item">
                  <div className="text-about__ico">
                     <img src={CheckCircle} alt="CheckCircle" />
                  </div>
                  <div className="text-about__profit">Ціну, яка повністю відповідає рівню якості запчастини</div>
               </div>
               <div className="text-about__item">
                  <div className="text-about__ico">
                     <img src={CheckCircle} alt="CheckCircle" />
                  </div>
                  <div className="text-about__profit">Більше 3500 запчастин в наявності</div>
               </div>
               <div className="text-about__item">
                  <div className="text-about__ico">
                     <img src={CheckCircle} alt="CheckCircle" />
                  </div>
                  <div className="text-about__profit">Безкоштовну консультацію та допомогу у підборі запчастин за Вашими характеристиками</div>
               </div>
               <div className="text-about__item">
                  <div className="text-about__ico">
                     <img src={CheckCircle} alt="CheckCircle" />
                  </div>
                  <div className="text-about__profit">Постійну підтримку під час покупки, допомогу у будь-який час після покупки</div>
               </div>
               <div className="text-about__item">
                  <div className="text-about__ico">
                     <img src={CheckCircle} alt="CheckCircle" />
                  </div>
                  <div className="text-about__profit">Ми гарантуємо 100% обмін/повернення запчастин за умови неправильного підбору від нашого співробітника або браку </div>
               </div>
            </div>
         </div>
         <Brands showAll={showAllBrands} />
         <div className="about-benefits container">
            <div className="about-benefits__title">
               <h2>Наші Переваги</h2>
            </div>
            <div className="about-benefits__subtitle"><h5>дбаємо про якість та зручність користування</h5></div>
            <div className="about-benefits__content benefits-content">
               <div className="benefits-content__item item-benefits">
                  <div className="item-benefits__ico">
                     <img src={Basket} alt="Basket" />
                  </div>
                  <div className="item-benefits__title"><h4>{'>'}3500 товарів у каталозі</h4></div>
                  <div className="item-benefits__text">Обирайте товар, який підходить за ціною та характеристиками. Якщо товару немає в наявності — ми замовимо його персонально для Вас </div>
               </div>
               <div className="benefits-content__item item-benefits">
                  <div className="item-benefits__ico">
                     <img src={Wallet} alt="wallet" />
                  </div>
                  <div className="item-benefits__title"><h4>4 способи оплаты</h4></div>
                  <div className="item-benefits__text">Ви можете оплатити покупку онлайн, при отриманні, за рахунком та через безготівковий розрахунок </div>
               </div>
               <div className="benefits-content__item item-benefits">
                  <div className="item-benefits__ico">
                     <img src={Guarantee} alt="Guarantee" />
                  </div>
                  <div className="item-benefits__title"><h4>Повна документація</h4></div>
                  <div className="item-benefits__text">При покупці вам видається касовий чек, який дає право повернути товар у разі браку або коли товар не підходить Умови повернення </div>
               </div>
               <div className="benefits-content__item item-benefits">
                  <div className="item-benefits__ico">
                     <img src={Delivery} alt="Delivery" />
                  </div>
                  <div className="item-benefits__title"><h4>Відправка замовлення день у день</h4></div>
                  <div className="item-benefits__text">Надсилаємо замовлення по всій Україні в день замовлення зручною для Вас службою доставки</div>
               </div>
               <div className="benefits-content__item item-benefits">
                  <div className="item-benefits__ico">
                     <img src={Profit} alt="Profit" />
                  </div>
                  <div className="item-benefits__title"><h4>Формуємо склади під Вас</h4></div>
                  <div className="item-benefits__text">Ми формуємо свої скади на основі ваших постійних замовлень. Будьте впевнені в наявності товару, який Ви купуєте регулярно </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default About
